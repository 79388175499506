import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LongsTableClient from "./LongsTableClient";
import BuysTable from "../Pages/trades/BuysTable";
import SellTable from "../Pages/trades/SellTable";
import ShortsTable from "../Pages/trades/ShortsTable";
import ShortsTableClient from "./ShortsTableClient";
import BuysTableClient from "./BuysTableClient";
import SellTableClient from "./SellTableClient";

export default class TradesClient extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };



    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };
    }



    componentDidMount(){

    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render(){

        const { classes } = this.props;
        const { value } = this.state;

        return(
            <div className={'container'}>
                <AppBar position="static">
                    <Tabs value={value} onChange={this.handleChange}
                          classes={{
                              indicator: 'tabsTradesIndicator'
                          }}>
                        <Tab label="Ventas" />
                        <Tab label="Long" />
                        <Tab label="Short" />
                    </Tabs>
                </AppBar>
                {value === 4 && <BuysTableClient search={this.props.search} clearTyped={this.props.clearTyped} btc={this.props.btc} user={this.props.user}/>}
                {value === 0 && <SellTableClient search={this.props.search} clearTyped={this.props.clearTyped} btc={this.props.btc} user={this.props.user}/>}
                {value === 1 && <LongsTableClient search={this.props.search} clearTyped={this.props.clearTyped} btc={this.props.btc} user={this.props.user} />}
                {value === 2 && <ShortsTableClient search={this.props.search} clearTyped={this.props.clearTyped} btc={this.props.btc} user={this.props.user}/>}
            </div>
        )
    }
}