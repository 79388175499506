import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import firebase from '../../firebase'


const suggestions = [
    { label: 'Afghanistan' },
    { label: 'Aland Islands' },
    { label: 'Albania' },
    { label: 'Algeria' },
    { label: 'American Samoa' },
    { label: 'Andorra' },
    { label: 'Angola' },
    { label: 'Anguilla' },
    { label: 'Antarctica' },
    { label: 'Antigua and Barbuda' },
    { label: 'Argentina' },
    { label: 'Armenia' },
    { label: 'Aruba' },
    { label: 'Australia' },
    { label: 'Austria' },
    { label: 'Azerbaijan' },
    { label: 'Bahamas' },
    { label: 'Bahrain' },
    { label: 'Bangladesh' },
    { label: 'Barbados' },
    { label: 'Belarus' },
    { label: 'Belgium' },
    { label: 'Belize' },
    { label: 'Benin' },
    { label: 'Bermuda' },
    { label: 'Bhutan' },
    { label: 'Bolivia, Plurinational State of' },
    { label: 'Bonaire, Sint Eustatius and Saba' },
    { label: 'Bosnia and Herzegovina' },
    { label: 'Botswana' },
    { label: 'Bouvet Island' },
    { label: 'Brazil' },
    { label: 'British Indian Ocean Territory' },
    { label: 'Brunei Darussalam' },
];

function renderInputComponent(inputProps) {
    const {error, classes, inputRef = () => {}, ref, ...other } = inputProps;

    return (
        <TextField
            className={'fieldModal clientsField'}
            variant={"outlined"}
            margin="normal"
            error={error}
            InputLabelProps={{
                classes: {
                    root: '',
                    focused: 'inputCustomLogin',
                },
            }}
            InputProps={{
                inputRef: node => {
                    ref(node);
                    inputRef(node);
                },
                classes: {
                    root: '',
                    focused: 'inputCustomLogin',
                    notchedOutline: 'inputCustomLogin',
                },
            }}
            {...other}
        />
    );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </strong>
                    );
                })}
            </div>
        </MenuItem>
    );
}




const styles = theme => ({
    root: {
        flexGrow: 1,
        display: 'inline-block',
        marginLeft: '3%',
        width: '45.5%',
    },
    container: {
        position: 'relative',
        display: 'inline-block'
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 2,
        marginTop: theme.spacing.unit,
        left: '3%',
        width: '44.5%',
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});

class ClientsField extends React.Component {
    state = {
        single: '',
        popper: '',
        suggestions: [],
        suggestionsClients: [],
        listener: '',
    };

    getSuggestionValue = (suggestion) => {
        this.props.handleChangeClientId(suggestion.id);
        return suggestion.label;
    };

    getSuggestions = (value) => {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0
            ? []
            : this.state.suggestionsClients.filter(suggestion => {
                const keep =
                    count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    };

    componentDidMount() {

        var db = firebase.firestore();

        var clientList = [];

        const  context = this;
        var listener = db.collection('clients').where('portfolioID', '==', this.props.user.portfolioID).onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                clientList.push({label: doc.data().name + ' ' + doc.data().lastName, id: doc.id})
            });
            context.setState({
                suggestionsClients: clientList,
            })
        });

        this.setState({listener: listener});

    }
    componentWillUnmount() {
        if (this.state.listener){
            this.state.listener();
        }
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = name => (event, { newValue }) => {
        this.props.handleChangeClient(newValue);
    };

    render() {
        const { classes } = this.props;

        const autosuggestProps = {
            renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue: this.getSuggestionValue,
            renderSuggestion,
        };

        return (
            <div className={classes.root}>
                <Autosuggest
                    {...autosuggestProps}
                    inputProps={{
                        classes,
                        label:'Cliente',
                        error: this.props.error,
                        value: this.props.value,
                        onChange: this.handleChange('single'),
                    }}
                    theme={{

                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                />

            </div>
        );
    }
}

ClientsField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClientsField);