import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UsersTable from "./users/UsersTable";
import Fab from "@material-ui/core/Fab";
import ThreeSixty from "@material-ui/core/SvgIcon/SvgIcon";
import firebase from '../firebase';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    fab: {
        marginRight: '2%',
        float: 'right'
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },

});

export default withStyles(styles)( class Users extends React.Component {

    static propTypes = {
        user: PropTypes.object,
        userData: PropTypes.object,
        handleChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
        this.props.clearTyped();
    }

    changeLongsAndShorts = () =>{
        var db = firebase.firestore();
        db.collection('trades').where('portfolioID', '==', this.props.user.portfolioID).where('symbol', '==', 'BTC').where('type', '==', 'short')
            .get().then(function (snap) {
            snap.forEach(function (trade) {
                var tradeValue = trade.data();
                console.log(tradeValue);
                db.collection('trades').doc(trade.id).update({btcUsdEnd: tradeValue.priceClosed}).then(function () {
                    console.log('listo')
                })
            })
        })
    };
    render(){

        const { classes } = this.props;

        return(
            <div className={'container'}>

                <UsersTable search={this.props.search} user={this.props.user} actionFeedback={this.props.actionFeedback} btc={this.props.btc} totalPortfolio={this.props.totalPortfolio}
                            actualCPart={this.props.actualCPart} handleClientIdSelected={this.props.handleClientIdSelected}/>
            </div>
        )
    }
})