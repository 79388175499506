import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import firebase from "../firebase";
import {Decimal} from "decimal.js";



const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');
function timestampToDateString(timestamp) {
    var date = new Date(timestamp * 1000);
    var year = date.getFullYear();
    var month = date.getMonth()+1;
    var day = date.getDate();

    return day + '/' + month + '/' + year;


}
function createData(id, coin, lev, cost, orderVal, quan, pnl, date, exchange, state, btcUsd, prAct, prActUsd, price, type, symbol) {

    let costUsd = new Decimal(cost).times(btcUsd);
    let orderValUsd = new Decimal(orderVal).times(btcUsd);
    let priceUsd = new Decimal (price).times(btcUsd);

    var rend;
    var pnlUsd;
    var rendUsd;

    if (pnl){
        pnlUsd = new Decimal(pnl).times(btcUsd);
        rendUsd = new Decimal(pnlUsd).dividedBy(costUsd);
        rend = new Decimal(pnl).dividedBy(cost);
    }  else {
        pnl = '-';
        pnlUsd = '-';
        rendUsd = '-';
        rend = '-';
    }



    return { id, coin, lev, cost, orderVal, quan, pnl, date, exchange, rend , state, costUsd, orderValUsd, pnlUsd, rendUsd, prAct, prActUsd, price, priceUsd, type, symbol, btcUsd};
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}



const rows = [
    { id: 'coin', numeric: false, disablePadding: false, label: 'Moneda' },
    { id: 'lev', numeric: true, disablePadding: false, label: 'Leverage' },
    { id: 'cost', numeric: true, disablePadding: false, label: 'Cost' },
    { id: 'orederVal', numeric: true, disablePadding: false, label: 'Order Value' },
    { id: 'quan', numeric: true, disablePadding: false, label: 'Cantidad' },
    { id: 'price', numeric: true, disablePadding: false, label: 'Precio compra' },
    { id: 'prAct', numeric: true, disablePadding: false, label: 'Precio cierre' },
    { id: 'pnl', numeric: true, disablePadding: false, label: 'PNL' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Fecha' },
    { id: 'rend', numeric: true, disablePadding: false, label: 'Rendimiento' },
];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {  order, orderBy, } = this.props;

        return (
            <TableHead className={'tableHead'}>
                <TableRow>
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '94%',
        marginTop: theme.spacing.unit * 3,
        marginLeft: '3%',
        borderRadius: 15,
        marginBottom: theme.spacing.unit * 3,
        [theme.breakpoints.up('md')]: {
            width: '96%',
            marginLeft: '2%',
        },
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});


class ShortsTableClient extends React.Component {
    state = {
        order: 'desc',
        orderBy: 'date',
        selected: [],
        listener: '',
        data: [],
        dataFiltered: [],
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };



    componentDidMount() {
        this.props.clearTyped();
        if (this.props.user.portfolioID !== undefined){

            var db = firebase.firestore();
            const context = this;

            var symbolList = [];

            var listener = db.collection("trades").where("portfolioID", "==", this.props.user.portfolioID).where("type", "==", "short" ).where('state','==',false)
                .onSnapshot(function(querySnapshot) {

                    querySnapshot.forEach(function(doc) {
                        if (!symbolList.includes(doc.data().symbol)){
                            symbolList.push(doc.data().symbol);
                        }
                    });

                    cc.priceMulti(symbolList, ['BTC', 'USD'])
                        .then(prices => {
                            console.log(prices);
                            var list = [];

                            querySnapshot.forEach(function(doc) {
                                list.push(createData(doc.id, doc.data().coin, doc.data().lev, doc.data().cost, doc.data().orderValue,
                                    doc.data().quantity, doc.data().pnl, doc.data().date, doc.data().exchange, doc.data().state,
                                    doc.data().btcUsd, doc.data().priceClosed, (doc.data().priceClosed * doc.data().btcUsdEnd), doc.data().price, doc.data().type, doc.data().symbol));

                            });

                            context.setState({data: list, dataFiltered: list})
                            /* context.setState({
                                 data: portList,
                             });
                             console.log(portList)*/


                            // -> { BTC: { USD: 1114.63, EUR: 1055.82 },
                            //      ETH: { USD: 12.74, EUR: 12.06 } }
                        })
                        .catch(console.error)

                });
            context.setState({listener: listener})
        }
    }

    componentWillUnmount() {
        if (this.state.listener !== '') {
            this.state.listener()
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.search){
            var filtTrades = [];
            this.state.data.forEach(function (trades) {
                if (trades.coin.toLowerCase().includes(nextProps.search.toLowerCase()) ||
                    trades.symbol.toLowerCase().includes(nextProps.search.toLowerCase())){
                    filtTrades.push(trades);
                }
            });
            this.setState({dataFiltered: filtTrades})
        }
        else {
            this.setState({dataFiltered: this.state.data})
        }
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;
        const { dataFiltered, order, orderBy, selected, } = this.state;


        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={dataFiltered.length}
                        />
                        <TableBody className={'tableBody'}>
                            {stableSort(dataFiltered, getSorting(order, orderBy))
                                .map(n => {
                                    const isSelected = this.isSelected(n.id);
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >


                                            <TableCell component="th" scope="row">
                                                {n.coin}
                                            </TableCell>

                                            <TableCell numeric>{n.lev}</TableCell>
                                            <TableCell numeric>{this.props.btc ? '₿ ' + n.cost :'$ ' + n.costUsd}</TableCell>
                                            <TableCell numeric>{this.props.btc ? '₿ ' + n.orderVal :'$ ' + n.orderValUsd}</TableCell>
                                            <TableCell numeric>{n.quan}</TableCell>
                                            <TableCell numeric>{this.props.btc ? '₿ ' + n.price :'$ ' + n.priceUsd}</TableCell>
                                            <TableCell numeric>{this.props.btc ? '₿ ' + n.prAct :'$ ' + n.prActUsd}</TableCell>
                                            <TableCell numeric>{this.props.btc ? '₿ ' + n.pnl :'$ ' + n.pnlUsd}</TableCell>
                                            <TableCell component="th" scope="row">{timestampToDateString(n.date)}</TableCell>
                                            {n.rend === '-' ? <TableCell numeric>-</TableCell> :  <TableCell numeric className={(this.props.btc ? n.rend : n.rendUsd) >= 0 ? 'rendPos' : 'rendNeg'}>{this.props.btc ? n.rend.toFixed(2) + '%': n.rendUsd.toFixed(2) + '%'}</TableCell> }
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>

            </Paper>
        );
    }
}

ShortsTableClient.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShortsTableClient);