import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DrawerComponent from "./DrawerComponent";
import { Switch, Route } from "react-router-dom";
import Portfolio from "./Pages/Portfolio";
import Coins from "./Pages/Coins";
import Trades from "./Pages/Trades";
import Users from "./Pages/Users";
import Goals from "./Pages/Goals";
import { withRouter } from "react-router-dom";
import AppBarComponent from "./AppBarComponent";
import FabComp from "./FabComp";
import Liquidation from "./Pages/Liquidation";
import NewInvestment from "./modals/NewInvestment";
import NewClient from "./modals/NewClient";
import NewTrade from "./modals/NewTrade";
import NewObjective from "./modals/NewObjective";
import Snackbar from "@material-ui/core/Snackbar";
import firebase from 'firebase';
import EndTrade from "./modals/EndTrade";
import EditLongShort from "./modals/EditLongShort";
import {Decimal} from 'decimal.js';
import ChangePassword from "./modals/ChangePassword";
import NewAdmin from "./modals/NewAdmin";
import ExtraCoin from "./modals/ExtraCoin";
import Investment from "./client/Investment";
import ClientsInvestment from "./Pages/ClientsInvestment";
import NewPortfolio from "./modals/NewPortfolio";
import Import from "./Pages/Import";

const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');

export default withRouter( class AppComponent extends React.Component {

    static propTypes = {
        user: PropTypes.object,

    };

    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            btc: true,
            openInvestment: false,
            openClient: false,
            openTrade: false,
            openObj: false,
            search: '',
            openSnackbar: false,
            snackMessage: '',
            snackClass: 'snackbarSucces',
            userObj: {},
            openEndLong: false,
            trade: {},
            openEditLongShort: false,
            qCPart: 0,
            portfolioListener: '',
            coinsListner: '',
            totalPortfolio: 0,
            openChangePass: false,
            portfolioObj: '',
            openNewAdmin: false,
            openExtraCoin: false,
            clientIdSelected: '',
            clientSelected: {},
            portListener: '',
            portfolios: [],
            fundListener: '',
            openNewPortfolio: false,
        };
    }

    componentDidMount(){
        this.props.history.push('portfolio');
        const context = this;
        var db = firebase.firestore();
        var docRef = db.collection('admins').where("userID", "==", this.props.user.uid);
        var portListener = docRef.onSnapshot(function(querySnapshot) {
                if (querySnapshot.size > 0){
                    querySnapshot.forEach(function(doc) {
                        // doc.data() is never undefined for query doc snapshots
                        var userObj = doc.data();
                        userObj.id = doc.id;
                        context.setState({userObj: userObj});



                        var symbolList = [];


                        var coinsListener = db.collection('coins')
                            .where('portfolioID', '==', doc.data().portfolioID)
                            .onSnapshot(function (querySnapshot) {

                                var totalPortfolio = new Decimal(0);

                            querySnapshot.forEach(function (doc) {
                                symbolList.push(doc.data().symbol)
                            });

                            if (symbolList.length > 0){
                                cc.priceMulti(symbolList, ['BTC'])
                                    .then(prices => {
                                        querySnapshot.forEach(function (doc) {
                                            if (prices[doc.data().symbol]){
                                                console.log(prices[doc.data().symbol].BTC, doc.data().quantity);
                                                totalPortfolio = totalPortfolio.plus(new Decimal(prices[doc.data().symbol].BTC).times(doc.data().quantity));
                                            } else {
                                                totalPortfolio = totalPortfolio.plus(new Decimal(doc.data().precioPonderado).times(doc.data().quantity));
                                            }

                                        });

                                        context.setState({totalPortfolio: totalPortfolio})
                                        /*context.setState({actualValueCPart: totalPortfolio / docu.data().qCpart});
                                        console.log(totalPortfolio / docu.data().qCpart);*/
                                    })
                                    .catch(console.error);
                            } else {
                                context.setState({totalPortfolio: totalPortfolio})
                            }



                        });
                        var porfolioListener = db.collection('portfolios').doc(doc.data().portfolioID).onSnapshot(function (doc) {

                            context.setState({qCPart: doc.data().qCPart, portfolioObj: doc.data()})
                        });

                        context.setState({porfolioListener: porfolioListener, coinsListener: coinsListener});

                    });
                } else {

                }

            });

        context.setState({portListener: portListener});


        var fundListener = db.collection('portfolios').onSnapshot(function (docs) {
            var portfolios = [];
            docs.forEach(function (doc) {
                var port = doc.data();
                port.id = doc.id;
                portfolios.push(port)
            });
            context.setState({portfolios: portfolios})
        });
        context.setState({fundListener: fundListener})


    }
    componentWillUnmount() {
        if (this.state.porfolioListener){
            this.state.porfolioListener()
        }
        if (this.state.coinsListener){
            this.state.coinsListener()
        }
        if (this.state.portListener){
            this.state.portListener()
        }
        if (this.state.fundListener){
            this.state.fundListener()
        }
    }
    handleClientSelected = (id, client) => {
        this.setState({clientIdSelected: id, clientSelected: client});
        this.props.history.push('investment');
    };

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };
    handleCurrency = () => {
        this.setState(state => ({ btc: !state.btc }));
    };
    handleSearch = (event) => {
        this.setState({search: event.target.value})
    };
    clearTyped = () => {
        this.setState({search: ''})
    };
    handleOpen = (i) => {
        if (i===0){
            this.setState({ openInvestment: true });
        } else if (i===1){
            this.setState({ openClient: true });
        }
        else if (i===2){
            this.setState({ openTrade: true });
        }
        else if (i===3){
            this.setState({ openObj: true });
        }
        else if (i===6){
            this.setState({ openChangePass: true });
        }
        else if (i===7){
            this.setState({openNewAdmin: true})
        }
        else if (i===8){
            this.setState({openExtraCoin: true})
        }
        else if (i===9){
            this.setState({openNewPortfolio: true})
        }

    };
    handleEndTrade = (trade) => {
        this.setState({
            trade: trade,
            openEndLong: true,
        })
    };
    handleEditLongShort = (trade) => {
        this.setState({
            trade: trade,
            openEditLongShort: true,
        })
    };

    handleClose = (i) => {
        if (i===0){
            this.setState({ openInvestment: false });
        }else if (i===1){
            this.setState({ openClient: false });
        }else if (i===2){
            this.setState({ openTrade: false });
        }else if (i===3){
            this.setState({ openObj: false });
        }else if (i===4){
            this.setState({ openEndLong: false, trade: {} });
        }else if (i===5){
            this.setState({ openEditLongShort: false, trade: {} });
        }
        else if (i===6){
            this.setState({ openChangePass: false});
        }
        else if (i===7){
            this.setState({openNewAdmin: false});
            setTimeout(function () {
                document.body.style.overflowY = 'auto';
            }, 10)
        }
        else if (i===8){
            this.setState({openExtraCoin: false})
        }
        else if (i===9){
            this.setState({openNewPortfolio: false})
        }



    };

    handlePortChange = (id) => {
        var db = firebase.firestore();
        var context = this;
        db.collection('admins').doc(this.state.userObj.id).update({portfolioID: id}).then(function (doc) {
           context.forceUpdate();
        })
    };
    actionFeedback = (type, message) => {
        if (type === 0 ){
            this.setState({
                openSnackbar: true,
                snackMessage: message,
                snackClass: 'snackbarSucces'
            });
        }
        if (type === 1 ){
            this.setState({
                openSnackbar: true,
                snackMessage: message,
                snackClass: 'snackbarFailed'
            })
        }
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openSnackbar: false });
    };

    render(){


        return(


            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    className={this.state.snackClass}
                    open={this.state.openSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackMessage}

                />

                <ExtraCoin open={this.state.openExtraCoin} handleClose={this.handleClose}  actionFeedback={this.actionFeedback} user={this.state.userObj}/>
                <ChangePassword open={this.state.openChangePass} handleClose={this.handleClose}  actionFeedback={this.actionFeedback} user={this.state.userObj}/>
                <NewAdmin open={this.state.openNewAdmin} handleClose={this.handleClose}  actionFeedback={this.actionFeedback} user={this.state.userObj}/>
                <NewPortfolio open={this.state.openNewPortfolio} handleClose={this.handleClose}  actionFeedback={this.actionFeedback} user={this.state.userObj}/>
                <EndTrade open={this.state.openEndLong} handleClose={this.handleClose} trade={this.state.trade} actionFeedback={this.actionFeedback} user={this.state.userObj}/>
                <EditLongShort open={this.state.openEditLongShort} handleClose={this.handleClose} trade={this.state.trade} actionFeedback={this.actionFeedback} user={this.state.userObj}/>
                <NewObjective open={this.state.openObj} handleClose={this.handleClose} actionFeedback={this.actionFeedback} user={this.state.userObj}/>
                <NewTrade open={this.state.openTrade} handleClose={this.handleClose} actionFeedback={this.actionFeedback} user={this.state.userObj}/>
                <NewClient open={this.state.openClient} handleClose={this.handleClose}  actionFeedback={this.actionFeedback} user={this.state.userObj}/>
                <NewInvestment openInvestment={this.state.openInvestment} handleClose={this.handleClose}  actionFeedback={this.actionFeedback} user={this.state.userObj}/>
                <DrawerComponent mobileOpen={this.state.mobileOpen} handleDrawerToggle={this.handleDrawerToggle}  user={this.state.userObj}/>
                <AppBarComponent handlePortChange={this.handlePortChange} portfolio={this.state.portfolioObj} portfolios={this.state.portfolios} user={this.state.userObj} handleDrawerToggle={this.handleDrawerToggle} btc={this.state.btc} handleCurrency={this.handleCurrency} handleSearch={this.handleSearch} searchVal={this.state.search} handleOpenModals={this.handleOpen}/>
                <FabComp handleOpen={this.handleOpen} user={this.state.userObj}/>
                <Route
                    path='/portfolio'
                    render={(props) => <Portfolio {...props} portfolio={this.state.portfolioObj} clearTyped={this.clearTyped} btc={this.state.btc} user={this.state.userObj}
                        actualCPart={new Decimal(this.state.totalPortfolio).dividedBy(this.state.qCPart)} qCPart={this.state.qCPart} search={this.state.search}/>}
                />
                <Route
                    path='/coins'
                    render={(props) => <Coins {...props} clearTyped={this.clearTyped} btc={this.state.btc} user={this.state.userObj} search={this.state.search}/>}
                />
                <Route
                    path='/trades'
                    render={(props) => <Trades {...props} actionFeedback={this.actionFeedback} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj} handleEndTrade={this.handleEndTrade} handleEditLongShort={this.handleEditLongShort}/>}
                />
                <Route
                    path='/users'
                    render={(props) => <Users {...props} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj} totalPortfolio={this.state.totalPortfolio}
                                              actualCPart={new Decimal(this.state.totalPortfolio).dividedBy(this.state.qCPart)} handleClientIdSelected={this.handleClientSelected}/>}
                />
                <Route
                    path='/goals'
                    render={(props) => <Goals {...props} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj} />}
                />
                <Route
                    path='/liquidation'
                    render={(props) => <Liquidation {...props} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj} totalPortfolio={this.state.totalPortfolio} actionFeedback={this.actionFeedback} />}
                />
                <Route
                    path='/investment'
                    render={(props) => <ClientsInvestment {...props} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj} userID={this.state.clientIdSelected} client={this.state.clientSelected} portfolio={this.state.portfolioObj}
                                                   actualCPart={new Decimal(this.state.totalPortfolio).dividedBy(this.state.qCPart)}/>}
                />
                {this.state.userObj.super &&
                <Route
                    path='/import'
                    render={(props) => <Import {...props} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj} userID={this.state.clientIdSelected} portfolio={this.state.portfolioObj}
                                               actualCPart={new Decimal(this.state.totalPortfolio).dividedBy(this.state.qCPart)}/>}
                />
                }


            </div>
        )
    }
})