import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import bclogo from "../../images/BC_Logo_.png";
import firebase from "../../firebase";
import {Decimal} from 'decimal.js';
import Button from "@material-ui/core/Button";

const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');

const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

function getMonthNameFrom(timestamp) {
    var date = new Date(timestamp * 1000);
    var month = date.getMonth();
    return monthNames[month];
}

function getYearFrom(timestamp) {
    var date = new Date(timestamp * 1000);
    var year = date.getFullYear();
    return year;
}

var moment = require('moment');
moment().format();


function createData(id, sales, buys, pnls, profit, totalPortfolio, recaud, managementFee, management, total, date) {

    return { id, sales, buys, pnls,  profit, totalPortfolio, recaud, managementFee, management, total, date};
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}




const rows = [
    { id: 'year', numeric: true, disablePadding: false, label: 'Año' },
    { id: 'date', numeric: true, disablePadding: false, label: 'Mes' },
    { id: 'sales', numeric: true, disablePadding: false, label: 'Ventas' },
    { id: 'buys', numeric: true, disablePadding: false, label: 'Compras' },
    { id: 'pnls', numeric: true, disablePadding: false, label: 'PNLS' },
    { id: 'profit', numeric: true, disablePadding: false, label: 'Ganancias' },
    { id: 'totalPortfolio', numeric: true, disablePadding: false, label: 'Total fondo' },
    { id: 'recaud', numeric: true, disablePadding: false, label: 'A recaudar' },
    { id: 'managementFee', numeric: true, disablePadding: false, label: 'Management fee' },
    { id: 'management', numeric: true, disablePadding: false, label: 'Management charge' },
    { id: 'total', numeric: true, disablePadding: false, label: 'Total' },

];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {  order, orderBy, } = this.props;

        return (
            <TableHead className={'tableHead'}>
                <TableRow>
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '94%',
        marginTop: theme.spacing.unit * 3,
        marginLeft: '3%',
        borderRadius: 15,
        marginBottom: theme.spacing.unit * 3,
        paddingBottom: '0.1px',
        [theme.breakpoints.up('md')]: {
            width: '96%',
            marginLeft: '2%',
        },
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});




class LiquisTable extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'date',
        selected: [],
        listener: '',
        data: [],
        month: '',
        disabled: true,
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.totalPortfolio){
            this.componentDidMount()
        }
    }

    componentDidMount() {
        if (this.props.user.portfolioID){
            var db = firebase.firestore();
            var context = this;

            var listener = db.collection('liquis').where('portfolioID', '==', this.props.user.portfolioID)
                .onSnapshot(function (querySnapshot) {
                    var data = [];
                    querySnapshot.forEach(function (doc) {
                        data.push(createData(doc.id, doc.data().sales, doc.data().buys, doc.data().pnls, doc.data().profit,
                            doc.data().totalPortfolio, doc.data().recaud, doc.data().managementFee, doc.data().managementCharge, doc.data().total, doc.data().date))
                    });
                    context.setState({data: data})
                });
            this.setState({listener: listener});

        }
    }
    componentWillUnmount() {
        if (this.state.listener){
            this.state.listener();
        }
    }


    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;
        const { data, order, orderBy, selected, } = this.state;


        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={data.length}
                        />
                        <TableBody className={'tableBody'}>
                            {stableSort(data, getSorting(order, orderBy))
                                .map(n => {
                                    const isSelected = this.isSelected(n.id);
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >


                                            <TableCell component="th" scope="row">{getYearFrom(n.date)}</TableCell>
                                            <TableCell component="th" scope="row">{getMonthNameFrom(n.date)}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.sales}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.buys}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.pnls}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.profit}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.totalPortfolio}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.recaud}</TableCell>
                                            <TableCell numeric>{n.managementFee + '%'}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.management}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.total}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        );
    }
}

LiquisTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LiquisTable);