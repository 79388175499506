import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DrawerComponent from "../DrawerComponent";
import { Switch, Route } from "react-router-dom";
import Portfolio from "../Pages/Portfolio";
import Coins from "../Pages/Coins";
import Trades from "../Pages/Trades";
import Users from "../Pages/Users";
import Goals from "../Pages/Goals";
import { withRouter } from "react-router-dom";
import AppBarComponent from "../AppBarComponent";
import FabComp from "../FabComp";
import Liquidation from "../Pages/Liquidation";
import NewInvestment from "../modals/NewInvestment";
import NewClient from "../modals/NewClient";
import NewTrade from "../modals/NewTrade";
import NewObjective from "../modals/NewObjective";
import Snackbar from "@material-ui/core/Snackbar";
import firebase from '../firebase';
import EndTrade from "../modals/EndTrade";
import EditLongShort from "../modals/EditLongShort";
import {Decimal} from 'decimal.js';
import TradesClient from "./TradesClient";
import AppBarClient from "./AppBarClient";
import DrawerClient from "./DrawerClient";
import Investment from "./Investment";
import ChangePassword from "../modals/ChangePassword";
import PortfolioClient from "./PortfolioClient";

const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');

export default withRouter( class AppComponent extends React.Component {

    static propTypes = {
        user: PropTypes.object,

    };

    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            btc: true,
            openInvestment: false,
            openClient: false,
            openTrade: false,
            openObj: false,
            search: '',
            openSnackbar: false,
            snackMessage: '',
            snackClass: 'snackbarSucces',
            userObj: {},
            userID: '',
            openEndLong: false,
            trade: {},
            openEditLongShort: false,
            qCPart: 0,
            portfolioListener: '',
            coinsListner: '',
            totalPortfolio: 0,
            totalPortfolioUsd: 0,
            openChangePass: false,
            portfolioObj: '',
        };
    }

    componentDidMount(){
        this.props.history.push('portfolio');
        const context = this;
        var db = firebase.firestore();
        var docRef = db.collection('clients').where("userID", "==", this.props.user.uid);
        docRef.get()
            .then(function(querySnapshot) {
                if (querySnapshot.size > 0){
                    querySnapshot.forEach(function(doc) {
                        // doc.data() is never undefined for query doc snapshots
                        console.log(doc.id, " => ", doc.data());
                        context.setState({userObj: doc.data(), userID: doc.id});



                        var symbolList = [];


                        var coinsListener = db.collection('coins')
                            .where('portfolioID', '==', doc.data().portfolioID)
                            .onSnapshot(function (querySnapshot) {

                                var totalPortfolio = new Decimal(0);

                                querySnapshot.forEach(function (doc) {
                                    symbolList.push(doc.data().symbol)
                                });

                                cc.priceMulti(symbolList, ['BTC'])
                                    .then(prices => {
                                        querySnapshot.forEach(function (doc) {
                                            if (prices[doc.data().symbol]) {
                                                totalPortfolio = totalPortfolio.plus(new Decimal(prices[doc.data().symbol].BTC).times(doc.data().quantity));
                                            }
                                            else {
                                                totalPortfolio = totalPortfolio.plus(new Decimal(doc.data().precioPonderado).times(doc.data().quantity));
                                            }

                                        });
                                        context.setState({totalPortfolio: totalPortfolio});
                                        cc.price('BTC', ['USD']).then(function (priceBtc) {
                                            var totalPortfolioUsd = new Decimal(totalPortfolio).times(priceBtc.USD);
                                            context.setState({totalPortfolioUsd: totalPortfolioUsd})
                                        });

                                        /*context.setState({actualValueCPart: totalPortfolio / docu.data().qCpart});
                                        console.log(totalPortfolio / docu.data().qCpart);*/
                                    })
                                    .catch(console.error);

                            });
                        var porfolioListener = db.collection('portfolios').doc(doc.data().portfolioID).onSnapshot(function (doc) {
                            context.setState({qCPart: doc.data().qCPart, portfolioObj: doc.data()})
                        });

                        context.setState({porfolioListener: porfolioListener, coinsListener: coinsListener});

                    });
                } else {

                }

            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);

            });

    }

    clearTyped = () => {
        this.setState({search: ''})
    };
    componentWillUnmount() {
        if (this.state.porfolioListener){
            this.state.porfolioListener()
        }
        if (this.state.coinsListener){
            this.state.coinsListener()
        }
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };
    handleCurrency = () => {
        this.setState(state => ({ btc: !state.btc }));
    };
    handleSearch = (event) => {
        this.setState({search: event.target.value})
    };
    handleOpen = (i) => {
        if (i===0){
            this.setState({ openInvestment: true });
        } else if (i===1){
            this.setState({ openClient: true });
        }
        else if (i===2){
            this.setState({ openTrade: true });
        }
        else if (i===3){
            this.setState({ openObj: true });
        }
        else if (i===6){
            this.setState({ openChangePass: true });
        }

    };
    handleEndTrade = (trade) => {
        this.setState({
            trade: trade,
            openEndLong: true,
        })
    };
    handleEditLongShort = (trade) => {
        this.setState({
            trade: trade,
            openEditLongShort: true,
        })
    };

    handleClose = (i) => {
        document.body.style.overflow = 'auto';
        if (i===0){
            this.setState({ openInvestment: false });
        }else if (i===1){
            this.setState({ openClient: false });
        }else if (i===2){
            this.setState({ openTrade: false });
        }else if (i===3){
            this.setState({ openObj: false });
        }else if (i===4){
            this.setState({ openEndLong: false, trade: {} });
        }else if (i===5){
            this.setState({ openEditLongShort: false, trade: {} });
        }else if (i===6){
            this.setState({ openChangePass: false});
        }

    };

    actionFeedback = (type, message) => {
        if (type === 0 ){
            this.setState({
                openSnackbar: true,
                snackMessage: message,
                snackClass: 'snackbarSucces'
            });
        }
        if (type === 1 ){
            this.setState({
                openSnackbar: true,
                snackMessage: message,
                snackClass: 'snackbarFailed'
            })
        }
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openSnackbar: false });
    };

    render(){


        return(


            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    className={this.state.snackClass}
                    open={this.state.openSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackMessage}

                />
                <ChangePassword open={this.state.openChangePass} handleClose={this.handleClose}  actionFeedback={this.actionFeedback} user={this.state.userObj}/>
                <DrawerClient mobileOpen={this.state.mobileOpen} handleDrawerToggle={this.handleDrawerToggle}  user={this.state.userObj}/>
                <AppBarClient handleOpenModals={this.handleOpen} user={this.state.userObj} handleDrawerToggle={this.handleDrawerToggle} btc={this.state.btc} handleCurrency={this.handleCurrency} handleSearch={this.handleSearch} searchVal={this.state.search}/>
                <Route
                    path='/portfolio'
                    render={(props) => <PortfolioClient {...props} portfolio={this.state.portfolioObj} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj}
                                                  actualCPart={new Decimal(this.state.totalPortfolio).dividedBy(this.state.qCPart)}/>}
                />
                <Route
                    path='/coins'
                    render={(props) => <Coins {...props} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj}/>}
                />
                <Route
                    path='/trades'
                    render={(props) => <TradesClient {...props} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj} handleEndTrade={this.handleEndTrade} handleEditLongShort={this.handleEditLongShort}/>}
                />
                <Route
                    path='/investment'
                    render={(props) => <Investment {...props} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj} userID={this.state.userID} portfolio={this.state.portfolioObj}
                                              totalPortfolio={this.state.totalPortfolio} totalPortfolioUsd={this.state.totalPortfolioUsd} actualCPart={new Decimal(this.state.totalPortfolio).dividedBy(this.state.qCPart)}/>}
                />
                <Route
                    path='/goals'
                    render={(props) => <Goals {...props} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj} />}
                />
               {/* <Route
                    path='/liquidation'
                    render={(props) => <Liquidation {...props} clearTyped={this.clearTyped} search={this.state.search} btc={this.state.btc} user={this.state.userObj} totalPortfolio={this.state.totalPortfolio} actionFeedback={this.actionFeedback} />}
                />*/}

            </div>
        )
    }
})