import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider, withSnackbar } from 'notistack';
import firebase from '../../firebase';
import {Decimal} from 'decimal.js';


export default withSnackbar( class ImportTrade extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    handleClose = () => {
        this.setState({

        });
        this.props.handleClose();
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    test = () => {
        var context = this;
        this.props.trades.forEach(function (trade, idx) {
            context.props.enqueueSnackbar(idx, { variant: 'success' });
        })
    };

    onNewTrade = () => {
        this.handleClose();
        var db = firebase.firestore();
        const context = this;
        var portRef = db.collection('portfolios').doc(this.props.user.portfolioID);



        db.runTransaction(function (transaction) {
            return transaction.get(portRef).then(function (portfolio) {
                var tradesIDS = [];
                context.props.trades.forEach(function (trade) {
                    tradesIDS.push(trade.id);
                });
                var importedTrades = portfolio.data().importedTrades;
                var newImported = importedTrades.concat(tradesIDS);
                transaction.update(portRef, {importedTrades: newImported});
                return importedTrades;
            })
        });

        this.props.trades.forEach(function (trade, idx) {
            delete trade.portfolioName;
            var idxPlus = idx + 1;
            trade.portfolioID = context.props.user.portfolioID;

            if (trade.type === 'buy'){
                var db = firebase.firestore();
                var updateBtc = false;
                var updateCoin = false;
                db.collection('trades').add(trade).then(function () {
                    context.props.enqueueSnackbar('Trade importado ' + idxPlus + '/' + context.props.trades.length, { variant: 'success' });
                });

                var coinCol = db.collection('coins').where("portfolioID", "==", context.props.user.portfolioID).where("symbol", "==", trade.symbol);
                coinCol.get()
                    .then(function(querySnapshot) {

                        if (querySnapshot.size === 0){
                            db.collection('coins').where('symbol', '==', trade.symbol).get().then(function (coins) {
                                coins.forEach(function (coin) {
                                    console.log(coin);
                                    db.collection("coins").add({
                                        precioPonderado: 0,
                                        precioPonderadoUSD: 0,
                                        exchange: '',
                                        quantity: 0,
                                        portfolioID: context.props.user.portfolioID,
                                        name: coin.data().name,
                                        symbol: coin.data().symbol,
                                        imageUrl: coin.data().imageUrl
                                    }).then(function (doc) {
                                        var coinRef = db.collection('coins').doc(doc.id);
                                        db.runTransaction(function(transaction) {

                                            return transaction.get(coinRef).then(function (coinDoc) {
                                                if (!coinDoc.exists) {
                                                    throw "Document does not exist!";
                                                }

                                                if (coinDoc.data().precioPonderado != null) {
                                                    var quan = new Decimal(coinDoc.data().quantity).plus(parseFloat(trade.quantity));
                                                    var exchList = coinDoc.data().exchanges;

                                                    var numerador = new Decimal(new Decimal(coinDoc.data().precioPonderado).times(coinDoc.data().quantity))
                                                        .plus(new Decimal(trade.price).times(trade.quantity));

                                                    var numeradorUsd = new Decimal(new Decimal(coinDoc.data().precioPonderadoUSD).times(coinDoc.data().quantity))
                                                        .plus(new Decimal(trade.price).times(trade.btcUsd).times(trade.quantity));

                                                    if (coinDoc.data().precioPonderado === 0) {
                                                        var newPrice = new Decimal(trade.price);
                                                        var newPriceUsd = new Decimal(newPrice).times(trade.btcUsd);
                                                    } else {
                                                        var newPrice = numerador.dividedBy(quan);
                                                        var newPriceUsd = numeradorUsd.dividedBy(quan);
                                                    }


                                                    transaction.update(coinRef, {
                                                        precioPonderado: newPrice.toNumber(),
                                                        quantity: quan.toNumber(),
                                                        precioPonderadoUSD: newPriceUsd.toNumber(),
                                                    });
                                                    return newPrice;
                                                }
                                            });
                                        });
                                    })
                                });

                            })
                        } else {
                            querySnapshot.forEach(function(doc) {
                                // doc.data() is never undefined for query doc snapshots
                                /*console.log(doc.id, " => ", doc.data());*/
                                var coinRef = db.collection('coins').doc(doc.id);
                                db.runTransaction(function(transaction) {

                                    return transaction.get(coinRef).then(function (coinDoc) {
                                        if (!coinDoc.exists) {
                                            throw "Document does not exist!";
                                        }

                                        if (coinDoc.data().precioPonderado != null) {
                                            var quan = new Decimal(coinDoc.data().quantity).plus(parseFloat(trade.quantity));
                                            var exchList = coinDoc.data().exchanges;

                                            var numerador = new Decimal(new Decimal(coinDoc.data().precioPonderado).times(coinDoc.data().quantity))
                                                .plus(new Decimal(trade.price).times(trade.quantity));

                                            var numeradorUsd = new Decimal(new Decimal(coinDoc.data().precioPonderadoUSD).times(coinDoc.data().quantity))
                                                .plus(new Decimal(trade.price).times(trade.btcUsd).times(trade.quantity));

                                            if (coinDoc.data().precioPonderado === 0) {
                                                var newPrice = new Decimal(trade.price);
                                                var newPriceUsd = new Decimal(newPrice).times(trade.btcUsd);
                                            } else {
                                                var newPrice = numerador.dividedBy(quan);
                                                var newPriceUsd = numeradorUsd.dividedBy(quan);
                                            }


                                            transaction.update(coinRef, {
                                                precioPonderado: newPrice.toNumber(),
                                                quantity: quan.toNumber(),
                                                precioPonderadoUSD: newPriceUsd.toNumber(),
                                            });
                                            return newPrice;
                                        }
                                    });
                                });
                            });
                        }


                    })
                    .catch(function(error) {
                        console.log("Error getting documents: ", error);
                    });

                if (trade.symbol === 'BTC'){
                    updateBtc = true;
                } else {
                    var coinCol = db.collection('coins').where("portfolioID", "==", context.props.user.portfolioID).where("symbol", "==", 'BTC');
                    coinCol.get()
                        .then(function(querySnapshot) {
                            querySnapshot.forEach(function(doc) {
                                // doc.data() is never undefined for query doc snapshots
                                /*console.log(doc.id, " => ", doc.data());*/
                                var coinRef = db.collection('coins').doc(doc.id);
                                db.runTransaction(function(transaction) {
                                    return transaction.get(coinRef).then(function(coinDoc) {
                                        if (!coinDoc.exists) {
                                            throw "Document does not exist!";
                                        }

                                        var newQuan = new Decimal(coinDoc.data().quantity).minus(new Decimal(trade.quantity).times(trade.price));

                                        transaction.update(coinRef, { quantity: newQuan.toNumber() });
                                        return newQuan;
                                    });
                                });
                            });
                        })
                        .catch(function(error) {
                            console.log("Error getting documents: ", error);
                        });
                }

            }
            if (trade.type === 'sell'){

                var db = firebase.firestore();
                var updateBtc = false;
                var updateCoin = false;
                db.collection('trades').add(trade).then(function () {
                    context.props.enqueueSnackbar('Trade importado ' + idxPlus + '/' + context.props.trades.length, { variant: 'success' });
                });


                var coinCol = db.collection('coins').where("portfolioID", "==", context.props.user.portfolioID).where("symbol", "==", trade.symbol);
                coinCol.get()
                    .then(function(querySnapshot) {
                        if (querySnapshot.size === 0){
                            db.collection('coins').where('symbol', '==', trade.symbol).get().then(function (coins) {
                                coins.forEach(function (coin) {
                                    db.collection("coins").add({
                                        precioPonderado: 0,
                                        precioPonderadoUSD: 0,
                                        exchange: '',
                                        quantity: 0,
                                        portfolioID: context.props.user.portfolioID,
                                        name: coin.data().name,
                                        symbol: coin.data().symbol,
                                        imageUrl: coin.data().imageUrl
                                    }).then(function (doc) {
                                        var coinRef = db.collection('coins').doc(doc.id);
                                        db.runTransaction(function(transaction) {
                                            return transaction.get(coinRef).then(function(coinDoc) {
                                                if (!coinDoc.exists) {
                                                    throw "Document does not exist!";
                                                }

                                                var quan = new Decimal (coinDoc.data().quantity).minus(trade.quantity);
                                                transaction.update(coinRef, { quantity: quan.toNumber()});
                                                return quan;
                                            });
                                        });
                                    })
                                });

                            });
                        } else {
                            querySnapshot.forEach(function(doc) {
                                // doc.data() is never undefined for query doc snapshots
                                /*console.log(doc.id, " => ", doc.data());*/
                                var coinRef = db.collection('coins').doc(doc.id);
                                db.runTransaction(function(transaction) {
                                    return transaction.get(coinRef).then(function(coinDoc) {
                                        if (!coinDoc.exists) {
                                            throw "Document does not exist!";
                                        }

                                        var quan = new Decimal (coinDoc.data().quantity).minus(trade.quantity);
                                        transaction.update(coinRef, { quantity: quan.toNumber()});
                                        return quan;
                                    });
                                });
                            });
                        }
                    })
                    .catch(function(error) {
                        console.log("Error getting documents: ", error);
                    });

                if (trade.symbol === 'BTC'){
                    updateBtc = true;
                } else {
                    var coinCol = db.collection('coins').where("portfolioID", "==", context.props.user.portfolioID).where("symbol", "==", 'BTC');
                    coinCol.get()
                        .then(function(querySnapshot) {
                            querySnapshot.forEach(function(doc) {
                                // doc.data() is never undefined for query doc snapshots
                                /*console.log(doc.id, " => ", doc.data());*/
                                var coinRef = db.collection('coins').doc(doc.id);
                                db.runTransaction(function(transaction) {
                                    return transaction.get(coinRef).then(function(coinDoc) {
                                        if (!coinDoc.exists) {
                                            throw "Document does not exist!";
                                        }
                                        var qTradeBtc = new Decimal(trade.quantity).times(trade.price);
                                        var quan = new Decimal(coinDoc.data().quantity).plus(qTradeBtc);

                                        var numerador = new Decimal(new Decimal(coinDoc.data().precioPonderadoUSD).times(coinDoc.data().quantity))
                                            .plus(new Decimal(trade.btcUsd).times(qTradeBtc));

                                        var newPrice = numerador.dividedBy(quan);
                                        transaction.update(coinRef, { precioPonderadoUSD: newPrice.toNumber(), quantity: quan.toNumber() });
                                        return newPrice;
                                    });
                                });
                            });
                        })
                        .catch(function(error) {
                            console.log("Error getting documents: ", error);
                        });
                }
            }
            if (trade.type === 'long' || trade.type === 'short') {


                var db = firebase.firestore();
                db.collection('trades').add(trade).then(function () {
                    context.props.enqueueSnackbar('Trade importado ' + idxPlus + '/' + context.props.trades.length, { variant: 'success' });
                });

                if (!trade.state){
                    db.collection('coins').where('portfolioID', '==', context.props.user.portfolioID)
                        .where("symbol", "==", "BTC").get()
                        .then(function(querySnapshot) {
                            querySnapshot.forEach(function(doc) {
                                // doc.data() is never undefined for query doc snapshots
                                /*console.log(doc.id, " => ", doc.data());*/
                                var coinRef = db.collection('coins').doc(doc.id);
                                db.runTransaction(function(transaction) {
                                    return transaction.get(coinRef).then(function(coinDoc) {
                                        if (!coinDoc.exists) {
                                            throw "Document does not exist!";
                                        }

                                        var quan = coinDoc.data().quantity + parseFloat(trade.pnl);
                                        transaction.update(coinRef, { quantity: quan });
                                        return quan;
                                    });
                                });
                            });
                        })
                        .catch(function(error) {
                            console.log("Error getting documents: ", error);
                        });
                }
            }
        });
    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (

            <div>
                <MuiThemeProvider theme={theme}>

                        <Modal open={this.props.open} className={"modalInv"} onClose={this.handleClose}>
                            <Paper className={"modalPaper"} elevation={1}>
                                <p className={'title modalTi'}>¿Seguro que desea importar los trades seleccionados?</p>
                                <Button onClick={this.onNewTrade} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"}>
                                    Importar
                                </Button>
                            </Paper>
                        </Modal>

                </MuiThemeProvider>

            </div>

        )
    }
})