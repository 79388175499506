import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CoinsTable from './Coins/CoinsTable'

export default class Coins extends React.Component {

    static propTypes = {
        user: PropTypes.object,
        userData: PropTypes.object,
        handleChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
        this.props.clearTyped();
    }

    render(){
        return(
            <div className={'container'}>
                <p className={"title"}>Top 100 Monedas</p>
                <CoinsTable btc={this.props.btc} search={this.props.search} clearTyped={this.props.clearTyped}/>
            </div>
        )
    }
}