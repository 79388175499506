import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ClientsField from "./autocom/ClientsField";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CoinsField from "./autocom/CoinsField";
import BuysTable from "../Pages/trades/BuysTable";
import firebase from '../firebase';
import {Decimal} from 'decimal.js';
import BuysField from "./autocom/BuysField";

const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');



function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

export default class NewTrade extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            type: 'buy',
            coin: '',
            coinSymbol:'',
            lastSelected: '',
            precioPonderado: '',
            precioPonderadoUSD: '',
            quantity: '',
            price: '',
            date: getCurrentDate(),
            btcUsd:'',
            exchange: 'Bitmex',
            valorCpart: '',
            cost: '',
            lev: '',
            orderValue:'',
            error: false,
            buyForSell: '',
            buyForSellID:'',
            buyForSellTrade: {},
        };
    }
    handleClose = () => {
        this.setState({
            type: 'buy',
            coin: '',
            coinSymbol:'',
            lastSelected: '',
            quantity: '',
            price: '',
            date: getCurrentDate(),
            btcUsd:'',
            exchange: 'Bitmex',
            valorCpart: '',
            cost: '',
            lev: '',
            orderValue:'',
            error: false,
            buyForSell: '',
            buyForSellID:'',
            buyForSellTrade: {},
        });
        this.props.handleClose(2);
    };

    componentDidMount() {

    }

    handleChangeBuyForSellCoin = (newValue) => {
        this.setState({buyForSell: newValue})
    };
    handleChangeBuyForSellId = (newValue) => {
        this.setState({buyForSellID: newValue})
    };
    handleChangeBuyForSellTrade= (newValue) => {
        this.setState({buyForSellTrade: newValue})
        this.setState({
            coin: newValue.coin,
            coinSymbol: newValue.symbol,
            quantity: newValue.quantity,
            exchange: newValue.exchange,
        })
    };


    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
            error: false,
        });
    };
    handleChangeCoin = (newValue) => {
        this.setState({coin: newValue})
    };

    handleChangeSymbol = (symbol, precioPonderado, precioPonderadoUSD) => {
        this.setState({coinSymbol: symbol, precioPonderado: precioPonderado, precioPonderadoUSD: precioPonderadoUSD});

    };
    setLastSelected = (name) => {

        this.setState({lastSelected: name});
    };
    handleChangeType = event => {
        this.setState({ type: event.target.value, error: false });
    };


   addBtcUsd = () => {
        var db = firebase.firestore();
        db.collection('trades').where('type','==','short').where('state','==',false).get().then(function (snap) {
            snap.forEach(function (trade) {
                cc.priceHistorical('BTC', 'USD', new Date(trade.data().date * 1000)).then(prices => {

                    db.collection('trades').doc(trade.id).update({btcUsDEnd: firebase.firestore.FieldValue.delete()}).then(function () {
                        console.log(prices.USD)
                    })

                    // -> { BTC: { USD: 997, EUR: 948.17 } }
                })
                    .catch(console.error)
            })
        })
   };

    onNewTrade = () => {



        if (this.state.type === 'buy'){
            if (this.state.coin !== '' && this.state.coinSymbol !== '' &&  this.state.quantity !== '' && this.state.price !== '' &&
                this.state.date !== '' && this.state.btcUsd !== '' && this.state.exchange !== '') {
                if (this.state.coin === this.state.lastSelected){
                    var date = new Date(this.state.date);
                    date.setHours(date.getHours() + 4);
                    var timestamp = date.getTime()/1000;
                    var db = firebase.firestore();
                    const context = this;
                    var updateBtc = false;
                    var updateCoin = false;
                    db.collection('trades').add({
                        type: context.state.type,
                        coin: context.state.coin,
                        symbol: context.state.coinSymbol,
                        quantity: parseFloat(context.state.quantity),
                        price: parseFloat(context.state.price),
                        date: timestamp,
                        btcUsd: parseFloat(context.state.btcUsd),
                        exchange: context.state.exchange,
                        portfolioID: context.props.user.portfolioID,
                        state: true,
                    })
                        .then(function(docRef) {
                            console.log("Document written with ID: ", docRef.id);
                            context.props.actionFeedback(0,'Nuevo trade creado!');
                            /*context.handleClose();*/
                        })
                        .catch(function(error) {
                            console.error("Error adding document: ", error);
                        });


                    var coinCol = db.collection('coins').where("portfolioID", "==", this.props.user.portfolioID).where("symbol", "==", this.state.coinSymbol);
                    coinCol.get()
                        .then(function(querySnapshot) {
                            querySnapshot.forEach(function(doc) {
                                // doc.data() is never undefined for query doc snapshots
                                /*console.log(doc.id, " => ", doc.data());*/
                                var coinRef = db.collection('coins').doc(doc.id);
                                db.runTransaction(function(transaction) {

                                    return transaction.get(coinRef).then(function (coinDoc) {
                                        if (!coinDoc.exists) {
                                            throw "Document does not exist!";
                                        }

                                        if (coinDoc.data().precioPonderado != null) {
                                            var quan = new Decimal(coinDoc.data().quantity).plus(parseFloat(context.state.quantity));
                                            var exchList = coinDoc.data().exchanges;

                                            var numerador = new Decimal(new Decimal(coinDoc.data().precioPonderado).times(coinDoc.data().quantity))
                                                .plus(new Decimal(context.state.price).times(context.state.quantity));

                                            var numeradorUsd = new Decimal(new Decimal(coinDoc.data().precioPonderadoUSD).times(coinDoc.data().quantity))
                                                .plus(new Decimal(context.state.price).times(context.state.btcUsd).times(context.state.quantity));

                                            if (coinDoc.data().precioPonderado === 0) {
                                                var newPrice = new Decimal(context.state.price);
                                                var newPriceUsd = new Decimal(newPrice).times(context.state.btcUsd);
                                            } else {
                                                var newPrice = numerador.dividedBy(quan);
                                                var newPriceUsd = numeradorUsd.dividedBy(quan);
                                            }


                                            transaction.update(coinRef, {
                                                precioPonderado: newPrice.toNumber(),
                                                quantity: quan.toNumber(),
                                                precioPonderadoUSD: newPriceUsd.toNumber(),
                                                exchange: context.state.exchange
                                            });
                                            return newPrice;

                                        }


                                    });
                                }).then(function(newPrice) {
                                    console.log("Nuevo precio ponderado: ", newPrice);
                                    updateCoin = true;
                                    if (updateBtc){
                                        context.handleClose();
                                    }
                                }).catch(function(err) {
                                    // This will be an "population is too big" error.
                                    console.error(err);
                                });
                            });
                        })
                        .catch(function(error) {
                            console.log("Error getting documents: ", error);
                        });

                    if (context.state.coinSymbol === 'BTC'){
                        updateBtc = true;
                    } else {
                        var coinCol = db.collection('coins').where("portfolioID", "==", this.props.user.portfolioID).where("symbol", "==", 'BTC');
                        coinCol.get()
                            .then(function(querySnapshot) {
                                querySnapshot.forEach(function(doc) {
                                    // doc.data() is never undefined for query doc snapshots
                                    /*console.log(doc.id, " => ", doc.data());*/
                                    var coinRef = db.collection('coins').doc(doc.id);
                                    db.runTransaction(function(transaction) {
                                        return transaction.get(coinRef).then(function(coinDoc) {
                                            if (!coinDoc.exists) {
                                                throw "Document does not exist!";
                                            }

                                            var newQuan = new Decimal(coinDoc.data().quantity).minus(new Decimal(context.state.quantity).times(context.state.price));

                                            transaction.update(coinRef, { quantity: newQuan.toNumber() });
                                            return newQuan;


                                        });
                                    }).then(function(newPrice) {
                                        updateBtc = true;
                                        console.log('BTC actualizado a: ', newPrice);
                                        if (updateCoin){
                                            context.handleClose();
                                        }
                                    }).catch(function(err) {
                                        // This will be an "population is too big" error.
                                        console.error(err);
                                    });
                                });
                            })
                            .catch(function(error) {
                                console.log("Error getting documents: ", error);
                            });
                    }

                } else {
                    this.setState({error: true});
                    this.props.actionFeedback(1,'Moneda mal introducida')
                }

            }
            else {
                this.setState({error: true});
                this.props.actionFeedback(1,'Campos incompletos')
            }
        }
        if (this.state.type === 'sell'){
            if (this.state.coin && this.state.coinSymbol &&  this.state.quantity && this.state.price &&
                this.state.date && this.state.btcUsd && this.state.exchange) {
                if (this.state.coin === this.state.lastSelected){
                    var date = new Date(this.state.date);
                    date.setHours(date.getHours() + 4);
                    var timestamp = date.getTime()/1000;
                    var db = firebase.firestore();
                    const context = this;
                    var updateBtc = false;
                    var updateCoin = false;
                    db.collection('trades').add({
                        type: context.state.type,
                        coin: context.state.coin,
                        symbol: context.state.coinSymbol,
                        quantity: parseFloat(context.state.quantity),
                        price: parseFloat(context.state.price),
                        date: timestamp,
                        precioPonderado: context.state.precioPonderado,
                        precioPonderadoUSD: context.state.precioPonderadoUSD,
                        btcUsd: parseFloat(context.state.btcUsd),
                        exchange: context.state.exchange,
                        portfolioID: context.props.user.portfolioID,
                    })
                        .then(function(docRef) {
                            context.props.actionFeedback(0,'Nuevo trade creado!');
                            /*context.handleClose();*/
                        })
                        .catch(function(error) {
                            console.error("Error adding document: ", error);
                        })

                    if (this.state.buyForSellID){
                        db.collection('trades').doc(context.state.buyForSellID).update({state:false});
                    }


                    var coinCol = db.collection('coins').where("portfolioID", "==", this.props.user.portfolioID).where("symbol", "==", this.state.coinSymbol);
                    coinCol.get()
                        .then(function(querySnapshot) {
                            querySnapshot.forEach(function(doc) {
                                // doc.data() is never undefined for query doc snapshots
                                /*console.log(doc.id, " => ", doc.data());*/
                                var coinRef = db.collection('coins').doc(doc.id);
                                db.runTransaction(function(transaction) {
                                    return transaction.get(coinRef).then(function(coinDoc) {
                                        if (!coinDoc.exists) {
                                            throw "Document does not exist!";
                                        }

                                        var quan = new Decimal (coinDoc.data().quantity).minus(context.state.quantity);
                                        transaction.update(coinRef, { quantity: quan.toNumber() , exchange: context.state.exchange});
                                        return quan;



                                    });
                                }).then(function(quan) {
                                    console.log("Nueva cantidad de moneda: " , quan);
                                    updateCoin = true;
                                    if (updateBtc){
                                        context.handleClose();
                                    }
                                }).catch(function(err) {
                                    // This will be an "population is too big" error.
                                    console.error(err);
                                });
                            });
                        })
                        .catch(function(error) {
                            console.log("Error getting documents: ", error);
                        });

                    if (context.state.coinSymbol === 'BTC'){
                        updateBtc = true;
                    } else {
                        var coinCol = db.collection('coins').where("portfolioID", "==", this.props.user.portfolioID).where("symbol", "==", 'BTC');
                        coinCol.get()
                            .then(function(querySnapshot) {
                                querySnapshot.forEach(function(doc) {
                                    // doc.data() is never undefined for query doc snapshots
                                    /*console.log(doc.id, " => ", doc.data());*/
                                    var coinRef = db.collection('coins').doc(doc.id);
                                    db.runTransaction(function(transaction) {
                                        return transaction.get(coinRef).then(function(coinDoc) {
                                            if (!coinDoc.exists) {
                                                throw "Document does not exist!";
                                            }


                                            var qTradeBtc = new Decimal(context.state.quantity).times(context.state.price);
                                            var quan = new Decimal(coinDoc.data().quantity).plus(qTradeBtc);

                                            var numerador = new Decimal(new Decimal(coinDoc.data().precioPonderadoUSD).times(coinDoc.data().quantity))
                                                .plus(new Decimal(context.state.btcUsd).times(qTradeBtc));

                                            var newPrice = numerador.dividedBy(quan);
                                            transaction.update(coinRef, { precioPonderadoUSD: newPrice.toNumber(), quantity: quan.toNumber() });
                                            return newPrice;




                                        });
                                    }).then(function(newPrice) {
                                        updateBtc = true;
                                        if (updateCoin){
                                            context.handleClose();
                                        }
                                    }).catch(function(err) {
                                        // This will be an "population is too big" error.
                                        console.error(err);
                                    });
                                });
                            })
                            .catch(function(error) {
                                console.log("Error getting documents: ", error);
                            });
                    }

                } else {
                    this.setState({error: true});
                    this.props.actionFeedback(1,'Moneda mal introducida')
                }

            }
            else {
                this.setState({error: true});
                this.props.actionFeedback(1,'Campos incompletos')
            }

        }
        if (this.state.type === 'long' || this.state.type === 'short') {
            if (this.state.coin !== '' && this.state.coinSymbol !== '' && this.state.quantity !== '' && this.state.price !== '' &&
                this.state.date !== '' && this.state.btcUsd !== '' && this.state.exchange !== '') {
                if (this.state.coin === this.state.lastSelected) {
                    var date = new Date(this.state.date);
                    date.setHours(date.getHours() + 4);
                    var timestamp = date.getTime()/1000;
                    var db = firebase.firestore();
                    const context = this;
                    db.collection('trades').add({
                        type: context.state.type,
                        coin: context.state.coin,
                        symbol: context.state.coinSymbol,
                        quantity: parseFloat(context.state.quantity),
                        price: parseFloat(context.state.price),
                        date: timestamp,
                        btcUsd: parseFloat(context.state.btcUsd),
                        exchange: context.state.exchange,
                        cost: parseFloat(context.state.cost),
                        lev: parseFloat(context.state.lev),
                        orderValue: parseFloat(context.state.orderValue),
                        state: true,
                        portfolioID: context.props.user.portfolioID,
                    })
                        .then(function (docRef) {
                            context.props.actionFeedback(0, 'Nuevo trade creado!');
                            context.handleClose();
                        })
                        .catch(function (error) {
                            console.error("Error adding document: ", error);
                        });


                } else {
                    this.setState({error: true});
                    this.props.actionFeedback(1, 'Moneda mal introducida')
                }

            } else {
                this.setState({error: true});
                this.props.actionFeedback(1, 'Campos incompletos')
            }
        }
    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Modal open={this.props.open} className={"modalInv"} onClose={this.handleClose}>
                        <Paper className={"modalPaper"} elevation={1}>
                            <p className={'title modalTi'}>Nuevo Trade</p>
                            <FormControl component="fieldset" className={'formControl'}>
                                <RadioGroup
                                    aria-label="Tipo"
                                    name="type"
                                    className={'radioForm'}
                                    value={this.state.type}
                                    onChange={this.handleChangeType}
                                >
                                    <FormControlLabel value={'buy'} control={<Radio color={'primary'}/>} label="Compra" className={'controlFormLabel'} />
                                    <FormControlLabel value={'sell'} control={<Radio color={'primary'}/>} label="Venta" className={'controlFormLabel'} />
                                    <FormControlLabel value={'long'} control={<Radio color={'primary'}/>} label="Long" className={'controlFormLabel'} />
                                    <FormControlLabel value={'short'} control={<Radio color={'primary'}/>} label="Short" className={'controlFormLabel'} />
                                </RadioGroup>
                            </FormControl>
                            {this.state.type === 'buy' && <div>
                                <CoinsField error={this.state.error} setLastSelected={this.setLastSelected} handleChangeSymbol={this.handleChangeSymbol} value={this.state.coin} handleChange={this.handleChangeCoin} actionFeedback={this.props.actionFeedback} user={this.props.user}/>
                                <TextField
                                    value={this.state.quantity}
                                    onChange={this.handleChange('quantity')}
                                    error={this.state.error}
                                    label="Cantidad"
                                    type={'number'}
                                    className="fieldModal"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.price}
                                    onChange={this.handleChange('price')}
                                    error={this.state.error}
                                    label="Precio BTC"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.date}
                                    onChange={this.handleChange('date')}
                                    error={this.state.error}
                                    label="Fecha"
                                    className="fieldModal"
                                    type="date"
                                    name="date"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.btcUsd}
                                    onChange={this.handleChange('btcUsd')}
                                    error={this.state.error}
                                    label="USD/BTC"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.exchange}
                                    onChange={this.handleChange('exchange')}
                                    error={this.state.error}
                                    label="Exchange"
                                    className="fieldModal"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                /></div>}
                            {this.state.type === 'sell' && <div><CoinsField error={this.state.error} setLastSelected={this.setLastSelected} handleChangeSymbol={this.handleChangeSymbol} value={this.state.coin} handleChange={this.handleChangeCoin} actionFeedback={this.props.actionFeedback} user={this.props.user}/>
                                <TextField
                                    value={this.state.quantity}
                                    onChange={this.handleChange('quantity')}
                                    error={this.state.error}
                                    label="Cantidad"
                                    type={'number'}
                                    className="fieldModal"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.price}
                                    onChange={this.handleChange('price')}
                                    error={this.state.error}
                                    label="Precio BTC"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.date}
                                    onChange={this.handleChange('date')}
                                    error={this.state.error}
                                    label="Fecha"
                                    className="fieldModal"
                                    type="date"
                                    name="date"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.btcUsd}
                                    onChange={this.handleChange('btcUsd')}
                                    error={this.state.error}
                                    label="USD/BTC"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.exchange}
                                    onChange={this.handleChange('exchange')}
                                    error={this.state.error}
                                    label="Exchange"
                                    className="fieldModal"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <BuysField error={this.state.error} handleChangeBuyForSellTrade={this.handleChangeBuyForSellTrade} handleChangeClientId={this.handleChangeBuyForSellId} handleChangeClient={this.handleChangeBuyForSellCoin} value={this.state.buyForSell} user={this.props.user}/>

                            </div>}
                            {this.state.type === 'long' && <div><CoinsField error={this.state.error} setLastSelected={this.setLastSelected} handleChangeSymbol={this.handleChangeSymbol} value={this.state.coin} handleChange={this.handleChangeCoin} actionFeedback={this.props.actionFeedback} user={this.props.user}/>
                                <TextField
                                    value={this.state.quantity}
                                    onChange={this.handleChange('quantity')}
                                    error={this.state.error}
                                    label="Cantidad"
                                    type={'number'}
                                    className="fieldModal"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.price}
                                    onChange={this.handleChange('price')}
                                    error={this.state.error}
                                    label="Precio BTC"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.cost}
                                    onChange={this.handleChange('cost')}
                                    error={this.state.error}
                                    label="Cost"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.lev}
                                    onChange={this.handleChange('lev')}
                                    error={this.state.error}
                                    label="Lev"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.orderValue}
                                    onChange={this.handleChange('orderValue')}
                                    error={this.state.error}
                                    label="Order Value"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.date}
                                    onChange={this.handleChange('date')}
                                    error={this.state.error}
                                    label="Fecha"
                                    className="fieldModal"
                                    type="date"
                                    name="date"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.btcUsd}
                                    onChange={this.handleChange('btcUsd')}
                                    error={this.state.error}
                                    label="USD/BTC"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.exchange}
                                    onChange={this.handleChange('exchange')}
                                    error={this.state.error}
                                    label="Exchange"
                                    className="fieldModal"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                /></div>}
                            {this.state.type === 'short' && <div><CoinsField error={this.state.error} setLastSelected={this.setLastSelected} handleChangeSymbol={this.handleChangeSymbol} value={this.state.coin} handleChange={this.handleChangeCoin} actionFeedback={this.props.actionFeedback} user={this.props.user}/>
                                <TextField
                                    value={this.state.quantity}
                                    onChange={this.handleChange('quantity')}
                                    error={this.state.error}
                                    label="Cantidad"
                                    type={'number'}
                                    className="fieldModal"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.price}
                                    onChange={this.handleChange('price')}
                                    error={this.state.error}
                                    label="Precio BTC"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.cost}
                                    onChange={this.handleChange('cost')}
                                    error={this.state.error}
                                    label="Cost"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.lev}
                                    onChange={this.handleChange('lev')}
                                    error={this.state.error}
                                    label="Lev"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.orderValue}
                                    onChange={this.handleChange('orderValue')}
                                    error={this.state.error}
                                    label="Order Value"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.date}
                                    onChange={this.handleChange('date')}
                                    error={this.state.error}
                                    label="Fecha"
                                    className="fieldModal"
                                    type="date"
                                    name="date"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.btcUsd}
                                    onChange={this.handleChange('btcUsd')}
                                    error={this.state.error}
                                    label="USD/BTC"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.exchange}
                                    onChange={this.handleChange('exchange')}
                                    error={this.state.error}
                                    label="Exchange"
                                    className="fieldModal"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                /></div>}


                            <Button onClick={this.onNewTrade} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"}>
                                Cargar
                            </Button>
                        </Paper>
                    </Modal>
                </MuiThemeProvider>
            </div>
        )
    }
}