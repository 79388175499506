import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import bclogo from "../../images/BC_Logo_.png";
import firebase from "../../firebase";
import {Decimal} from 'decimal.js';
import { Link } from "react-router-dom";
import Fab from "@material-ui/core/Fab";

const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');

function createData(id,name, usdInvest, btcInvest,  cPartPriceBuy1, cPartPriceAct, quanCp, totalCparts, totalPortfolioUsd) {

    var cPartPriceBuy  = parseFloat(cPartPriceBuy1);
    let rend = new Decimal(cPartPriceAct).dividedBy(cPartPriceBuy).minus(1).times(100);


    let share = new Decimal(quanCp).dividedBy(totalCparts).times(100).toNumber();

    let rendUsd = new Decimal(share).times(totalPortfolioUsd).dividedBy(usdInvest).minus(100);

    let actualBtcInvest = rend.dividedBy(100).times(btcInvest).plus(btcInvest);

    let actualUsdInvest = rendUsd.dividedBy(100).times(usdInvest).plus(usdInvest);

    if (!actualUsdInvest.toNumber()) {
        actualUsdInvest = 0;
    }

    rend = rend.toNumber();
    rendUsd = rendUsd.toNumber();

    return { id, name, usdInvest, btcInvest, rend, quanCp, share, rendUsd , actualBtcInvest, actualUsdInvest, cPartPriceBuy};
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}






class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const rows = [
            { id: 'name', numeric: false, disablePadding: false, label: 'Nombre' },
            { id: 'usdInvest', numeric: true, disablePadding: false, label: 'Inversion USD' },
            { id: 'btcInvest', numeric: true, disablePadding: false, label: 'Inversion BTC' },
            { id: this.props.btc ? 'rend' : 'rendUsd', numeric: true, disablePadding: false, label: 'Rendimiento' },
            { id: 'actualUsdInvest', numeric: true, disablePadding: false, label: 'USD actual' },
            { id: 'actualBtcInvest', numeric: true, disablePadding: false, label: 'BTC actual' },
            { id: 'quanCp', numeric: true, disablePadding: false, label: 'Cantidad C.Partes' },
            { id: 'cPartPriceBuy', numeric: true, disablePadding: false, label: 'Valor C.P. Entrada' },
            { id: 'share', numeric: true, disablePadding: false, label: 'Participacion' },
        ];
        const {  order, orderBy, } = this.props;

        return (
            <TableHead className={'tableHead'}>
                <TableRow>
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const theme2 = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 660,
            md: 960,
            lg: 1280,
            xl: 1413,
        }
    }
});

const styles = theme => ({
    root: {
        width: '94%',
        marginTop: theme.spacing.unit * 3,
        marginLeft: '3%',
        borderRadius: 15,
        marginBottom: theme.spacing.unit * 3,
        [theme.breakpoints.up('md')]: {
            width: '96%',
            marginLeft: '2%',
        },
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    fab: {
        display: 'block',
        marginTop: '1em',
        [theme2.breakpoints.up('xl')]: {
            display: 'inline-block',
            marginTop: '0',
            marginRight: '2%',
            float: 'right'
        },

    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
});




class UsersTable extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'calories',
        selected: [],
        listener: '',
        coinsListener: '',
        data: [],
        dataFiltered: [],
        totalPortfolio: 0,
        totalPortfolioUsd: 0,
        qCParts: 0,
        sumInvesBtc: 0,
        sumInvesUsd: 0,
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    componentWillReceiveProps(nextProps) {
        if(nextProps.search){
            var filtUsers = [];
            this.state.data.forEach(function (user) {
                if (user.name.toLowerCase().includes(nextProps.search.toLowerCase())){
                    filtUsers.push(user);
                }
            });
            this.setState({dataFiltered: filtUsers})
        }
        else {
            this.setState({dataFiltered: this.state.data})
        }
        if (nextProps.user.portfolioID !== this.props.user.portfolioID) {
            if (this.state.listener) {
                this.state.listener();
            }
            if (this.state.coinsListener){
                this.state.coinsListener();
            }

            var db = firebase.firestore();
            var context = this;

            var symbolList = [];


            var coinsListener = db.collection('coins')
                .where('portfolioID', '==', nextProps.user.portfolioID)
                .onSnapshot(function (querySnapshot) {

                    var totalPortfolio = new Decimal(0);

                    querySnapshot.forEach(function (doc) {
                        symbolList.push(doc.data().symbol)
                    });

                    if (symbolList.length > 0){
                        cc.priceMulti(symbolList, ['BTC'])
                            .then(prices => {
                                querySnapshot.forEach(function (doc) {
                                    if (prices[doc.data().symbol]){
                                        totalPortfolio = totalPortfolio.plus(new Decimal(prices[doc.data().symbol].BTC).times(doc.data().quantity));
                                    } else {
                                        totalPortfolio = totalPortfolio.plus(new Decimal(doc.data().precioPonderado).times(doc.data().quantity));
                                    }

                                });

                                context.setState({totalPortfolio: totalPortfolio.toNumber()});
                                cc.price('BTC', ['USD'])
                                    .then(prices => {
                                        var totalPortfolioUsd = new Decimal(totalPortfolio).times(prices.USD);

                                        var listener = db.collection('clients').where('portfolioID', '==', nextProps.user.portfolioID)
                                            .onSnapshot(function (querySnapshot) {
                                                var usersList = [];
                                                var totalCparts = 0;
                                                var sumInvesBtc = 0;
                                                var sumInvesUsd = 0;
                                                querySnapshot.forEach(function (doc) {
                                                    totalCparts = totalCparts + doc.data().qCPart;
                                                });
                                                querySnapshot.forEach(function (doc) {
                                                    var user = createData(doc.id, doc.data().name + " " + doc.data().lastName,
                                                        doc.data().investmentUsd, doc.data().investmentBtc, doc.data().valorCpart,
                                                        totalPortfolio.dividedBy(totalCparts).toNumber(),
                                                        doc.data().qCPart, totalCparts, totalPortfolioUsd);
                                                    sumInvesBtc = sumInvesBtc + user.btcInvest;
                                                    sumInvesUsd = sumInvesUsd + user.usdInvest;
                                                    usersList.push(createData(doc.id, doc.data().name + " " + doc.data().lastName,
                                                        doc.data().investmentUsd, doc.data().investmentBtc, doc.data().valorCpart,
                                                        totalPortfolio.dividedBy(totalCparts).toNumber(),
                                                        doc.data().qCPart, totalCparts, totalPortfolioUsd))
                                                });

                                                context.setState({data: usersList, dataFiltered: usersList, qCParts: totalCparts, totalPortfolioUsd: totalPortfolioUsd,sumInvesUsd: sumInvesUsd, sumInvesBtc: sumInvesBtc})
                                            });
                                        context.setState({listener: listener})

                                        // -> { USD: 1100.24, EUR: 1039.63 }
                                    })
                                    .catch(console.error)
                                /*context.setState({actualValueCPart: totalPortfolio / docu.data().qCpart});
                                console.log(totalPortfolio / docu.data().qCpart);*/
                            })
                            .catch(console.error);
                    } else {
                        context.setState({totalPortfolio: totalPortfolio.toNumber(), totalPortfolioUsd: 0})
                    }



                });


            context.setState({coinsListener: coinsListener});



        }
    }

    componentDidMount() {
        if (this.props.user.portfolioID){
            var db = firebase.firestore();
            var context = this;

            var symbolList = [];


            var coinsListener = db.collection('coins')
                .where('portfolioID', '==', this.props.user.portfolioID)
                .onSnapshot(function (querySnapshot) {

                    var totalPortfolio = new Decimal(0);

                    querySnapshot.forEach(function (doc) {
                        symbolList.push(doc.data().symbol)
                    });

                    if (symbolList.length > 0){
                        cc.priceMulti(symbolList, ['BTC'])
                            .then(prices => {
                                querySnapshot.forEach(function (doc) {
                                    if (doc.data().quantity > 0){
                                        if (prices[doc.data().symbol]){

                                            totalPortfolio = totalPortfolio.plus(new Decimal(prices[doc.data().symbol].BTC).times(doc.data().quantity));
                                        } else {
                                            totalPortfolio = totalPortfolio.plus(new Decimal(doc.data().precioPonderado).times(doc.data().quantity));

                                        }
                                    }


                                });

                                context.setState({totalPortfolio: totalPortfolio.toNumber()});
                                cc.price('BTC', ['USD'])
                                    .then(prices => {
                                        var totalPortfolioUsd = new Decimal(totalPortfolio).times(prices.USD);

                                        var listener = db.collection('clients').where('portfolioID', '==', context.props.user.portfolioID)
                                            .onSnapshot(function (querySnapshot) {
                                                var usersList = [];
                                                var totalCparts = 0;
                                                var sumaPor = 0;
                                                var sumInvesBtc = 0;
                                                var sumInvesUsd = 0;
                                                querySnapshot.forEach(function (doc) {
                                                    totalCparts = totalCparts + doc.data().qCPart;
                                                });
                                                querySnapshot.forEach(function (doc) {
                                                    var user = createData(doc.id, doc.data().name + " " + doc.data().lastName,
                                                        doc.data().investmentUsd, doc.data().investmentBtc, doc.data().valorCpart,
                                                        totalPortfolio.dividedBy(totalCparts).toNumber(),
                                                        doc.data().qCPart, totalCparts, totalPortfolioUsd);
                                                    sumaPor = sumaPor + user.quanCp;
                                                    sumInvesBtc = sumInvesBtc + user.btcInvest;
                                                    sumInvesUsd = sumInvesUsd + user.usdInvest;
                                                    usersList.push(createData(doc.id, doc.data().name + " " + doc.data().lastName,
                                                        doc.data().investmentUsd, doc.data().investmentBtc, doc.data().valorCpart,
                                                        totalPortfolio.dividedBy(totalCparts).toNumber(),
                                                        doc.data().qCPart, totalCparts, totalPortfolioUsd))
                                                });

                                                console.log('total cpart: ', totalCparts);
                                                /*console.log(totalPortfolioUsd.toNumber(), 'totalUsd');*/
                                                context.setState({data: usersList, dataFiltered: usersList, qCParts: totalCparts, totalPortfolioUsd: totalPortfolioUsd, sumInvesUsd: sumInvesUsd, sumInvesBtc: sumInvesBtc})
                                            });
                                        context.setState({listener: listener})

                                        // -> { USD: 1100.24, EUR: 1039.63 }
                                    })
                                    .catch(console.error)
                                /*context.setState({actualValueCPart: totalPortfolio / docu.data().qCpart});
                                console.log(totalPortfolio / docu.data().qCpart);*/
                            })
                            .catch(console.error);
                    } else {
                        context.setState({totalPortfolio: totalPortfolio.toNumber(), totalPortfolioUsd: 0})
                    }



                });


            context.setState({coinsListener: coinsListener});






        }
    }
    componentWillUnmount() {
        if (this.state.listener){
            this.state.listener();
        }
        if (this.state.coinsListener){
            this.state.coinsListener();
        }
    }


    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;
        const { dataFiltered, order, orderBy, selected, } = this.state;
        const breakpointValues = {
            xs: 0,
            sm: 660,
            md: 960,
            lg: 1280,
            xl: 1413,
        };
        const theme = createMuiTheme({
            breakpoints: { values: breakpointValues },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                <p className={"title importTradesTitle"}>Usuarios
                    <Fab variant="extended" color="primary" aria-label="Importar" className={classes.fab}>
                        {this.props.actualCPart ? 'Valor Cuota parte: ' + this.props.actualCPart.toNumber().toFixed(4) : ''}
                    </Fab>
                    <Fab variant="extended" color="primary" aria-label="Importar" className={classes.fab}>
                        {'Total Cuota partes: ' + this.state.qCParts.toFixed(4)}
                    </Fab>
                    <Fab variant="extended" color="primary" aria-label="Importar" className={classes.fab}>
                        {this.props.btc ? 'Total Portfolio: ₿' + this.state.totalPortfolio.toFixed(8): 'Total Portfolio: $' + this.state.totalPortfolioUsd.toFixed(2)}
                    </Fab>
                    <Fab variant="extended" color="primary" aria-label="Importar" className={classes.fab}>
                        {this.props.btc ? 'Total Invertido: ₿' + this.state.sumInvesBtc.toFixed(8): 'Total Invertido: $' + this.state.sumInvesUsd.toFixed(2)}
                    </Fab>
                </p>
                <Paper className={classes.root}>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                btc={this.props.btc}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                rowCount={dataFiltered.length}
                            />
                            <TableBody className={'tableBody'}>
                                {stableSort(dataFiltered, getSorting(order, orderBy))
                                    .map(n => {
                                        const isSelected = this.isSelected(n.id);
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.id}
                                                className={classes.row}
                                                selected={isSelected}
                                                onClick={() => {this.props.handleClientIdSelected(n.id, n)}}
                                            >

                                                <TableCell component="th" scope="row">
                                                    {n.name}
                                                </TableCell>

                                                <TableCell numeric>{'$ ' + n.usdInvest.toFixed(2)}</TableCell>
                                                <TableCell numeric>{'₿ '  + n.btcInvest.toFixed(8)}</TableCell>

                                                { n.quanCp ?
                                                    this.props.btc &&
                                                    <TableCell numeric className={n.rend > 0 ? 'rendPos' : 'rendNeg'} >{n.rend.toFixed(2) + '%'}</TableCell> :
                                                    this.props.btc && <TableCell numeric>{'-'}</TableCell>}
                                                { n.quanCp ?
                                                    !this.props.btc &&
                                                    <TableCell numeric className={n.rendUsd > 0 ? 'rendPos' : 'rendNeg'} >{n.rendUsd.toFixed(2) + '%'}</TableCell> :
                                                    !this.props.btc && <TableCell numeric>{'-'}</TableCell> }
                                                <TableCell numeric>{'$' + n.actualUsdInvest.toFixed(2)}</TableCell>
                                                <TableCell numeric>{'₿ '  + n.actualBtcInvest.toFixed(8)}</TableCell>
                                                <TableCell numeric>{n.quanCp.toFixed(4)}</TableCell>
                                                <TableCell numeric>{n.cPartPriceBuy.toFixed(4)}</TableCell>
                                                <TableCell numeric>{n.share.toFixed(2) + '%'}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </div>

                </Paper>
                </MuiThemeProvider>
            </div>

        );
    }
}

UsersTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UsersTable);