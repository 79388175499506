import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from "./firebase";
import AppComponent from "./AppComponent";
import ClientAppComponent from "./client/ClientAppComponent";
import LoadBackComponent from "./LoadBackComponent";
import { withRouter } from "react-router-dom";

export default withRouter( class SuperAppComponent extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            type:''
        };
    }

    componentDidMount() {
        const firestore = firebase.firestore();
        const settings = {/* your settings... */ timestampsInSnapshots: true};
        firestore.settings(settings);
        const context = this;
        this.props.history.push('portfolio');
        var db = firebase.firestore();
        var docRef = db.collection('admins').where("userID", "==", this.props.user.uid);
        docRef.get()
            .then(function(querySnapshot) {
                if (querySnapshot.size > 0) {
                    context.setState({type: 'admin'})
                }
                else {
                    context.setState({type: 'client'})
                }
            })
    }

    render() {
        return (
            <div>
                {this.state.type === '' &&  <LoadBackComponent/>}
                {this.state.type === 'admin' &&  <AppComponent user={this.props.user}/>}
                {this.state.type === 'client' &&  <ClientAppComponent user={this.props.user}/>}
            </div>
        )
    }
})