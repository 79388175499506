import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ClientsField from "./autocom/ClientsField";
import firebase from '../firebase';

function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

export default class NewClient extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lastName: '',
            date: getCurrentDate(),
            email:'',
            password: '',
            fee: '',
            valorCpart: '',
            error: false,
        };
    }

    componentDidMount() {

    }

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
            error: false,
        });
    };
    handleChangeClient = (newValue) => {
        this.setState({client: newValue})
    };

    editClients = () => {


        const db = firebase.firestore();
        const context = this;

        db.collection('clients').where('portfolioID', '==', context.props.user.portfolioID).get().then(function (snap) {
            snap.forEach(function (user) {
                var date = new Date(context.getUserDate(user.data().email));
                date.setHours(date.getHours() + 4);
                db.collection('clients').doc(user.id).update({date: date.getTime()/1000}).then(function () {
                    console.log('update ' + user.data().name)
                })
            })
        })
    }

    getUserDate = (email) => {
        var arrUsers = [{name:"Diego",lastName:"Cazap",email:"diego.cazap@gmail.com",investmentUsd:900,investmentBtc:0.10291215,date:"09/04/17",fee:0,valorCpart:0.0502},
            {name:"Matias",lastName:"Cohen",email:"cohen.matiasc@gmail.com",investmentUsd:200,investmentBtc:0.04530176,date:"09/04/17",fee:15,valorCpart:0.0453},
            {name:"Alan",lastName:"Erdei",email:"alane1993@hotmail.com",investmentUsd:494,investmentBtc:0.05974786,date:"09/04/17",fee:15,valorCpart:0.0473},
            {name:"Lucas",lastName:"Maceiras",email:"lucass.maceiras@hotmail.com",investmentUsd:1273,investmentBtc:0.07195924,date:"09/04/17",fee:15,valorCpart:0.0434},
            {name:"Julian",lastName:"Hilale",email:"julianurielhilale@gmail.com",investmentUsd:400,investmentBtc:0.07180288,date:"09/04/17",fee:15,valorCpart:0.0430},
            {name:"Maximo",lastName:"Boskis",email:"boskismaximo@gmail.com",investmentUsd:800,investmentBtc:0.10666730,date:"09/04/17",fee:10,valorCpart:0.0486},
            {name:"Alejandro",lastName:"Safdie",email:"safdiealejandro@gmail.com",investmentUsd:4672,investmentBtc:0.40038934,date:"09/04/17",fee:15,valorCpart:0.0422},
            {name:"Eric",lastName:"Catz",email:"ericmcatz@gmail.com",investmentUsd:840,investmentBtc:0.14211561,date:"10/09/17",fee:15,valorCpart:0.0408},
            {name:"Federico",lastName:"Estrin",email:"fede.estrin@live.com.ar",investmentUsd:1000,investmentBtc:0.14062433,date:"11/14/17",fee:15,valorCpart:0.0400},
            {name:"Brian",lastName:"Hamui",email:"brianhamui24@gmail.com",investmentUsd:106,investmentBtc:0.01558533,date:"11/14/17",fee:15,valorCpart:0.03995000},
            {name:"Damian",lastName:"Senderosky",email:"damiansenderosk@gmail.com",investmentUsd:500,investmentBtc:0.05232833,date:"11/30/17",fee:15,valorCpart:0.0472},
            {name:"Kevin",lastName:"Rubins",email:"kevinrubins7@gmail.com",investmentUsd:500,investmentBtc:0.05232833,date:"11/30/17",fee:15,valorCpart:0.0472},
            {name:"Martin",lastName:"Chueke",email:"martinchueke@gmail.com",investmentUsd:1500,investmentBtc:0.15698500,date:"11/30/17",fee:15,valorCpart:0.0472},
            {name:"Maximiliano",lastName:"Oliver",email:"maximilianooliver@hotmail.com",investmentUsd:500,investmentBtc:0.05232833,date:"11/30/17",fee:0,valorCpart:0.0472},
            {name:"Brian",lastName:"Pochne",email:"bripoch@gmail.com",investmentUsd:15000,investmentBtc:0.79412120,date:"12/15/17",fee:20,valorCpart:0.0411},
            {name:"Sebastian",lastName:"Spiguel",email:"sebastian.spiguel@gmail.com",investmentUsd:1000,investmentBtc:0.05294141,date:"12/15/17",fee:15,valorCpart:0.0411},
            {name:"Matias",lastName:"Perez",email:"dmatiasperez@gmail.com",investmentUsd:10000,investmentBtc:0.52941413,date:"12/15/17",fee:20,valorCpart:0.0411},
            {name:"Jimmy",lastName:"Perez",email:"jaimeperezdirecto@gmail.com",investmentUsd:10000,investmentBtc:0.52941413,date:"12/15/17",fee:20,valorCpart:0.0411},
            {name:"Ariel",lastName:"Chaufan",email:"arichaufan@gmail.com",investmentUsd:9800,investmentBtc:0.51882585,date:"12/15/17",fee:20,valorCpart:0.0411},
            {name:"Ariel",lastName:"Safdie",email:"aasafdie@gmail.com",investmentUsd:8400,investmentBtc:0.65650413,date:"12/15/17",fee:15,valorCpart:0.0474},
            {name:"Ezequiel",lastName:"Droblas",email:"ezequieldroblas@gmail.com",investmentUsd:2300,investmentBtc:0.12176525,date:"12/15/17",fee:20,valorCpart:0.0411},
            {name:"Juan Segundo",lastName:"Guareschi",email:"jsguareschi@gmail.com",investmentUsd:700,investmentBtc:0.04850744,date:"12/15/17",fee:20,valorCpart:0.0455},
            {name:"Ezequiel",lastName:"Cazap",email:"ecazap@icloud.com",investmentUsd:7500,investmentBtc:0.47045848,date:"12/15/17",fee:0,valorCpart:0.0462},
            {name:"Nicolas",lastName:"Scolnic",email:"nscolnic@gmail.com",investmentUsd:3000,investmentBtc:0.15882424,date:"12/15/17",fee:15,valorCpart:0.0411},
            {name:"Bruno",lastName:"Daniele",email:"danieleabruno@gmail.com",investmentUsd:3000,investmentBtc:0.15882424,date:"12/15/17",fee:15,valorCpart:0.0411},
            {name:"Mariano",lastName:"Biocca",email:"mariano.biocca@gmail.com",investmentUsd:1000,investmentBtc:0.05294141,date:"12/15/17",fee:15,valorCpart:0.0411},
            {name:"Adam",lastName:"Kirschbaum",email:"adam.kirschbaum@hotmail.com",investmentUsd:7000,investmentBtc:0.57093771,date:"01/18/18",fee:20,valorCpart:0.0480},
            {name:"Agustin",lastName:"Trajtenberg",email:"agustintrasten@gmail.com",investmentUsd:14788,investmentBtc:1.00000000,date:"02/26/18",fee:0,valorCpart:0.0472},
            {name:"Hernan",lastName:"Fartuzek",email:"hernanfartu@hotmail.com",investmentUsd:950,investmentBtc:0.11270433,date:"03/19/18",fee:20,valorCpart:0.0384},
            {name:"Luis Alejandro",lastName:"Paravati",email:"azzurroinformatica@gmail.com",investmentUsd:4298,investmentBtc:0.51515021,date:"03/22/18",fee:20,valorCpart:0.0408},
            {name:"Sebastian",lastName:"Bordon",email:"bordonsa@hotmail.com",investmentUsd:1940,investmentBtc:0.22974404,date:"05/12/18",fee:20,valorCpart:0.0547},
            {name:"Roberto",lastName:"Ritter",email:"betoritter9@me.com",investmentUsd:2857,investmentBtc:0.37638557,date:"06/06/18",fee:20,valorCpart:0.0511},
            {name:"Damian",lastName:"Bertuetti",email:"bertuettidamian@yahoo.com.ar",investmentUsd:4717,investmentBtc:0.69681606,date:"06/20/18",fee:20,valorCpart:0.0474},
            {name:"Jonny",lastName:"Keller",email:"johnny.keller.v@gmail.com",investmentUsd:6495,investmentBtc:1.00000000,date:"10/24/18",fee:20,valorCpart:0.0380}];

        for (var user of arrUsers) {
            if (user.email === email){
                return user.date
            }
        }
    }

    addClientsScript = () => {
        var arrUsers = [{name:"Diego",lastName:"Cazap",email:"diego.cazap@gmail.com",investmentUsd:900,investmentBtc:0.10291215,date:"09/04/17",fee:0,valorCpart:0.0502},
            {name:"Matias",lastName:"Cohen",email:"cohen.matiasc@gmail.com",investmentUsd:200,investmentBtc:0.04530176,date:"09/04/17",fee:15,valorCpart:0.0453},
            {name:"Alan",lastName:"Erdei",email:"alane1993@hotmail.com",investmentUsd:494,investmentBtc:0.05974786,date:"09/04/17",fee:15,valorCpart:0.0473},
            {name:"Lucas",lastName:"Maceiras",email:"lucass.maceiras@hotmail.com",investmentUsd:1273,investmentBtc:0.07195924,date:"09/04/17",fee:15,valorCpart:0.0434},
            {name:"Julian",lastName:"Hilale",email:"julianurielhilale@gmail.com",investmentUsd:400,investmentBtc:0.07180288,date:"09/04/17",fee:15,valorCpart:0.0430},
            {name:"Maximo",lastName:"Boskis",email:"boskismaximo@gmail.com",investmentUsd:800,investmentBtc:0.10666730,date:"09/04/17",fee:10,valorCpart:0.0486},
            {name:"Alejandro",lastName:"Safdie",email:"safdiealejandro@gmail.com",investmentUsd:4672,investmentBtc:0.40038934,date:"09/04/17",fee:15,valorCpart:0.0422},
            {name:"Eric",lastName:"Catz",email:"ericmcatz@gmail.com",investmentUsd:840,investmentBtc:0.14211561,date:"10/09/17",fee:15,valorCpart:0.0408},
            {name:"Federico",lastName:"Estrin",email:"fede.estrin@live.com.ar",investmentUsd:1000,investmentBtc:0.14062433,date:"11/14/17",fee:15,valorCpart:0.0400},
            {name:"Brian",lastName:"Hamui",email:"brianhamui24@gmail.com",investmentUsd:106,investmentBtc:0.01558533,date:"11/14/17",fee:15,valorCpart:0.03995000},
            {name:"Damian",lastName:"Senderosky",email:"damiansenderosk@gmail.com",investmentUsd:500,investmentBtc:0.05232833,date:"11/30/17",fee:15,valorCpart:0.0472},
            {name:"Kevin",lastName:"Rubins",email:"kevinrubins7@gmail.com",investmentUsd:500,investmentBtc:0.05232833,date:"11/30/17",fee:15,valorCpart:0.0472},
            {name:"Martin",lastName:"Chueke",email:"martinchueke@gmail.com",investmentUsd:1500,investmentBtc:0.15698500,date:"11/30/17",fee:15,valorCpart:0.0472},
            {name:"Maximiliano",lastName:"Oliver",email:"maximilianooliver@hotmail.com",investmentUsd:500,investmentBtc:0.05232833,date:"11/30/17",fee:0,valorCpart:0.0472},
            {name:"Brian",lastName:"Pochne",email:"bripoch@gmail.com",investmentUsd:15000,investmentBtc:0.79412120,date:"12/15/17",fee:20,valorCpart:0.0411},
            {name:"Sebastian",lastName:"Spiguel",email:"sebastian.spiguel@gmail.com",investmentUsd:1000,investmentBtc:0.05294141,date:"12/15/17",fee:15,valorCpart:0.0411},
            {name:"Matias",lastName:"Perez",email:"dmatiasperez@gmail.com",investmentUsd:10000,investmentBtc:0.52941413,date:"12/15/17",fee:20,valorCpart:0.0411},
            {name:"Jimmy",lastName:"Perez",email:"jaimeperezdirecto@gmail.com",investmentUsd:10000,investmentBtc:0.52941413,date:"12/15/17",fee:20,valorCpart:0.0411},
            {name:"Ariel",lastName:"Chaufan",email:"arichaufan@gmail.com",investmentUsd:9800,investmentBtc:0.51882585,date:"12/15/17",fee:20,valorCpart:0.0411},
            {name:"Ariel",lastName:"Safdie",email:"aasafdie@gmail.com",investmentUsd:8400,investmentBtc:0.65650413,date:"12/15/17",fee:15,valorCpart:0.0474},
            {name:"Ezequiel",lastName:"Droblas",email:"ezequieldroblas@gmail.com",investmentUsd:2300,investmentBtc:0.12176525,date:"12/15/17",fee:20,valorCpart:0.0411},
            {name:"Juan Segundo",lastName:"Guareschi",email:"jsguareschi@gmail.com",investmentUsd:700,investmentBtc:0.04850744,date:"12/15/17",fee:20,valorCpart:0.0455},
            {name:"Ezequiel",lastName:"Cazap",email:"ecazap@icloud.com",investmentUsd:7500,investmentBtc:0.47045848,date:"12/15/17",fee:0,valorCpart:0.0462},
            {name:"Nicolas",lastName:"Scolnic",email:"nscolnic@gmail.com",investmentUsd:3000,investmentBtc:0.15882424,date:"12/15/17",fee:15,valorCpart:0.0411},
            {name:"Bruno",lastName:"Daniele",email:"danieleabruno@gmail.com",investmentUsd:3000,investmentBtc:0.15882424,date:"12/15/17",fee:15,valorCpart:0.0411},
            {name:"Mariano",lastName:"Biocca",email:"mariano.biocca@gmail.com",investmentUsd:1000,investmentBtc:0.05294141,date:"12/15/17",fee:15,valorCpart:0.0411},
            {name:"Adam",lastName:"Kirschbaum",email:"adam.kirschbaum@hotmail.com",investmentUsd:7000,investmentBtc:0.57093771,date:"01/18/18",fee:20,valorCpart:0.0480},
            {name:"Agustin",lastName:"Trajtenberg",email:"agustintrasten@gmail.com",investmentUsd:14788,investmentBtc:1.00000000,date:"02/26/18",fee:0,valorCpart:0.0472},
            {name:"Hernan",lastName:"Fartuzek",email:"hernanfartu@hotmail.com",investmentUsd:950,investmentBtc:0.11270433,date:"03/19/18",fee:20,valorCpart:0.0384},
            {name:"Luis Alejandro",lastName:"Paravati",email:"azzurroinformatica@gmail.com",investmentUsd:4298,investmentBtc:0.51515021,date:"03/22/18",fee:20,valorCpart:0.0408},
            {name:"Sebastian",lastName:"Bordon",email:"bordonsa@hotmail.com",investmentUsd:1940,investmentBtc:0.22974404,date:"05/12/18",fee:20,valorCpart:0.0547},
            {name:"Roberto",lastName:"Ritter",email:"betoritter9@me.com",investmentUsd:2857,investmentBtc:0.37638557,date:"06/06/18",fee:20,valorCpart:0.0511},
            {name:"Damian",lastName:"Bertuetti",email:"bertuettidamian@yahoo.com.ar",investmentUsd:4717,investmentBtc:0.69681606,date:"06/20/18",fee:20,valorCpart:0.0474},
            {name:"Jonny",lastName:"Keller",email:"johnny.keller.v@gmail.com",investmentUsd:6495,investmentBtc:1.00000000,date:"10/24/18",fee:20,valorCpart:0.0380}];
        const db = firebase.firestore();
        const context = this;
        arrUsers.forEach(function (user) {

            db.collection('clients').where('email', '==', user.email).get().then(function (snap) {
                snap.forEach(function (doc) {
                    db.collection('clients').doc(doc.id).update({
                        investmentUsd: user.investmentUsd,
                        investmentBtc: user.investmentBtc,
                    }).then(function (doc) {
                        console.log(user.email + " updated" )
                    })
                })
            })

        })


    };

    onNewClient = () => {

        if (this.state.name && this.state.lastName && this.state.email && this.state.password
            && this.state.fee && this.state.valorCpart){
            const db = firebase.firestore();
            const context = this;
            var timestamp = new Date(this.state.date).getTime()/1000;

            var userDatabase = false;
            var userAuth = false;


            db.collection('clients').where('email','==',context.state.email).get().then(function(querySnapshot) {
                if(querySnapshot.size > 0){
                    context.setState({error: true, });
                    context.props.actionFeedback(1, 'Ya existe ese cliente en el portfolio');
                }
                else {

                    let config = {
                        apiKey: "AIzaSyDC1R33pTAJGCni6UV5cERvSLtJ5gTdjxw",
                        authDomain: "portfolio-eaaf7.firebaseapp.com",
                        databaseURL: "https://portfolio-eaaf7.firebaseio.com",
                        projectId: "portfolio-eaaf7",
                        storageBucket: "portfolio-eaaf7.appspot.com",
                        messagingSenderId: "232310493554"
                    };



                    var secondaryApp = firebase.initializeApp(config, context.state.name + context.state.lastName + context.state.email+timestamp.toString());
                    secondaryApp.auth().createUserWithEmailAndPassword(context.state.email, context.state.password).then(function(firebaseUser) {
                        console.log("User " + firebaseUser.user.uid + " created successfully!");
                        secondaryApp.auth().signOut();
                        userAuth = true;



                        db.collection('clients').add({
                            name: context.state.name,
                            portfolioID: context.props.user.portfolioID,
                            lastName: context.state.lastName,
                            date: timestamp,
                            email:context.state.email,
                            fee: parseFloat(context.state.fee),
                            valorCpart: parseFloat(context.state.valorCpart),
                            investmentBtc: 0,
                            investmentUsd: 0,
                            qCPart: 0,
                            userID: firebaseUser.user.uid,
                        })
                            .then(function(docRef) {
                                console.log("Document written with ID: ", docRef.id);
                                context.props.actionFeedback(0, 'Usuario creado');
                                context.handleClose();

                            })
                            .catch(function(error) {
                                console.error("Error adding document: ", error);
                            });

                    });
                }
            }).catch(function(error) {
                    console.log("Error getting documents: ", error);
                });


        } else {
            this.setState({error: true});
            this.props.actionFeedback(1, 'Campos incompletos')
        }





    };

    handleClose = () =>{
      this.setState({
          name: '',
          lastName: '',
          date: getCurrentDate(),
          email:'',
          password: '',
          fee: '',
          valorCpart: '',
          error: false,
      });
        this.props.handleClose(1);


    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Modal open={this.props.open} className={"modalInv"} onClose={this.handleClose}>
                        <Paper className={"modalPaper"} elevation={1}>
                            <p className={'title modalTi'}>Nuevo Cliente</p>
                            <TextField
                                value={this.state.name}
                                onChange={this.handleChange('name')}
                                error={this.state.error}
                                label="Nombre"
                                className="fieldModal"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.lastName}
                                onChange={this.handleChange('lastName')}
                                error={this.state.error}
                                label="Apellido"
                                className="fieldModal"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.password}
                                onChange={this.handleChange('password')}
                                error={this.state.error}
                                label="Contraseña"
                                className="fieldModal"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.date}
                                onChange={this.handleChange('date')}
                                error={this.state.error}
                                label="Fecha"
                                className="fieldModal"
                                type="date"
                                name="date"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.email}
                                onChange={this.handleChange('email')}
                                error={this.state.error}
                                label="E-mail"
                                className="fieldModal"
                                type="email"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.fee}
                                onChange={this.handleChange('fee')}
                                error={this.state.error}
                                label="Fee (%)"
                                className="fieldModal"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.valorCpart}
                                onChange={this.handleChange('valorCpart')}
                                error={this.state.error}
                                label="Valor C.Parte"
                                className="fieldModal"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />

                            <Button onClick={this.onNewClient} variant="contained" color="primary" className={"loginButton modalButton"}>
                                Cargar
                            </Button>
                        </Paper>
                    </Modal>
                </MuiThemeProvider>
            </div>
        )
    }
}