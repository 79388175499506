import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import bclogo from "../../images/BC_Logo_.png";
import firebase from '../../firebase';

const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');

function createData(id,name, tp1, tp2, tp3, stopLoss, priceAct) {
    return { id, name, tp1, tp2, tp3, stopLoss, priceAct };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Nombre' },
    { id: 'tp1', numeric: true, disablePadding: false, label: 'TP 1' },
    { id: 'tp2', numeric: true, disablePadding: false, label: 'TP 2' },
    { id: 'tp3', numeric: true, disablePadding: false, label: 'TP 3' },
    { id: 'stopLoss', numeric: true, disablePadding: false, label: 'Stop Loss' },
    { id: 'priceAct', numeric: true, disablePadding: false, label: 'Precio hoy' },
    { id: 'delete', numeric: false, disablePadding: false, label: 'Eliminar' },

];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {  order, orderBy, } = this.props;

        return (
            <TableHead className={'tableHead'}>
                <TableRow>
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '94%',
        marginTop: theme.spacing.unit * 3,
        marginLeft: '3%',
        borderRadius: 15,
        marginBottom: theme.spacing.unit * 3,
        [theme.breakpoints.up('md')]: {
            width: '96%',
            marginLeft: '2%',
        },
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class GoalsTable extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'calories',
        selected: [],
        data: [],
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.user.portfolioID !== this.props.user.portfolioID){
            var db = firebase.firestore();
            var context = this;


            db.collection('objectives').where('portfolioID', '==', nextProps.user.portfolioID)
                .onSnapshot(function (querySnapshot) {
                    var symbolList = [];
                    var goalList = [];
                    querySnapshot.forEach(function (doc) {
                        symbolList.push(doc.data().coinSymbol);
                    });

                    if (symbolList.length > 0){
                        cc.priceMulti(symbolList, ['BTC'])
                            .then(prices => {
                                querySnapshot.forEach(function (doc) {
                                    goalList.push(createData(doc.id, doc.data().coin, doc.data().tp1, doc.data().tp2,
                                        doc.data().tp3,doc.data().stopLoss, prices[doc.data().coinSymbol].BTC))
                                });

                                context.setState({data: goalList})

                            })
                            .catch(console.error);
                    }
                    else {
                        context.setState({data: goalList})
                    }
                })
        }
    }

    componentDidMount() {
        if (this.props.user.portfolioID){
            var db = firebase.firestore();
            var context = this;


            db.collection('objectives').where('portfolioID', '==', this.props.user.portfolioID)
                .onSnapshot(function (querySnapshot) {
                    var symbolList = [];
                    var goalList = [];
                    querySnapshot.forEach(function (doc) {
                        symbolList.push(doc.data().coinSymbol);
                    });

                    if (symbolList.length > 0){
                        cc.priceMulti(symbolList, ['BTC'])
                            .then(prices => {
                                querySnapshot.forEach(function (doc) {
                                    goalList.push(createData(doc.id, doc.data().coin, doc.data().tp1, doc.data().tp2,
                                        doc.data().tp3,doc.data().stopLoss, prices[doc.data().coinSymbol].BTC))
                                });

                                context.setState({data: goalList})

                            })
                            .catch(console.error);
                    }
                    else {
                        context.setState({data: goalList})
                    }




                })
        }
    }

    onDelete = (id) => {
        var db = firebase.firestore();
        db.collection("objectives").doc(id).delete().then(function() {
            console.log("Document successfully deleted!");
        }).catch(function(error) {
            console.error("Error removing document: ", error);
        });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;
        const { data, order, orderBy, selected, } = this.state;


        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={data.length}
                        />
                        <TableBody className={'tableBody'}>
                            {stableSort(data, getSorting(order, orderBy))
                                .map(n => {
                                    const isSelected = this.isSelected(n.id);
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >
                                            <TableCell component="th" scope="row">
                                                {n.name}
                                            </TableCell>

                                            <TableCell numeric>{'₿ ' + n.tp1}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.tp2}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.tp3}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.stopLoss}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.priceAct}</TableCell>
                                            <TableCell component="th" scope="row"><IconButton  onClick={() => {this.onDelete(n.id)}}><i className={'ion-md-trash'}/></IconButton></TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>

            </Paper>
        );
    }
}

GoalsTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GoalsTable);