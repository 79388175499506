import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import bclogo from "../../images/BC_Logo_.png";
import firebase from '../../firebase';
import {Decimal} from 'decimal.js';
import ExtraCoin from "../../modals/ExtraCoin";
import DeleteTrade from "../../modals/DeleteTrade";
import Fab from "@material-ui/core/Fab";
import ThreeSixty from '@material-ui/icons/ThreeSixty';
import ImportTrade from "./ImportTrade";


const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');

function timestampToDateString(timestamp) {
    var date = new Date(timestamp * 1000);
    var year = date.getFullYear();
    var month = date.getMonth()+1;
    var day = date.getDate();

    return day + '/' + month + '/' + year;


}
function createData(id, coin, symbol, quantity, buyPrBtc, btcUsd, pract, date, exchange, prActUsd, type) {
    let total = new Decimal(buyPrBtc).times(quantity);
    let rend =  new Decimal(pract).dividedBy(buyPrBtc).minus(1).times(100);
    let rendNet = new Decimal(pract).times(quantity).minus(total);

    let buyPrUsd = new Decimal(buyPrBtc).times(btcUsd);
    let totalUsd = buyPrUsd.times(quantity);
    let rendUsd = new Decimal(prActUsd).dividedBy(buyPrUsd).minus(1).times(100);
    let rendNetUsd = new Decimal(prActUsd).times(quantity).minus(totalUsd);


    return { id, coin, symbol, quantity, buyPrBtc, buyPrUsd, total, pract,rend, rendNet, date, exchange, prActUsd,totalUsd, rendUsd, rendNetUsd, type };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [

    { id: 'portfolioName', numeric: false, disablePadding: false, label: 'Portfolio' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Tipo' },
    { id: 'coin', numeric: false, disablePadding: false, label: 'Moneda' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Fecha' },
    { id: 'quantity', numeric: true, disablePadding: false, label: 'Cantidad' },


];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

        return (
            <TableHead className={'tableHead'}>

                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '94%',
        marginTop: theme.spacing.unit * 3,
        marginLeft: '3%',
        borderRadius: 15,
        marginBottom: theme.spacing.unit * 3,
        [theme.breakpoints.up('md')]: {
            width: '96%',
            marginLeft: '2%',
        },
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fab: {
        marginRight: '2%',
        float: 'right'
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});

class ImportTradesTable extends React.Component {
    state = {
        order: 'desc',
        buysListener: '',
        orderBy: 'date',
        selected: [],
        data: [],
        dataFiltered: [],
        tradeSelected : {},
        openImportTrade: false,
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.data.map(n => n) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, n) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(n);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, n);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    componentWillReceiveProps(nextProps) {
        if(nextProps.search){
            var filtTrades = [];
            this.state.data.forEach(function (trades) {
                if (trades.coin.toLowerCase().includes(nextProps.search.toLowerCase()) ||
                    trades.symbol.toLowerCase().includes(nextProps.search.toLowerCase()) || trades.portfolioName.toLowerCase().includes(nextProps.search.toLowerCase()) ){
                    filtTrades.push(trades);
                }
            });
            this.setState({dataFiltered: filtTrades})
        }
        else {
            this.setState({dataFiltered: this.state.data})
        }
        if (nextProps.user.portfolioID !== this.props.user.portfolioID) {
            if (this.state.buysListener){
                this.state.buysListener();
            }
            var db = firebase.firestore();
            const context = this;



            db.collection('portfolios').get().then(function (docs) {
                var port ={};
                docs.forEach(function (portfolio) {

                    port[portfolio.id] = portfolio.data().name;
                    console.log(port[portfolio.id])
                });
                var buysListener = db.collection("trades")
                    .onSnapshot(function(querySnapshot) {
                        var buysList = [];


                        querySnapshot.forEach(function(doc) {
                            /* buysList.push(doc.data())*/
                            var trade = doc.data();
                            trade.id = doc.id;
                            if (trade.portfolioID !== nextProps.user.portfolioID) {
                                if (!nextProps.portfolio.importedTrades.includes(trade.id)) {
                                    trade.portfolioName = port[trade.portfolioID];
                                    buysList.push(trade)
                                }

                            }

                        });

                        context.setState({data: buysList, dataFiltered: buysList})

                    });
                context.setState({buysListener: buysListener})



                });
        }
    }

    componentDidMount() {
        this.props.clearTyped();

        if (this.props.user.portfolioID !== undefined){

            var db = firebase.firestore();
            const context = this;

            var symbolList = [];

            db.collection('portfolios').get().then(function (docs) {
                var port ={};
                docs.forEach(function (portfolio) {

                     port[portfolio.id] = portfolio.data().name;
                     console.log(port[portfolio.id])
                });
                var buysListener = db.collection("trades")
                    .onSnapshot(function(querySnapshot) {
                        var buysList = [];


                        querySnapshot.forEach(function(doc) {
                            /* buysList.push(doc.data())*/
                            var trade = doc.data();
                            trade.id = doc.id;
                            var type = trade.type;
                            if (trade.portfolioID !== context.props.user.portfolioID) {
                                if (!context.props.portfolio.importedTrades.includes(trade.id)) {
                                    trade.portfolioName = port[trade.portfolioID];
                                    /*if (!trade.portfolioName){
                                        console.log(trade)
                                        db.collection('trades').doc(trade.id).delete()
                                    }*/
                                    var rend = 0;
                                    var rendUsd = 0;


                                    buysList.push(trade)
                                }

                            }

                        });

                        context.setState({data: buysList, dataFiltered: buysList})

                    });
                context.setState({buysListener: buysListener})

            });


        }
    }
    componentWillUnmount() {
        if (this.state.buysListener !== '') {
            this.state.buysListener()
        }

    }

    onDelete = (trade) =>{
        this.setState({tradeSelected: trade})
        this.handleOpen();
    };
    handleClose = () =>{
        this.setState({openImportTrade: false})
    };
    handleOpen = () =>{
        this.setState({openImportTrade: true})
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;



    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#3F51B5',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });
        const { classes } = this.props;
        const { dataFiltered, order, orderBy, selected, } = this.state;


        return (
            <div className={'container'}>
                <p className={"title importTradesTitle"}>Importar trades
                    <Fab disabled={this.state.selected.length === 0} onClick={this.handleOpen} variant="extended" color="primary" aria-label="Importar" className={classes.fab}>
                        <ThreeSixty className={classes.extendedIcon} />
                        Importar trades
                    </Fab>
                </p>
                <MuiThemeProvider theme={theme}>
                    <Paper className={classes.root}>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={dataFiltered.length}
                                />
                                <TableBody className={'tableBody'}>
                                    {stableSort(dataFiltered, getSorting(order, orderBy))
                                        .map(n => {
                                            const isSelected = this.isSelected(n);
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    onClick={event => this.handleClick(event, n)}
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={n.id}
                                                    className={classes.row}
                                                    selected={isSelected}
                                                >

                                                    <TableCell padding="checkbox">
                                                        <Checkbox color="primary" checked={isSelected} />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {n.portfolioName}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {n.type.charAt(0).toUpperCase() + n.type.slice(1)}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {n.coin}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">{timestampToDateString(n.date)}</TableCell>

                                                    <TableCell numeric>{n.quantity}</TableCell>


                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </div>
                        <ImportTrade open={this.state.openImportTrade} handleClose={this.handleClose}  actionFeedback={this.props.actionFeedback} user={this.props.user} trades={this.state.selected}/>



                    </Paper>
                </MuiThemeProvider>
            </div>


        );
    }
}

ImportTradesTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImportTradesTable);