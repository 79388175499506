import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popper from '@material-ui/core/Popper';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CoinsApiField from "./CoinsApiField";
import firebase from '../../firebase'
import Snackbar from "@material-ui/core/Snackbar";
import SymbolApiField from "./SymbolApiField";

const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');




function renderInputComponent(inputProps) {
    const {error, onClickNew ,classes, inputRef = () => {}, ref, ...other } = inputProps;


    return (
        <div>
            <TextField
                className={'fieldModal clientsField'}
                variant={"outlined"}
                margin="normal"
                error={error}
                InputLabelProps={{
                    classes: {
                        root: '',
                        focused: 'inputCustomLogin',
                    },
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end"> <IconButton onClick={onClickNew} color={"primary"}><i className={'ion-md-add-circle-outline'}/></IconButton></InputAdornment>,
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: {
                        root: '',
                        focused: 'inputCustomLogin',
                        notchedOutline: 'inputCustomLogin',
                    },
                }}
                {...other}
            />

        </div>


    );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </strong>
                    );
                })}
            </div>
        </MenuItem>
    );
}



/*function getSuggestionValue(suggestion) {
    alert(suggestion.label);
    return suggestion.label;
}*/

const styles = theme => ({
    root: {
        flexGrow: 1,
        display: 'inline-block',
        marginLeft: '3%',
        width: '45.5%',
    },
    container: {
        position: 'relative',
        display: 'inline-block'
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 2,
        marginTop: theme.spacing.unit,
        left: '3%',
        width: '44.5%',
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});

class CoinsField extends React.Component {
    state = {
        single: '',
        popper: '',
        suggestions: [],
        open: false,
        anchorEl: null,
        newName: '',
        newSymbol: '',
        errorSymbol: false,
        errorNewName: false,
        suggestionsCoins: [],
        labelSymbol: "Simbolo",
        coinsListener: {},
        imageUrl: '',

    };

    getSuggestionValue = (suggestion) => {
        this.setState({newSymbol: suggestion.symbol});
        this.props.handleChangeSymbol(suggestion.symbol, suggestion.precioPonderado, suggestion.precioPonderadoUSD);
        this.props.setLastSelected(suggestion.label);
        return suggestion.label;
    };

    getSuggestions2 = (value) => {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0
            ? []
            : this.state.suggestionsCoins.filter(suggestion => {
                const keep =
                    count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    };

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions2(value),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = name => (event, { newValue }) => {
        if (name === 'single'){
            this.props.handleChange(newValue);
        }
        else{

            this.setState({
                [name]: event.target.value,
                labelSymbol:"Simbolo",
                errorSymbol: false,
                errorNewName: false,
            });
        }
    };

    handleChangeSym = name => (event) => {
        if (name === 'single'){
            this.props.handleChange(event.target.value);
        }
        else{
            this.setState({
                [name]: event.target.value,
            });
        }

    };

    componentDidMount() {
        var suggestionCoinsList = [];
        var db = firebase.firestore();
        const context = this;


        var coinsListener = db.collection('coins').where('portfolioID', '==', this.props.user.portfolioID).onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                suggestionCoinsList.push({label: doc.data().name, symbol: doc.data().symbol, precioPonderado: doc.data().precioPonderado, precioPonderadoUSD: doc.data().precioPonderadoUSD})
            });
            context.setState({
                suggestionsCoins: suggestionCoinsList,
            })
        });

        this.setState({coinsListener: coinsListener})

    }

    componentWillUnmount() {
        this.state.coinsListener();
    }

    /*handleChange = name => (event, { newValue }) => {
        this.props.handleChange(newValue);
    };

*/
    getSuggestApiField = (suggestion) => {
        this.getSymbolForName(suggestion.label);
        return suggestion.label;
    };
    getSuggestApiFieldSymbol = (suggestion) => {
        this.getNameForSymbol(suggestion.label);
        return suggestion.label;
    };

    getSymbolForName = (name) =>{

        cc.coinList()
            .then(coinList => {
                for (var key in coinList.Data) {
                    if (coinList.Data.hasOwnProperty(key)) {
                        if (coinList.Data[key].CoinName === name){
                            this.setState({
                                newSymbol: coinList.Data[key].Symbol,
                                imageUrl: coinList.Data[key].ImageUrl,
                                labelSymbol:"Simbolo",
                                errorSymbol: false,
                                errorNewName: false,
                            });
                            break
                        }
                    }
                }
                // ->
                // {
                //   BTC: {
                //    Id: "1182",
                //    Url: "/coins/btc/overview",
                //    ImageUrl: "/media/19633/btc.png",
                //    Name: "BTC",
                //    Symbol: "BTC",
                //    CoinName: "Bitcoin",
                //    FullName: "Bitcoin (BTC)",
                //    Algorithm: "SHA256",
                //    ProofType: "PoW",
                //    FullyPremined: "0",
                //    TotalCoinSupply: "21000000",
                //    PreMinedValue: "N/A",
                //    TotalCoinsFreeFloat: "N/A",
                //    SortOrder: "1",
                //    Sponsored: false
                // },
                //   ETH: {...},
                // }

            })
            .catch(console.error);

    };

    getNameForSymbol = (name) =>{

        cc.coinList()
            .then(coinList => {
                for (var key in coinList.Data) {
                    if (coinList.Data.hasOwnProperty(key)) {
                        if (coinList.Data[key].Symbol === name){
                            this.setState({
                                newName: coinList.Data[key].CoinName,
                                imageUrl: coinList.Data[key].ImageUrl,
                                labelSymbol:"Simbolo",
                                errorSymbol: false,
                                errorNewName: false,
                            });
                            break
                        }
                    }
                }
                // ->
                // {
                //   BTC: {
                //    Id: "1182",
                //    Url: "/coins/btc/overview",
                //    ImageUrl: "/media/19633/btc.png",
                //    Name: "BTC",
                //    Symbol: "BTC",
                //    CoinName: "Bitcoin",
                //    FullName: "Bitcoin (BTC)",
                //    Algorithm: "SHA256",
                //    ProofType: "PoW",
                //    FullyPremined: "0",
                //    TotalCoinSupply: "21000000",
                //    PreMinedValue: "N/A",
                //    TotalCoinsFreeFloat: "N/A",
                //    SortOrder: "1",
                //    Sponsored: false
                // },
                //   ETH: {...},
                // }

            })
            .catch(console.error);

    };

    handleChangeNew = (newValue) => {
        this.setState({newName: newValue})
    };
    handleChangeNewSymbol = (newValue) => {
        this.setState({newSymbol: newValue})
    };

    onClickNew = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };
    handleClose = () => {
        this.setState({
            anchorEl: null,
            newName: '',
            imageUrl: '',
            newSymbol: '',
            errorSymbol: false,
            errorNewName: false,
            labelSymbol: "Simbolo"
        });
    };


    createNewCoin = ()=> {
        var db = firebase.firestore();

        if (this.state.newName !== '' && this.state.newSymbol !== ''){
            var colRef = db.collection("coins").where("portfolioID", "==", this.props.user.portfolioID).where("symbol", "==", this.state.newSymbol);
            var docRef = db.collection("coins").doc(this.state.newSymbol);
            const  context = this;

            colRef.get()
                .then(function(querySnapshot) {
                    if (querySnapshot.size > 0){
                        context.setState({errorSymbol: true, errorNewName: true,});
                        context.props.actionFeedback(1, 'Ya existe esa moneda en el portfolio');
                        /*querySnapshot.forEach(function(doc) {
                            // doc.data() is never undefined for query doc snapshots
                            console.log(doc.id, " => ", doc.data());

                        });*/
                    } else {
                        db.collection("coins").add({
                            precioPonderado: 0,
                            precioPonderadoUSD: 0,
                            exchange: '',
                            quantity: 0,
                            portfolioID: context.props.user.portfolioID,
                            name: context.state.newName,
                            symbol: context.state.newSymbol,
                            imageUrl: context.state.imageUrl
                        })
                            .then(function(docRef) {
                                console.log("Document written with ID: ", docRef.id);
                                context.props.actionFeedback(0, 'Moneda Subida!');
                                context.props.handleChange(context.state.newName);
                                context.props.handleChangeSymbol(context.state.newSymbol);
                                context.props.setLastSelected(context.state.newName);
                                context.handleClose();
                            })
                            .catch(function(error) {
                                console.error("Error adding document: ", error);
                            });

                        console.log('No existe!')
                    }

                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
                    context.setState({errorSymbol: true, errorNewName: true,});
                    context.props.actionFeedback(1, 'Error getting documents');
                });

        }
        else {
            this.setState({errorSymbol: true, errorNewName: true,});
            this.props.actionFeedback(1, 'No eligio Moneda');
        }

        /*this.handleClose();*/
    };
    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const autosuggestProps = {
            renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue: this.getSuggestionValue,
            renderSuggestion,
        };

        return (
            <div className={classes.root}>
                <Autosuggest
                    {...autosuggestProps}
                    inputProps={{
                        classes,
                        onClickNew: this.onClickNew,
                        label:'Moneda',
                        value: this.props.value,
                        error: this.props.error,
                        onChange: this.handleChange('single'),
                    }}
                    theme={{
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                />
                <Popover
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className={'paperNewCoin'}>
                        <CoinsApiField handleChange={this.handleChangeNew} value={this.state.newName} getSuggestApiField={this.getSuggestApiField} error={this.state.errorNewName}/>
                        <SymbolApiField handleChange={this.handleChangeNewSymbol} value={this.state.newSymbol} getSuggestApiField={this.getSuggestApiFieldSymbol} error={this.state.errorSymbol}/>

                        {/*<TextField
                            value={this.state.newSymbol}
                            onChange={this.handleChangeSym('newSymbol')}
                            error={this.state.errorSymbol}
                            label={this.state.labelSymbol}
                            className="fieldModal newCoinField"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    root: '',
                                    focused: 'inputCustomLogin',
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: '',
                                    focused: 'inputCustomLogin',
                                    notchedOutline: 'inputCustomLogin',
                                },
                            }}
                        />*/}
                        <Button type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"} onClick={this.createNewCoin} >
                            Cargar
                        </Button>

                    </div>

                </Popover>


            </div>
        );
    }
}

CoinsField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoinsField);