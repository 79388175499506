import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ImportTradesTable from "./importtrades/ImportTradesTable";


export default class Import extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className={''}>

                <ImportTradesTable actionFeedback={this.props.actionFeedback} portfolio={this.props.portfolio} btc={this.props.btc} user={this.props.user} search={this.props.search} clearTyped={this.props.clearTyped}/>
            </div>
        )
    }
}