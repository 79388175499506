import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ClientsField from "./autocom/ClientsField";
import CoinsField from "./autocom/CoinsField";
import firebase from '../firebase';

function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

export default class EndTrade extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
        trade: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            pnl: '',
            price: '',
            error: false,
            btcUsdEnd: '',
            date: getCurrentDate(),
        };
    }

    componentWillUnmount() {

    }

    componentDidMount() {

    }

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
            error: false,
        });
    };

    handleClose = () => {
        this.setState({
            pnl: 0,
            price: '',
            error: false,
            btcUsdEnd: '',
            date: getCurrentDate(),
        });
        this.props.handleClose(4);
    };

    onTradeEnd = () => {
        if (this.state.pnl && this.state.price){

            const context = this;
            var db = firebase.firestore();
            var updateTrade = false;
            var updateBtc = false;
            var date = new Date(this.state.date);
            date.setHours(date.getHours() + 4);
            var timestamp = date.getTime()/1000;

            db.collection('trades').doc(this.props.trade.id).update({
                state: false,
                priceClosed: parseFloat(this.state.price),
                pnl: parseFloat(this.state.pnl),
                btcUsdEnd: parseFloat(this.state.btcUsdEnd),
                date: timestamp,
            }).then(function () {
                updateTrade = true;
                if (updateBtc){
                    context.handleClose();
                    context.props.actionFeedback(0,'Trade cerrado!')
                }
            });

            db.collection('coins').where('portfolioID', '==', this.props.user.portfolioID)
                .where("symbol", "==", "BTC").get()
                .then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                        // doc.data() is never undefined for query doc snapshots
                        /*console.log(doc.id, " => ", doc.data());*/
                        var coinRef = db.collection('coins').doc(doc.id);
                        db.runTransaction(function(transaction) {
                            return transaction.get(coinRef).then(function(coinDoc) {
                                if (!coinDoc.exists) {
                                    throw "Document does not exist!";
                                }

                                var quan = coinDoc.data().quantity + parseFloat(context.state.pnl);
                                transaction.update(coinRef, { quantity: quan });
                                return quan;



                            });
                        }).then(function(quan) {
                            console.log("Nueva cantidad de btc: " , quan);
                            updateBtc = true;
                            if (updateTrade){
                                context.handleClose();
                                context.props.actionFeedback(0,'Trade cerrado!');
                            }
                        }).catch(function(err) {
                            // This will be an "population is too big" error.
                            console.error(err);
                        });
                    });
                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
                });



        }else {
            this.setState({
                error: true,
            })
        }
    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Modal open={this.props.open} className={"modalInv"} onClose={this.handleClose}>
                        <Paper className={"modalPaper"} elevation={1}>
                            <p className={'title modalTi'}>Terminar {this.props.trade.type}</p>
                            <p className={'title modalTi endModalP'}>{this.props.trade.coin}</p>
                            <p className={'title modalTi endModalP'}>{'Rendimiento: %' + ((parseFloat(this.state.pnl)/ this.props.trade.cost * 100).toFixed(2) ? (parseFloat(this.state.pnl)/ this.props.trade.cost * 100).toFixed(2) : '') }</p>
                            <TextField
                                value={this.state.price}
                                onChange={this.handleChange('price')}
                                error={this.state.error}
                                label="Precio"
                                className="fieldModal"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.pnl}
                                onChange={this.handleChange('pnl')}
                                error={this.state.error}
                                label="PNL"
                                className="fieldModal"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.btcUsdEnd}
                                onChange={this.handleChange('btcUsdEnd')}
                                error={this.state.error}
                                label="BTC/USD"
                                className="fieldModal"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />

                            <TextField
                                value={this.state.date}
                                onChange={this.handleChange('date')}
                                error={this.state.error}
                                label="Fecha"
                                className="fieldModal"
                                type="date"
                                name="date"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />

                            <Button onClick={this.onTradeEnd} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"}>
                                Cargar
                            </Button>
                        </Paper>
                    </Modal>
                </MuiThemeProvider>
            </div>
        )
    }
}