import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {Line} from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2'
import {Pie} from 'react-chartjs-2';
import firebase from '../firebase';
import {Decimal} from 'decimal.js';
import PortsTableClient from "./PortsTableClient";
import { withStyles } from '@material-ui/core/styles';
import Fab from "@material-ui/core/Fab";

const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');

const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

function createData(id, name, symbol, quantity, precioPonderado, sumAll, prAct, exchange, imageUrl, precioPonderadoUSD, prActUSD) {

    let total = new Decimal(prAct).times(quantity);
    let part = total.dividedBy(sumAll).times(100).toNumber();
    let rend = new Decimal(prAct).dividedBy(precioPonderado).minus(1).times(100).toNumber();
    let rendNet = total.minus(new Decimal(precioPonderado).times(quantity)).toNumber();
    total = total.toNumber();

    let totalUSD = new Decimal(prActUSD).times(quantity);
    let rendUSD = new Decimal(prActUSD).dividedBy(precioPonderadoUSD).minus(1).times(100).toNumber();
    let rendNetUSD = totalUSD.minus(new Decimal(precioPonderadoUSD).times(quantity)).toNumber();
    totalUSD = totalUSD.toNumber();



    return { id, symbol ,name, quantity, precioPonderado, total, part, prAct, rend, rendNet, exchange, imageUrl, precioPonderadoUSD, prActUSD, totalUSD, rendUSD, rendNetUSD };
}

const styles = theme => ({
    fab: {
        display: 'block',
        marginTop: '1em',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            marginTop: '-8px',
            marginRight: '2%',
            float: 'right'
        },

    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
});

class PortfolioClient extends React.Component {

    static propTypes = {
        user: PropTypes.object,

    };

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            data: [],
            dataFiltered: [],
            portListener: '',
            dates: [],
            values: [],
            dataRend: {},
            btcUsd: 0,
        };
    }
    componentWillUnmount() {
        if (this.state.portListener !== ''){
            this.state.portListener();
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.user.portfolioID !== this.props.user.portfolioID ) {

            if (this.state.portListener){
                this.state.portListener();
            }

            var db = firebase.firestore();
            const context = this;

            cc.price('BTC', ['USD']).then(price => {
                context.setState({btcUsd: price.USD})
            });

            var symbolList = [];

            var portListener = db.collection("coins").where("portfolioID", "==", nextProps.user.portfolioID)
                .onSnapshot(function (querySnapshot) {
                    var portList = [];
                    querySnapshot.forEach(function (doc) {
                        if (doc.data().quantity > 0) {
                            symbolList.push(doc.data().symbol);
                        }
                    });

                    if (symbolList.length > 0){
                        cc.priceMulti(symbolList, ['BTC', 'USD'])
                            .then(prices => {
                                var sumaAll = 0;


                                querySnapshot.forEach(function (doc) {
                                    if (doc.data().quantity > 0) {
                                        if (prices[doc.data().symbol]){
                                            sumaAll = sumaAll + prices[doc.data().symbol].BTC * doc.data().quantity;
                                        }
                                        else {
                                            sumaAll = sumaAll + doc.data().precioPonderado * doc.data().quantity;
                                        }
                                    }
                                });
                                querySnapshot.forEach(function (doc) {
                                    if (doc.data().quantity > 0) {

                                        if ( prices[doc.data().symbol]){
                                            portList.push(createData(doc.id, doc.data().name, doc.data().symbol, doc.data().quantity,
                                                doc.data().precioPonderado, sumaAll,  prices[doc.data().symbol] ? prices[doc.data().symbol].BTC : 0, doc.data().exchange,
                                                doc.data().imageUrl, doc.data().precioPonderadoUSD,  prices[doc.data().symbol] ?  prices[doc.data().symbol].USD : 0))
                                        }
                                        else {
                                            portList.push(createData(doc.id, doc.data().name, doc.data().symbol, doc.data().quantity,
                                                doc.data().precioPonderado, sumaAll,  doc.data().precioPonderado, doc.data().exchange,
                                                doc.data().imageUrl, doc.data().precioPonderadoUSD,  doc.data().precioPonderadoUSD))
                                        }
                                    }
                                });

                                context.setState({
                                    data: portList,
                                    dataFiltered: portList,
                                });


                                // -> { BTC: { USD: 1114.63, EUR: 1055.82 },
                                //      ETH: { USD: 12.74, EUR: 12.06 } }
                            })
                            .catch(console.error);
                    }else {
                        context.setState({
                            data: portList,
                            dataFiltered: portList,
                        });
                    }




                    context.setState({portListener: portListener});

                })
        }
        if (nextProps.search){
            var filtCoins = [];
            this.state.data.forEach(function (coin) {
                if (coin.name.toLowerCase().includes(nextProps.search.toLowerCase()) ||
                    coin.symbol.toLowerCase().includes(nextProps.search.toLowerCase())){
                    filtCoins.push(coin);
                }
            });
            this.setState({dataFiltered: filtCoins})
        }
        else {
            this.setState({dataFiltered: this.state.data})
        }
        if (JSON.stringify(nextProps.portfolio.historicCpart) !== JSON.stringify(this.props.portfolio.historicCpart)){

            var arrayHistoric = nextProps.portfolio.historicCpart;
            var values = [];
            var dates = [];
            arrayHistoric.sort(function(a, b){
                var keyA = new Date(a.date * 1000),
                    keyB = new Date(b.date * 1000);
                // Compare the 2 dates
                if(keyA < keyB) return -1;
                if(keyA > keyB) return 1;
                return 0;
            });

            var yearFilteredArray = [];
            var lastDate = new Date(arrayHistoric[arrayHistoric.length-1].date * 1000);
            lastDate.setFullYear(lastDate.getFullYear() - 1);
            arrayHistoric.forEach(function (hist) {
                if (new Date(hist.date * 1000) > lastDate){
                    yearFilteredArray.push(hist)
                }
            });

            yearFilteredArray.forEach(function (hist) {
                values.push(hist.value);
                dates.push(monthNames[new Date(hist.date * 1000).getMonth()]);
            });
            /*this.setState({values: values, dates: dates});*/

            const data = (canvas) => {

                const ctx = canvas.getContext("2d");
                var gradientFill = ctx.createLinearGradient(0, 0, 0, 350);
                gradientFill.addColorStop(0, "rgba(68, 87, 243, 0.5)");
                gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.1)");
                return{
                    labels: dates,
                    datasets: [
                        {
                            label: '',
                            fill: true,
                            lineTension: 0.3,
                            backgroundColor: gradientFill,
                            borderColor: '#4457F3',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(68, 87, 243, 1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: '#4457F3',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: values,
                        }
                    ]
                }

            };
            this.setState({dataRend: data})

        }
    }

    componentDidMount(){
        this.props.clearTyped();
        if (this.props.user.portfolioID){
            var db = firebase.firestore();
            const context = this;

            var symbolList = [];

            var portListener = db.collection("coins").where("portfolioID", "==", this.props.user.portfolioID)
                .onSnapshot(function (querySnapshot) {
                    var portList = [];
                    querySnapshot.forEach(function (doc) {
                        if (doc.data().quantity > 0) {
                            symbolList.push(doc.data().symbol);
                        }
                    });

                    cc.priceMulti(symbolList, ['BTC', 'USD'])
                        .then(prices => {

                            var sumaAll = 0;


                            querySnapshot.forEach(function (doc) {
                                if (doc.data().quantity > 0) {
                                    if (prices[doc.data().symbol]){
                                        sumaAll = sumaAll + prices[doc.data().symbol].BTC * doc.data().quantity;
                                    }
                                    else {
                                        sumaAll = sumaAll + doc.data().precioPonderado * doc.data().quantity;
                                    }
                                }
                            });
                            querySnapshot.forEach(function (doc) {
                                if (doc.data().quantity > 0) {
                                    if (prices[doc.data().symbol]) {
                                        portList.push(createData(doc.id, doc.data().name, doc.data().symbol, doc.data().quantity,
                                            doc.data().precioPonderado, sumaAll, prices[doc.data().symbol].BTC, doc.data().exchange,
                                            doc.data().imageUrl, doc.data().precioPonderadoUSD, prices[doc.data().symbol].USD))
                                    }
                                    else {
                                        portList.push(createData(doc.id, doc.data().name, doc.data().symbol, doc.data().quantity,
                                            doc.data().precioPonderado, sumaAll,  doc.data().precioPonderado, doc.data().exchange,
                                            doc.data().imageUrl, doc.data().precioPonderadoUSD,  doc.data().precioPonderadoUSD))
                                    }

                                }
                            });
                            context.setState({
                                data: portList,
                                dataFiltered: portList
                            });


                            // -> { BTC: { USD: 1114.63, EUR: 1055.82 },
                            //      ETH: { USD: 12.74, EUR: 12.06 } }
                        })
                        .catch(console.error);


                    context.setState({portListener: portListener});

                })
        }
        if (this.props.portfolio.historicCpart){

            var arrayHistoric = this.props.portfolio.historicCpart;
            var values = [];
            var dates = [];

            arrayHistoric.sort(function(a, b){
                var keyA = new Date(a.date * 1000),
                    keyB = new Date(b.date * 1000);
                // Compare the 2 dates
                if(keyA < keyB) return -1;
                if(keyA > keyB) return 1;
                return 0;
            });

            var yearFilteredArray = [];
            var lastDate = new Date(arrayHistoric[arrayHistoric.length-1].date * 1000);
            lastDate.setFullYear(lastDate.getFullYear() - 1);
            arrayHistoric.forEach(function (hist) {
                if (new Date(hist.date * 1000) > lastDate){
                    yearFilteredArray.push(hist)
                }
            });


            yearFilteredArray.forEach(function (hist) {
                values.push(hist.value);
                dates.push(monthNames[new Date(hist.date * 1000).getMonth()]);
            });
            const data = (canvas) => {

                const ctx = canvas.getContext("2d");
                var gradientFill = ctx.createLinearGradient(0, 0, 0, 350);
                gradientFill.addColorStop(0, "rgba(68, 87, 243, 0.5)");
                gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.1)");
                return{
                    labels: dates,
                    datasets: [
                        {
                            label: '',
                            fill: true,
                            lineTension: 0.3,
                            backgroundColor: gradientFill,
                            borderColor: '#4457F3',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(68, 87, 243, 1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: '#4457F3',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: values,
                        }
                    ]
                }

            };
            this.setState({dataRend: data})

        }
    }

    render(){


        var labels = [];
        var quans = [];
        var total = new Decimal(0);

        this.state.data.forEach(function (coin) {
            total = total.plus(new Decimal(coin.quantity).times(coin.prAct));
        });

        for (const coin of this.state.data) {
            var quan = new Decimal(coin.quantity).times(coin.prAct);
            labels.push(coin.name);
            quans.push(quan.dividedBy(total).times(100).toNumber().toFixed(2));
        }

        const data2 = (canvas) => {

            return {

                labels: labels,
                datasets: [{
                    data: quans,
                    backgroundColor: [
                        '#4457F3',
                        '#DE3BCB',
                        '#FF4995',
                        '#FF8566',
                        '#FFC351',
                        '#F9F871',
                        '#009F71',
                        '#B200FF',
                        '#008ee1',
                        '#A097CD',
                        '#009CA7',
                    ],
                    hoverBackgroundColor: [
                        '#4457F3',
                        '#DE3BCB',
                        '#FF4995',
                        '#FF8566',
                        '#FFC351',
                        '#F9F871',
                        '#009F71',
                        '#B200FF',
                        '#008ee1',
                        '#A097CD',
                        '#009CA7',
                    ]
                }]
            }

        };

        const { classes } = this.props;
        return(


            <div className={"container"}>
                <p className={"title"}>Portfolio
                    <Fab variant="extended" color="primary" aria-label="Importar" className={classes.fab}>
                        {'Valor BTC: $' + this.state.btcUsd.toFixed(2) }
                    </Fab>
                </p>
                <div className={"graphsContainer"}>
                    <Paper elevation={1} className={"paperRendimiento"}>
                        <div className={'rendimientoCointainer'}>
                            <p className={"title"}>Rendimiento<span className={'cpartValue'}>{this.props.actualCPart.toNumber() ?'₿ ' +  this.props.actualCPart.toDecimalPlaces(4).toNumber() : '₿ ' }</span></p>

                            <Line className={'chart'} data={this.state.dataRend} options={{
                                maintainAspectRatio: false,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    xAxes: [{
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        },

                                        ticks: {
                                            autoSkip: true,
                                            /* maxRotation: window.innerWidth < 960 ? 90 : 0,
                                             minRotation: window.innerWidth < 960 ? 90 : 0,*/
                                            maxRotation: 0,
                                            minRotation: 0,
                                        }
                                    }],
                                    yAxes: [{
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        },
                                        ticks: {
                                            display: false //this will remove only the label
                                        }
                                    }]
                                }
                            }}/>
                        </div>

                    </Paper>
                    <Paper elevation={1} className={"paperChartPortfolio"}>
                        <div className={"rendimientoCointainer"}>
                            <p className={"title"}>Distribucion portfolio</p>
                            <Pie data={data2} options={{
                                maintainAspectRatio: false,
                                legend: {
                                    display: true,
                                    position: 'right',
                                },

                            }} />
                        </div>
                    </Paper>


                </div>


                <PortsTableClient user={this.props.user} data={this.state.dataFiltered} btc={this.props.btc}/>

            </div>
        )
    }
}
PortfolioClient.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(PortfolioClient);