import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ClientsField from "./autocom/ClientsField";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CoinsField from "./autocom/CoinsField";
import BuysTable from "../Pages/trades/BuysTable";
import firebase from '../firebase';
import Decimal from "decimal.js";

function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

function timestampToDate(timestamp) {
    var today = new Date(timestamp*1000);
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

export default class EditLongShort extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            type: 'buy',
            coin: '',
            coinSymbol:'',
            lastSelected: '',
            quantity: '',
            price: '',
            date: '',
            btcUsd:'',
            exchange: 'Bitmex',
            valorCpart: '',
            cost: '',
            lev: '',
            orderValue:'',
            error: false,
            id: '',
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.trade !== this.props.trade){
            this.setState({type: nextProps.trade.type, id: nextProps.trade.id});
            this.handleChangeCoin(nextProps.trade.coin);
            this.handleChangeSymbol(nextProps.trade.symbol);
            this.setLastSelected(nextProps.trade.coin);
            this.handleChange('quantity')({target: {value: nextProps.trade.quan}});
            this.handleChange('price')({target: {value: nextProps.trade.price}});
            this.handleChange('date')({target: {value: timestampToDate(nextProps.trade.date)}});
            this.handleChange('btcUsd')({target: {value: nextProps.trade.btcUsd}});
            this.handleChange('exchange')({target: {value: nextProps.trade.exchange}});
            this.handleChange('cost')({target: {value: nextProps.trade.cost}});
            this.handleChange('lev')({target: {value: nextProps.trade.lev}});
            this.handleChange('orderValue')({target: {value: nextProps.trade.orderVal}});

        }
    }

    componentDidMount() {

    }

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
            error: false,
        });
        console.log(event.target.value);
    };
    handleChangeCoin = (newValue) => {
        this.setState({coin: newValue})
    };

    handleChangeSymbol = (symbol) => {
        this.setState({coinSymbol: symbol});
        console.log(symbol);
    };
    setLastSelected = (name) => {
        console.log(name);
        this.setState({lastSelected: name});
    };

    handleClose = () => {
        this.setState({
            coin: '',
            coinSymbol:'',
            lastSelected: '',
            quantity: '',
            price: '',
            date: getCurrentDate(),
            btcUsd:'',
            exchange: 'Bitmex',
            valorCpart: '',
            cost: '',
            lev: '',
            orderValue:'',
            error: false,
            id: ''
        });
        this.props.handleClose(5);
    };

    onEditTrade = () => {

        if(this.state.id){

            var db = firebase.firestore();
            var context = this;
            var date = new Date(this.state.date);
            date.setHours(date.getHours() + 3);
            var timestamp  = new Decimal(date.getTime()).dividedBy(1000).toNumber();


            db.collection('trades').doc(this.state.id).set({
                type: context.state.type,
                coin: context.state.coin,
                symbol: context.state.coinSymbol,
                quantity: parseFloat(context.state.quantity),
                price: parseFloat(context.state.price),
                date: timestamp,
                btcUsd: parseFloat(context.state.btcUsd),
                exchange: context.state.exchange,
                cost: parseFloat(context.state.cost),
                lev: parseFloat(context.state.lev),
                orderValue: parseFloat(context.state.orderValue),
                state: true,
                portfolioID: context.props.user.portfolioID,
            }).then(function (doc) {
                context.props.actionFeedback(0,'Trade editado!');
                context.handleClose();

            }).catch(function (error) {
                context.props.actionFeedback(1,'Error al editar');
            })


        }

    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Modal open={this.props.open} className={"modalInv"} onClose={this.handleClose}>
                        <Paper className={"modalPaper"} elevation={1}>
                            <p className={'title modalTi'}>Editar Trade</p>


                            <div>
                                <CoinsField error={this.state.error} setLastSelected={this.setLastSelected} handleChangeSymbol={this.handleChangeSymbol} value={this.state.coin} handleChange={this.handleChangeCoin} actionFeedback={this.props.actionFeedback} user={this.props.user}/>
                                <TextField
                                    value={this.state.quantity}
                                    onChange={this.handleChange('quantity')}
                                    error={this.state.error}
                                    label="Cantidad"
                                    type={'number'}
                                    className="fieldModal"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.price}
                                    onChange={this.handleChange('price')}
                                    error={this.state.error}
                                    label="Precio BTC"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.cost}
                                    onChange={this.handleChange('cost')}
                                    error={this.state.error}
                                    label="Cost"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.lev}
                                    onChange={this.handleChange('lev')}
                                    error={this.state.error}
                                    label="Lev"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.orderValue}
                                    onChange={this.handleChange('orderValue')}
                                    error={this.state.error}
                                    label="Order Value"
                                    className="fieldModal"
                                    type={'number'}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.date}
                                    onChange={this.handleChange('date')}
                                    error={this.state.error}
                                    label="Fecha"
                                    className="fieldModal"
                                    type="date"
                                    name="date"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.btcUsd}
                                    onChange={this.handleChange('btcUsd')}
                                    error={this.state.error}
                                    label="USD/BTC"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.exchange}
                                    onChange={this.handleChange('exchange')}
                                    error={this.state.error}
                                    label="Exchange"
                                    className="fieldModal"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                /></div>



                            <Button onClick={this.onEditTrade} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"}>
                                Cargar
                            </Button>
                        </Paper>
                    </Modal>
                </MuiThemeProvider>
            </div>
        )
    }
}