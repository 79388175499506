import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ClientsField from "./autocom/ClientsField";
import CoinsField from "./autocom/CoinsField";
import firebase from '../firebase';
import {Decimal} from "decimal.js";


function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

export default class ExtraCoin extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            coin: '',
            coinSymbol: '',
            quantitiy: '',
            stopLoss : '',
            lastSelected: '',
        };
    }

    componentWillUnmount() {

    }

    componentDidMount() {

    }

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
            error: false
        });
    };
    handleChangeCoin = (newValueCoin) => {
        this.setState({coin: newValueCoin})
    };
    handleChangeSymbol = (symbol) => {
        this.setState({coinSymbol: symbol});
    };
    setLastSelected = (name) => {
        this.setState({lastSelected: name});
    };

    onNewObjective = () => {
        if (this.state.coin && this.state.coinSymbol && this.state.quantity && this.state.lastSelected){
            if (this.state.coin === this.state.lastSelected) {
                var db = firebase.firestore();
                var context = this;
                var coinCol = db.collection('coins').where("portfolioID", "==", this.props.user.portfolioID).where("symbol", "==", this.state.coinSymbol);
                coinCol.get()
                    .then(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {
                            // doc.data() is never undefined for query doc snapshots
                            /*console.log(doc.id, " => ", doc.data());*/
                            var coinRef = db.collection('coins').doc(doc.id);
                            db.runTransaction(function(transaction) {
                                return transaction.get(coinRef).then(function(coinDoc) {
                                    if (!coinDoc.exists) {
                                        throw "Document does not exist!";
                                    }

                                    if (coinDoc.data().precioPonderado != null ){
                                        var quan = new Decimal(coinDoc.data().quantity).plus(parseFloat(context.state.quantity));



                                        transaction.update(coinRef, { quantity: quan.toNumber()});
                                        return quan.toNumber();

                                    }


                                });
                            }).then(function(newPrice) {
                                console.log("Nuevo cantidad: ", newPrice);
                                context.props.actionFeedback(0, 'Cantidad editada');
                                context.handleClose();

                            }).catch(function(err) {
                                // This will be an "population is too big" error.
                                console.error(err);
                                context.props.actionFeedback(1, 'Hubo un error al editar')
                            });
                        });
                    })
                    .catch(function(error) {
                        console.log("Error getting documents: ", error);
                    });
            }
            else{
                this.setState({error: true});
                this.props.actionFeedback(1, 'Moneda mal introdicida')
            }
        }
        else {
            this.setState({error: true});
            this.props.actionFeedback(1, 'Campos incorrectos')
        }

    };

    handleClose = () => {
        this.setState({
            coin: '',
            coinSymbol: '',
            quantity: '',
            lastSelected: '',
            error: false,
        });
        this.props.handleClose(8);
    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Modal open={this.props.open} className={"modalInv"} onClose={this.handleClose}>
                        <Paper className={"modalPaper"} elevation={1}>
                            <p className={'title modalTi'}>Agregar Cantidad</p>
                            <CoinsField error={this.state.error} setLastSelected={this.setLastSelected} handleChangeSymbol={this.handleChangeSymbol} handleChange={this.handleChangeCoin} value={this.state.coin} actionFeedback={this.props.actionFeedback} user={this.props.user}/>

                            <TextField
                                value={this.state.quantity}
                                onChange={this.handleChange('quantity')}
                                error={this.state.error}
                                label="Cantidad"
                                className="fieldModal"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />

                            <Button onClick={this.onNewObjective} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"}>
                                Cargar
                            </Button>
                        </Paper>
                    </Modal>
                </MuiThemeProvider>
            </div>
        )
    }
}