import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import bclogo from "../../images/BC_Logo_.png";
import firebase from "../../firebase";
import {Decimal} from 'decimal.js';
import Button from "@material-ui/core/Button";

const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');

const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

var moment = require('moment');
moment().format();

var id = 0;
function createData(sales, buys, pnls, profit, totalPortfolio, recaud, managementFee) {
    id++;
    let management = new Decimal(totalPortfolio).times(new Decimal(managementFee).dividedBy(100));
    let total = new Decimal(recaud).plus(management);



    return { id, sales, buys, pnls,  profit, totalPortfolio, recaud, managementFee, management, total};
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}




const rows = [
    { id: 'sales', numeric: true, disablePadding: false, label: 'Ventas' },
    { id: 'buys', numeric: true, disablePadding: false, label: 'Compras' },
    { id: 'pnls', numeric: true, disablePadding: false, label: 'PNLS' },
    { id: 'profit', numeric: true, disablePadding: false, label: 'Ganancias' },
    { id: 'totalPortfolio', numeric: true, disablePadding: false, label: 'Total fondo' },
    { id: 'recaud', numeric: true, disablePadding: false, label: 'A recaudar' },
    { id: 'managementFee', numeric: true, disablePadding: false, label: 'Management fee' },
    { id: 'management', numeric: true, disablePadding: false, label: 'Management charge' },
    { id: 'total', numeric: true, disablePadding: false, label: 'Total' },

];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {  order, orderBy, } = this.props;

        return (
            <TableHead className={'tableHead'}>
                <TableRow>
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '94%',
        marginTop: theme.spacing.unit * 3,
        marginLeft: '3%',
        borderRadius: 15,
        marginBottom: theme.spacing.unit * 3,
        paddingBottom: '0.1px',
        [theme.breakpoints.up('md')]: {
            width: '96%',
            marginLeft: '2%',
        },
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});




class ActualLiquiTable extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'calories',
        selected: [],
        listener: '',
        data: [],
        month: '',
        disabled: true,
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.totalPortfolio){
            this.componentDidMount()
        }
    }

    liquidate = () => {
        if (this.props.user.portfolioID){

            var db = firebase.firestore();
            var context = this;

            var sumSales = new Decimal(0);
            var sumBuys = new Decimal(0);
            var sumPnls = new Decimal(0);

            var recaud = new Decimal(0);

            var tradesDone = false;

            var profit = new Decimal(0);
            var data = [];

            var batch = db.batch();

            db.collection('portfolios').doc(this.props.user.portfolioID)
                .get().then(function (portfolio) {

                var date = new Date(portfolio.data().lastLiqui);
                var timestamp = (date.getTime()/1000) - 1;

                var actualCPartVal = context.props.totalPortfolio.dividedBy(portfolio.data().qCPart);


                db.collection('trades').where('date', '>' , timestamp).where('portfolioID', '==', context.props.user.portfolioID)
                    .get().then(function (querySnapshot) {

                    querySnapshot.forEach(function (trade) {

                        if (moment(new Date(trade.data().date * 1000)).isSame(date, 'month')){

                            if (trade.data().type === 'buy' && !trade.data().state){
                                sumBuys = new Decimal(sumBuys).plus(new Decimal(trade.data().price).times(trade.data().quantity))
                            }
                            else if (trade.data().type === 'sell'){
                                sumSales = new Decimal(sumSales).plus(new Decimal(trade.data().price).times(trade.data().quantity))
                            }
                            else if (!trade.data().state){
                                sumPnls = sumPnls.plus(trade.data().pnl)
                            }
                        }
                    });

                    profit = profit.plus(sumSales).minus(sumBuys).plus(sumPnls);

                    if (profit > 0){
                        db.collection('clients').where('portfolioID', '==', context.props.user.portfolioID)
                            .get().then(function (querySnapshot) {
                            querySnapshot.forEach(function (client) {
                                var fee = new Decimal(client.data().fee).dividedBy(100);
                                var part = new Decimal(client.data().qCPart).dividedBy(portfolio.data().qCPart);
                                var recaudClient = profit.times(fee).times(part);
                                recaud = recaud.plus(recaudClient);
                                var newQCPart = new Decimal(client.data().qCPart).minus(recaudClient.dividedBy(actualCPartVal));
                                var clientRef = db.collection("clients").doc(client.id);
                                batch.update(clientRef, {"qCPart": newQCPart.toNumber()});
                            });
                            var portfolioRef = db.collection('portfolios').doc(context.props.user.portfolioID);
                            var newPortQCPart = new Decimal(portfolio.data().qCPart).minus(recaud.dividedBy(actualCPartVal));
                            db.collection('coins').where('symbol','==', 'BTC')
                                .where('portfolioID', '==', context.props.user.portfolioID).get().then(function (query) {
                                query.forEach(function (btc) {
                                    var btcRef = db.collection('coins').doc(btc.id);
                                    var managementCharge = context.props.totalPortfolio.times(new Decimal(portfolio.data().managementFee).dividedBy(100));
                                    var newQ = new Decimal(btc.data().quantity).minus(recaud).minus(context.props.totalPortfolio.times(new Decimal(portfolio.data().managementFee).dividedBy(100)));
                                    date.setMonth(date.getMonth() + 1);
                                    batch.update(btcRef, {'quantity': newQ.toNumber()});
                                    batch.update(portfolioRef, {'qCPart': newPortQCPart.toNumber(), lastLiqui: date.toISOString()});
                                    date.setMonth(date.getMonth() - 1);
                                    batch.commit().then(function () {
                                        db.collection('liquis').add({
                                            buys: sumBuys.toNumber(),
                                            date: date.getTime() / 1000,
                                            managementCharge: managementCharge.toNumber(),
                                            managementFee: portfolio.data().managementFee,
                                            pnls: sumPnls.toNumber(),
                                            portfolioID: context.props.user.portfolioID,
                                            profit: profit.toNumber(),
                                            recaud: recaud.toNumber(),
                                            sales: sumSales.toNumber(),
                                            total : recaud.plus(managementCharge).toNumber(),
                                            totalPortfolio: context.props.totalPortfolio.toNumber()
                                        }).then(function () {
                                            context.props.actionFeedback(0, 'Nueva liquidacion cargada!')
                                        })

                                    })
                                })
                            })
                        })
                    } else {
                        var portfolioRef = db.collection('portfolios').doc(context.props.user.portfolioID);
                        db.collection('coins').where('symbol','==', 'BTC')
                            .where('portfolioID', '==', context.props.user.portfolioID).get().then(function (query) {
                            query.forEach(function (btc) {
                                var btcRef = db.collection('coins').doc(btc.id);
                                var managementCharge = context.props.totalPortfolio.times(new Decimal(portfolio.data().managementFee).dividedBy(100));
                                var newQ = new Decimal(btc.data().quantity).minus(recaud).minus(context.props.totalPortfolio.times(new Decimal(portfolio.data().managementFee).dividedBy(100)));
                                date.setMonth(date.getMonth() + 1);
                                batch.update(btcRef, {'quantity': newQ.toNumber()});
                                batch.update(portfolioRef, {lastLiqui: date.toISOString()});
                                date.setMonth(date.getMonth() - 1);
                                batch.commit().then(function () {
                                    db.collection('liquis').add({
                                        buys: sumBuys.toNumber(),
                                        date: date.getTime() / 1000,
                                        managementCharge: managementCharge.toNumber(),
                                        managementFee: portfolio.data().managementFee,
                                        pnls: sumPnls.toNumber(),
                                        portfolioID: context.props.user.portfolioID,
                                        profit: profit.toNumber(),
                                        recaud: recaud.toNumber(),
                                        sales: sumSales.toNumber(),
                                        total : recaud.plus(managementCharge).toNumber(),
                                        totalPortfolio: context.props.totalPortfolio.toNumber()
                                    }).then(function () {
                                        context.props.actionFeedback(0, 'Nueva liquidacion cargada!')
                                    })

                                })
                            })
                        })

                    }
                });

            })


        }
    };

    componentDidMount() {
        if (this.props.user.portfolioID){

            var db = firebase.firestore();
            var context = this;

            var sumSales = new Decimal(0);
            var sumBuys = new Decimal(0);
            var sumPnls = new Decimal(0);

            var recaud = new Decimal(0);

            var tradesDone = false;

            var profit = new Decimal(0);
            var data = [];

            var listener = db.collection('portfolios').doc(this.props.user.portfolioID)
                .onSnapshot(function (portfolio) {

                var date = new Date(portfolio.data().lastLiqui);
                var timestamp = (date.getTime()/1000) - 1;

                if (!moment(date).isSame(new Date(), 'month')){
                    context.setState({disabled: false})
                }else {
                    context.setState({disabled: true})
                }

                db.collection('trades').where('date', '>' , timestamp).where('portfolioID', '==', context.props.user.portfolioID)
                    .get().then(function (querySnapshot) {

                        querySnapshot.forEach(function (trade) {

                            if (moment(new Date(trade.data().date * 1000)).isSame(date, 'month')){

                                if (trade.data().type === 'buy' && !trade.data().state){
                                    sumBuys = new Decimal(sumBuys).plus(new Decimal(trade.data().price).times(trade.data().quantity))
                                }
                                else if (trade.data().type === 'sell'){
                                    sumSales = new Decimal(sumSales).plus(new Decimal(trade.data().price).times(trade.data().quantity))
                                }
                                else if (!trade.data().state){
                                    sumPnls = sumPnls.plus(trade.data().pnl)
                                }
                            }
                        });

                        profit = profit.plus(sumSales).minus(sumBuys).plus(sumPnls);

                        if (profit > 0){
                            db.collection('clients').where('portfolioID', '==', context.props.user.portfolioID)
                                .get().then(function (querySnapshot) {
                                querySnapshot.forEach(function (client) {
                                    var fee = new Decimal(client.data().fee).dividedBy(100);
                                    var part = new Decimal(client.data().qCPart).dividedBy(portfolio.data().qCPart);
                                    var recaudClient = profit.times(fee).times(part);
                                    recaud = recaud.plus(recaudClient);
                                });
                                data.push(createData(sumSales,sumBuys, sumPnls, profit, context.props.totalPortfolio.toNumber(), recaud,
                                    portfolio.data().managementFee));
                                context.setState({data: data, month: monthNames[date.getMonth()]})

                            })
                        }
                        else {
                            data.push(createData(sumSales,sumBuys, sumPnls, profit, context.props.totalPortfolio.toNumber(), recaud,
                                portfolio.data().managementFee));
                            context.setState({data: data, month: monthNames[date.getMonth()]})
                        }

                    });

            });

            this.setState({listener: listener})


        }
    }
    componentWillUnmount() {
        if (this.state.listener){
            this.state.listener();
        }
    }


    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;
        const { data, order, orderBy, selected, } = this.state;


        return (
            <Paper className={classes.root}>
                <p className={"title actualLiquiTitle"}>{this.state.month}</p>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={data.length}
                        />
                        <TableBody className={'tableBody'}>
                            {stableSort(data, getSorting(order, orderBy))
                                .map(n => {
                                    const isSelected = this.isSelected(n.id);
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >


                                            <TableCell numeric>{'₿ ' + n.sales.toFixed(8)}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.buys.toFixed(8)}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.pnls.toFixed(8)}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.profit.toFixed(8)}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.totalPortfolio.toFixed(8)}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.recaud.toFixed(8)}</TableCell>
                                            <TableCell numeric>{n.managementFee.toFixed(2) + '%'}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.management.toFixed(8)}</TableCell>
                                            <TableCell numeric>{'₿ ' + n.total.toFixed(8)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <Button onClick={this.liquidate} disabled={this.state.disabled} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton actualLiquiButton"}>
                    Cargar
                </Button>
            </Paper>
        );
    }
}

ActualLiquiTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActualLiquiTable);