import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, MainButton, ChildButton } from 'react-mfb'
import 'react-mfb/mfb.css'
import Alarm from './images/alarm.svg'
import './ionicons.min.css'


export default class FabComp extends React.Component {

    static propTypes = {
        user: PropTypes.object,
        userData: PropTypes.object,
        handleChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

    render(){

        var panel = document.getElementById('panel'),
            showcode = document.getElementById('showcode'),
            selectFx = document.getElementById('selections-fx'),
            selectPos = document.getElementById('selections-pos'),
            selectMethod = document.getElementById('selections-method');
        var effect = 'zoomin',
            pos = 'br',
            method = window.innerWidth > 959 ?  'hover': 'click';


        return(
            <div>
                <Menu effect={effect} method={method} position={pos}>
                    <MainButton iconResting="ion-md-add" iconActive="ion-md-close" >
                        <img src={Alarm}/>
                    </MainButton>
                    <ChildButton
                        onClick={() => {this.props.handleOpen(0) }}
                        icon="ion-md-cash"
                        label="Nueva inversion"/>
                    <ChildButton
                        onClick={() => {this.props.handleOpen(1) }}
                        icon="ion-md-body"
                        label="Nuevo cliente"/>
                    <ChildButton
                        onClick={() => {this.props.handleOpen(2) }}
                        icon="ion-md-git-compare"
                        label="Nuevo trade"/>
                    <ChildButton
                        onClick={() => {this.props.handleOpen(3) }}
                        icon="ion-md-rocket"
                        label="Nuevo objetivo"/>
                   <ChildButton
                        disabled = {!this.props.user.super}
                        onClick={() => {this.props.handleOpen(8) }}
                        icon="ion-logo-bitcoin"
                        label="Agregar Moneda"/>

                </Menu>
            </div>
        )
    }
}