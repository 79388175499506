import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import firebase from '../firebase';


function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

export default class ChangePassword extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            newPass: '',
            repeatPass: '',
            error: false,
        };
    }

    componentWillUnmount() {

    }

    componentDidMount() {

    }

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
            error: false
        });
    };

    onNewPass = () => {
        var context = this;
        if (this.state.newPass && this.state.repeatPass && this.state.oldPass){
            if (this.state.newPass === this.state.repeatPass){
                var user = firebase.auth().currentUser;
                const credential = firebase.auth.EmailAuthProvider.credential(
                    user.email,
                    this.state.oldPass
                );
                user.reauthenticateAndRetrieveDataWithCredential(credential).then(function() {
                    user.updatePassword(context.state.newPass).then(function() {
                        context.props.actionFeedback(0, 'Contraseña actualizada');
                        context.handleClose();
                    }).catch(function(error) {
                        context.setState({error: true});
                        context.props.actionFeedback(1, 'Error')
                    });
                }).catch(function(error) {
                    context.setState({error: true});
                    context.props.actionFeedback(1, 'Imposible reautenticar')
                });
            } else {
                this.setState({error: true});
                this.props.actionFeedback(1, 'Contraseñas no coinciden')
            }
        }
        else {
            this.setState({error: true});
            this.props.actionFeedback(1, 'Campos incorrectos')
        }

    };

    handleClose = () => {
        this.setState({
            oldPass: '',
            newPass: '',
            repeatPass: '',
            error: false,
        });
        this.props.handleClose(6);
        setTimeout(function () {
            document.body.style.overflow = 'auto';
        }, 10);

    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Modal open={this.props.open} className={"modalInv"} onClose={this.handleClose}>
                        <Paper className={"modalPaper"} elevation={1}>
                            <p className={'title modalTi'}>Cambiar contraseña</p>

                            <TextField
                                value={this.state.oldPass}
                                onChange={this.handleChange('oldPass')}
                                error={this.state.error}
                                label="Vieja contraseña"
                                className="fieldModal"
                                type="password"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.newPass}
                                onChange={this.handleChange('newPass')}
                                error={this.state.error}
                                label="Nueva contraseña"
                                className="fieldModal"
                                type="password"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.repeatPass}
                                onChange={this.handleChange('repeatPass')}
                                error={this.state.error}
                                label="Repetir contraseña"
                                className="fieldModal"
                                type="password"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />

                            <Button onClick={this.onNewPass} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"}>
                                Cargar
                            </Button>
                        </Paper>
                    </Modal>
                </MuiThemeProvider>
            </div>
        )
    }
}