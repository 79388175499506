import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import firebase from '../firebase';
import {Decimal} from 'decimal.js';


export default class DeleteTrade extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    handleClose = () => {
        this.setState({

        });
        this.props.handleClose(2);
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.trade !== this.props.trade){
            console.log(nextProps.trade)
        }
    }


    onDeleteTrade = () => {
        var trade = this.props.trade;
        const context = this;
        if (this.props.trade.type === 'buy'){
            var db = firebase.firestore();

            var updateBtc = false;
            var updateCoin = false;


            db.collection('trades').doc(this.props.trade.id).delete().then(function () {
                context.props.actionFeedback(0,'Trade borrado!');
            });



            var coinCol = db.collection('coins').where("portfolioID", "==", this.props.user.portfolioID).where("symbol", "==", this.props.trade.symbol);
            coinCol.get()
                .then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                        // doc.data() is never undefined for query doc snapshots
                        /*console.log(doc.id, " => ", doc.data());*/
                        var coinRef = db.collection('coins').doc(doc.id);
                        db.runTransaction(function(transaction) {

                            return transaction.get(coinRef).then(function (coinDoc) {
                                if (!coinDoc.exists) {
                                    throw "Document does not exist!";
                                }

                                if (coinDoc.data().precioPonderado != null) {

                                    var oldQ = new Decimal(coinDoc.data().quantity).minus(parseFloat(trade.quantity));
                                    var actualP = new Decimal(coinDoc.data().precioPonderado);
                                    var actualQ = new Decimal(coinDoc.data().quantity);
                                    var q = new Decimal(trade.quantity);
                                    var p = new Decimal(trade.buyPrBtc);

                                    var oldPrice = ((actualP.times(actualQ)).minus(q.times(p))).dividedBy(oldQ);


                                    var actualPUsd = new Decimal(coinDoc.data().precioPonderadoUSD);
                                    var pUsd = new Decimal(trade.buyPrUsd);



                                    var oldPriceUsd = ((actualPUsd.times(actualQ)).minus(q.times(pUsd))).dividedBy(oldQ);
                                    console.log(oldQ.toNumber(), actualP.toNumber(), actualQ.toNumber(), p.toNumber(), q.toNumber(), oldPrice.toNumber(), actualPUsd.toNumber()
                                    , pUsd.toNumber(), oldPriceUsd);


                                    if (oldQ.toNumber() === 0){
                                        transaction.update(coinRef, {
                                            precioPonderado: 0,
                                            quantity: oldQ.toNumber(),
                                            precioPonderadoUSD: 0,
                                        });
                                        return oldPrice;
                                    } else {
                                        transaction.update(coinRef, {
                                            precioPonderado: oldPrice.toNumber(),
                                            quantity: oldQ.toNumber(),
                                            precioPonderadoUSD: oldPriceUsd.toNumber(),
                                        });
                                        return oldPrice;
                                    }


                                }


                            });
                        }).then(function(newPrice) {
                            console.log("Nuevo precio ponderado: ", newPrice);
                            updateCoin = true;
                            if (updateBtc){
                                context.handleClose();
                            }
                        }).catch(function(err) {
                            // This will be an "population is too big" error.
                            console.error(err);
                        });
                    });
                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
                });

            if (context.state.coinSymbol === 'BTC'){
                updateBtc = true;
            } else {
                var coinCol = db.collection('coins').where("portfolioID", "==", this.props.user.portfolioID).where("symbol", "==", 'BTC');
                coinCol.get()
                    .then(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {
                            // doc.data() is never undefined for query doc snapshots
                            /*console.log(doc.id, " => ", doc.data());*/
                            var coinRef = db.collection('coins').doc(doc.id);
                            db.runTransaction(function(transaction) {
                                return transaction.get(coinRef).then(function(coinDoc) {
                                    if (!coinDoc.exists) {
                                        throw "Document does not exist!";
                                    }

                                    var newQuan = new Decimal(coinDoc.data().quantity).plus(new Decimal(trade.quantity).times(trade.buyPrBtc));

                                    transaction.update(coinRef, { quantity: newQuan.toNumber() });
                                    return newQuan;


                                });
                            }).then(function(newPrice) {
                                updateBtc = true;
                                console.log('BTC actualizado a: ', newPrice);
                                if (updateCoin){
                                    context.handleClose();
                                }
                            }).catch(function(err) {
                                // This will be an "population is too big" error.
                                console.error(err);
                            });
                        });
                    })
                    .catch(function(error) {
                        console.log("Error getting documents: ", error);
                    });
            }


        }

        else if (trade.type === 'sell'){
            var db = firebase.firestore();
            const context = this;
            var updateBtc = false;
            var updateCoin = false;

            db.collection('trades').doc(this.props.trade.id).delete().then(function () {
                context.props.actionFeedback(0,'Trade borrado!');
            });

            var coinCol = db.collection('coins').where("portfolioID", "==", this.props.user.portfolioID).where("symbol", "==", trade.symbol);
            coinCol.get()
                .then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {


                        var coinRef = db.collection('coins').doc(doc.id);
                        db.runTransaction(function(transaction) {
                            return transaction.get(coinRef).then(function(coinDoc) {

                                var quan = new Decimal (coinDoc.data().quantity).plus(trade.quantity);
                                transaction.update(coinRef, { quantity: quan.toNumber()});
                                return quan;
                            });
                        }).then(function(quan) {
                            console.log("Nueva cantidad de moneda: " , quan);
                            updateCoin = true;
                            if (updateBtc){
                                context.handleClose();
                            }
                        }).catch(function(err) {
                            // This will be an "population is too big" error.
                            console.error(err);
                        });
                    });
                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
                });

            if (context.state.coinSymbol === 'BTC'){
                updateBtc = true;
            } else {
                var coinCol = db.collection('coins').where("portfolioID", "==", this.props.user.portfolioID).where("symbol", "==", 'BTC');
                coinCol.get()
                    .then(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {
                            // doc.data() is never undefined for query doc snapshots
                            /*console.log(doc.id, " => ", doc.data());*/
                            var coinRef = db.collection('coins').doc(doc.id);
                            db.runTransaction(function(transaction) {
                                return transaction.get(coinRef).then(function(coinDoc) {

                                    var actualQ = new Decimal(coinDoc.data().quantity);
                                    var q = new Decimal(trade.quantity) ;
                                    var p = new Decimal(trade.buyPrBtc);

                                    var actualPUsd = new Decimal(coinDoc.data().precioPonderadoUSD);
                                    var pUsd = new Decimal(trade.btcUsd);


                                    var qBtc = q.times(p);
                                    var oldQBtc = actualQ.minus(qBtc);
                                    var oldPriceUsd = ((actualPUsd.times(actualQ)).minus(qBtc.times(pUsd))).dividedBy(oldQBtc);


                                    if (oldQBtc.toNumber() === 0){
                                        transaction.update(coinRef, { precioPonderadoUSD: 0, quantity: oldQBtc.toNumber() });
                                        return oldPriceUsd;
                                    } else {
                                        transaction.update(coinRef, { precioPonderadoUSD: oldPriceUsd.toNumber(), quantity: oldQBtc.toNumber() });
                                        return oldPriceUsd;
                                    }


                                });
                            }).then(function(newPrice) {
                                updateBtc = true;
                                if (updateCoin){
                                    context.handleClose();
                                }
                            }).catch(function(err) {
                                // This will be an "population is too big" error.
                                console.error(err);
                            });
                        });
                    })
                    .catch(function(error) {
                        console.log("Error getting documents: ", error);
                    });
            }


        }
        if (trade.type === 'long' || trade.type === 'short') {
            if (trade.state){
                var db = firebase.firestore();
                db.collection('trades').doc(trade.id).delete().then(function () {
                    context.props.actionFeedback(0,'Trade borrado!');
                    context.handleClose();
                });
            } else{
                var db = firebase.firestore();
                db.collection('trades').doc(trade.id).delete().then(function () {
                    context.props.actionFeedback(0,'Trade borrado!');
                });
                db.collection('coins').where('portfolioID', '==', this.props.user.portfolioID)
                    .where("symbol", "==", "BTC").get()
                    .then(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {
                            var coinRef = db.collection('coins').doc(doc.id);
                            db.runTransaction(function(transaction) {
                                return transaction.get(coinRef).then(function(coinDoc) {

                                    var quan = coinDoc.data().quantity - parseFloat(trade.pnl);
                                    transaction.update(coinRef, { quantity: quan });
                                    return quan;
                                });
                            }).then(function(quan) {
                                console.log("Nueva cantidad de btc: " , quan);
                                context.handleClose();

                            }).catch(function(err) {
                                // This will be an "population is too big" error.
                                console.error(err);
                            });
                        });
                    })
                    .catch(function(error) {
                        console.log("Error getting documents: ", error);
                    });
            }
        }
    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Modal open={this.props.open} className={"modalInv"} onClose={this.handleClose}>
                        <Paper className={"modalPaper"} elevation={1}>
                            <p className={'title modalTi'}>¿Seguro que desea elimar el trade?</p>



                            <Button onClick={this.onDeleteTrade} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"}>
                                Cargar
                            </Button>
                        </Paper>
                    </Modal>
                </MuiThemeProvider>
            </div>
        )
    }
}