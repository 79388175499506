import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ClientsField from "./autocom/ClientsField";
import firebase from '../firebase';
import {Decimal} from 'decimal.js';
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";

const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');

function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

export default class NewInvestment extends React.Component {

    static propTypes = {
        openInvestment: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            type: 'in',
            client: '',
            clientID: '',
            date: getCurrentDate(),
            montoUss: '',
            comisionBroker: '',
            montoNetUs : '',
            montoBtc: '',
            btcUsd: '',
            qCPart: '',
            actualCpartValue: '',
            error: false,
        };
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.user.portfolioID !== this.props.user.portfolioID){
            var db = firebase.firestore();
            const context = this;
            var totalPortfolio = new Decimal(0);
            var symbolList = [];

            db.collection('coins')
                .where('portfolioID', '==', nextProps.user.portfolioID)
                .get().then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {

                    symbolList.push(doc.data().symbol)
                });

                db.collection('portfolios').doc(nextProps.user.portfolioID).get().then(function (docu) {

                    if (symbolList.length>0){

                        cc.priceMulti(symbolList, ['BTC'])
                            .then(prices => {
                                querySnapshot.forEach(function (doc) {
                                    if (prices[doc.data().symbol]){
                                        totalPortfolio = totalPortfolio.plus(new Decimal(prices[doc.data().symbol].BTC).times(doc.data().quantity));
                                    } else {
                                        totalPortfolio = totalPortfolio.plus(new Decimal(doc.data().precioPonderado).times(doc.data().quantity));
                                    }
                                });
                                context.setState({actualCpartValue: totalPortfolio.dividedBy(docu.data().qCPart).toNumber()});

                            })
                            .catch(console.error);
                    } else {
                        context.setState({actualCpartValue: totalPortfolio.dividedBy(docu.data().qCPart).toNumber()});
                    }

                })
            });


        }
    }

    componentDidMount() {

        if (this.props.user.portfolioID){
            var db = firebase.firestore();
            const context = this;
            var totalPortfolio = new Decimal(0);
            var symbolList = [];




            db.collection('coins')
                .where('portfolioID', '==', this.props.user.portfolioID)
                .get().then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {

                    symbolList.push(doc.data().symbol)
                });
                db.collection('portfolios').doc(context.props.user.portfolioID).get().then(function (docu) {

                    cc.priceMulti(symbolList, ['BTC'])
                        .then(prices => {
                            querySnapshot.forEach(function (doc) {
                                totalPortfolio = totalPortfolio.plus(new Decimal(prices[doc.data().symbol].BTC).times(doc.data().quantity));
                            });

                            context.setState({actualCpartValue: totalPortfolio.dividedBy(docu.data().qCPart).toNumber()});

                        })
                        .catch(console.error);
                })
            });


        }

    }

    addInvestments = () =>{
        var db = firebase.firestore();
        var context = this;

        var arrInves = [{name:"Alan",lastName:"Erdei",type:"in", date:"09/04/17",montoUsd:100,comisionBroker:0.05,montoNetUs:95.00,montoBtc:0.02183901,btcUsd:4350.02,qCpart:0.4821,actualCpartValue:0.04530176},
            {name:"Diego",lastName:"Cazap",type:"in", date:"09/04/17",montoUsd:200,comisionBroker:0.014683333,montoNetUs:197.06,montoBtc:0.04530176,btcUsd:4350.02,qCpart:1.0000,actualCpartValue:0.04530176},
            {name:"Julian",lastName:"Hilale",type:"in", date:"09/04/17",montoUsd:200,comisionBroker:0.05,montoNetUs:190.00,montoBtc:0.04367801,btcUsd:4350.02,qCpart:0.9642,actualCpartValue:0.04530176},
            {name:"Lucas",lastName:"Maceiras",type:"in", date:"09/04/17",montoUsd:1475,comisionBroker:0.05,montoNetUs:1401.25,montoBtc:0.32212533,btcUsd:4350.02,qCpart:7.1107,actualCpartValue:0.04530176},
            {name:"Matias",lastName:"Cohen",type:"in", date:"09/04/17",montoUsd:200,comisionBroker:0.014683333,montoNetUs:197.06,montoBtc:0.04530176,btcUsd:4350.02,qCpart:1.0000,actualCpartValue:0.04530176},
            {name:"Maximo",lastName:"Boskis",type:"in", date:"09/14/17",montoUsd:300,comisionBroker:0.05,montoNetUs:285.00,montoBtc:0.06551702,btcUsd:4350.02,qCpart:1.4462,actualCpartValue:0.04530176},
            {name:"Alejandro",lastName:"Safdie",type:"in", date:"10/04/17",montoUsd:617,comisionBroker:0.05,montoNetUs:586.15,montoBtc:0.13690727,btcUsd:4281.37,qCpart:3.0221,actualCpartValue:0.04530176},
            {name:"Eric",lastName:"Catz",type:"in", date:"10/09/17",montoUsd:396,comisionBroker:0.05,montoNetUs:376.20,montoBtc:0.07967841,btcUsd:4721.48,qCpart:1.9202,actualCpartValue:0.04149411},
            {name:"Alan",lastName:"Erdei",type:"in", date:"11/14/17",montoUsd:94,comisionBroker:0.07,montoNetUs:87.42,montoBtc:0.01321869,btcUsd:6613.37,qCpart:0.3309,actualCpartValue:0.03995000},
            {name:"Alejandro",lastName:"Safdie",type:"in", date:"11/14/17",montoUsd:556,comisionBroker:0.07,montoNetUs:517.08,montoBtc:0.07818713,btcUsd:6613.37,qCpart:1.9571,actualCpartValue:0.03995000},
            {name:"Brian",lastName:"Hamui",type:"in", date:"11/14/17",montoUsd:278,comisionBroker:0.07,montoNetUs:258.54,montoBtc:0.03909356,btcUsd:6613.37,qCpart:0.9786,actualCpartValue:0.03995000},
            {name:"Eric",lastName:"Catz",type:"in", date:"11/14/17",montoUsd:444,comisionBroker:0.07,montoNetUs:412.92,montoBtc:0.06243720,btcUsd:6613.37,qCpart:1.5629,actualCpartValue:0.03995000},
            {name:"Federico",lastName:"Estrin",type:"in", date:"11/14/17",montoUsd:1000,comisionBroker:0.07,montoNetUs:930.00,montoBtc:0.14062433,btcUsd:6613.37,qCpart:3.5200,actualCpartValue:0.03995000},
            {name:"Julian",lastName:"Hilale",type:"in", date:"11/14/17",montoUsd:200,comisionBroker:0.07,montoNetUs:186.00,montoBtc:0.02812487,btcUsd:6613.37,qCpart:0.7040,actualCpartValue:0.03995000},
            {name:"Lucas",lastName:"Maceiras",type:"in", date:"11/14/17",montoUsd:1111,comisionBroker:0.07,montoNetUs:1033.23,montoBtc:0.15623363,btcUsd:6613.37,qCpart:3.9107,actualCpartValue:0.03995000},
            {name:"Damian",lastName:"Senderosky",type:"in", date:"11/30/17",montoUsd:500,comisionBroker:0.07,montoNetUs:465.00,montoBtc:0.05232833,btcUsd:8886.20,qCpart:1.1088,actualCpartValue:0.04719398},
            {name:"Kevin",lastName:"Rubins",type:"in", date:"11/30/17",montoUsd:500,comisionBroker:0.07,montoNetUs:465.00,montoBtc:0.05232833,btcUsd:8886.20,qCpart:1.1088,actualCpartValue:0.04719398},
            {name:"Martin",lastName:"Chueke",type:"in", date:"11/30/17",montoUsd:1500,comisionBroker:0.07,montoNetUs:1395.00,montoBtc:0.15698500,btcUsd:8886.20,qCpart:3.3264,actualCpartValue:0.04719398},
            {name:"Maximiliano",lastName:"Oliver",type:"in", date:"11/30/17",montoUsd:500,comisionBroker:0.07,montoNetUs:465.00,montoBtc:0.05232833,btcUsd:8886.20,qCpart:1.1088,actualCpartValue:0.04719398},
            {name:"Alejandro",lastName:"Safdie",type:"in", date:"12/15/17",montoUsd:3500,comisionBroker:0.08,montoNetUs:3220.00,montoBtc:0.18529495,btcUsd:17377.70,qCpart:4.503460978,actualCpartValue:0.04114501},
            {name:"Ariel",lastName:"Chaufan",type:"in", date:"12/15/17",montoUsd:9800,comisionBroker:0.08,montoNetUs:9016.00,montoBtc:0.51882585,btcUsd:17377.70,qCpart:12.60969074,actualCpartValue:0.04114501},
            {name:"Ariel",lastName:"Safdie",type:"in", date:"12/15/17",montoUsd:1000,comisionBroker:0.08,montoNetUs:920.00,montoBtc:0.05294141,btcUsd:17377.70,qCpart:1.286703137,actualCpartValue:0.04114501},
            {name:"Brian",lastName:"Pochne",type:"in", date:"12/15/17",montoUsd:15000,comisionBroker:0.08,montoNetUs:13800.00,montoBtc:0.79412120,btcUsd:17377.70,qCpart:19.30054705,actualCpartValue:0.04114501},
            {name:"Bruno",lastName:"Daniele",type:"in", date:"12/15/17",montoUsd:3000,comisionBroker:0.08,montoNetUs:2760.00,montoBtc:0.15882424,btcUsd:17377.70,qCpart:3.86010941,actualCpartValue:0.04114501},
            {name:"Ezequiel",lastName:"Droblas",type:"in", date:"12/15/17",montoUsd:2300,comisionBroker:0.08,montoNetUs:2116.00,montoBtc:0.12176525,btcUsd:17377.70,qCpart:2.959417214,actualCpartValue:0.04114501},
            {name:"Ezequiel",lastName:"Cazap",type:"in", date:"12/15/17",montoUsd:5000,comisionBroker:0.08,montoNetUs:4600.00,montoBtc:0.26470707,btcUsd:17377.70,qCpart:6.433515683,actualCpartValue:0.04114501},
            {name:"Jimmy",lastName:"Perez",type:"in", date:"12/15/17",montoUsd:10000,comisionBroker:0.08,montoNetUs:9200.00,montoBtc:0.52941413,btcUsd:17377.70,qCpart:12.86703137,actualCpartValue:0.04114501},
            {name:"Juan Segundo",lastName:"Guareschi",type:"in", date:"12/15/17",montoUsd:300,comisionBroker:0.08,montoNetUs:276.00,montoBtc:0.01588242,btcUsd:17377.70,qCpart:0.386010941,actualCpartValue:0.04114501},
            {name:"Mariano",lastName:"Biocca",type:"in", date:"12/15/17",montoUsd:1000,comisionBroker:0.08,montoNetUs:920.00,montoBtc:0.05294141,btcUsd:17377.70,qCpart:1.286703137,actualCpartValue:0.04114501},
            {name:"Matias",lastName:"Perez",type:"in", date:"12/15/17",montoUsd:10000,comisionBroker:0.08,montoNetUs:9200.00,montoBtc:0.52941413,btcUsd:17377.70,qCpart:12.86703137,actualCpartValue:0.04114501},
            {name:"Nicolas",lastName:"Scolnic",type:"in", date:"12/15/17",montoUsd:3000,comisionBroker:0.08,montoNetUs:2760.00,montoBtc:0.15882424,btcUsd:17377.70,qCpart:3.86010941,actualCpartValue:0.04114501},
            {name:"Sebastian",lastName:"Spiguel",type:"in", date:"12/15/17",montoUsd:1000,comisionBroker:0.08,montoNetUs:920.00,montoBtc:0.05294141,btcUsd:17377.70,qCpart:1.286703137,actualCpartValue:0.04114501},
            {name:"Brian",lastName:"Hamui",type:"out", date:"12/20/17",montoUsd:412,comisionBroker:0.03,montoNetUs:399.64,montoBtc:0.02350824,btcUsd:17000.00,qCpart:0.535704024,actualCpartValue:0.04388288},
            {name:"Lucas",lastName:"Maceiras",type:"out", date:"12/20/17",montoUsd:4223,comisionBroker:0.03,montoNetUs:4096.31,montoBtc:0.40639972,btcUsd:10079.51,qCpart:9.261008344,actualCpartValue:0.04388288},
            {name:"Adam",lastName:"Kirschbaum",type:"in", date:"01/18/18",montoUsd:7000,comisionBroker:0.06,montoNetUs:6580.00,montoBtc:0.57093771,btcUsd:11524.90,qCpart:11.891031008,actualCpartValue:0.04801415},
            {name:"Ariel",lastName:"Safdie",type:"in", date:"01/18/18",montoUsd:7400,comisionBroker:0.06,montoNetUs:6956.00,montoBtc:0.60356272,btcUsd:11524.90,qCpart:12.570518494,actualCpartValue:0.04801415},
            {name:"Juan Segundo",lastName:"Guareschi",type:"in", date:"01/18/18",montoUsd:400,comisionBroker:0.06,montoNetUs:376.00,montoBtc:0.03262501,btcUsd:11524.90,qCpart:0.679487486,actualCpartValue:0.04801415},
            {name:"Alan",lastName:"Erdei",type:"in", date:"01/25/18",montoUsd:300,comisionBroker:0.06,montoNetUs:282.00,montoBtc:0.02469017,btcUsd:11421.55,qCpart:0.449727397,actualCpartValue:0.05490030},
            {name:"Diego",lastName:"Cazap",type:"in", date:"01/25/18",montoUsd:700,comisionBroker:0.06,montoNetUs:658.00,montoBtc:0.05761039,btcUsd:11421.55,qCpart:1.049363927,actualCpartValue:0.05490030},
            {name:"Ezequiel",lastName:"Cazap",type:"in", date:"01/25/18",montoUsd:2500,comisionBroker:0.06,montoNetUs:2350.00,montoBtc:0.20575141,btcUsd:11421.55,qCpart:3.74772831,actualCpartValue:0.05490030},
            {name:"Maximo",lastName:"Boskis",type:"in", date:"01/25/18",montoUsd:500,comisionBroker:0.06,montoNetUs:470.00,montoBtc:0.04115028,btcUsd:11421.55,qCpart:0.749545662,actualCpartValue:0.05490030},
            {name:"Agustin",lastName:"Trajtenberg",type:"in", date:"02/26/18",montoUsd:10374,comisionBroker:0,montoNetUs:10374.00,montoBtc:1.00000000,btcUsd:10374.00,qCpart:21.19898041,actualCpartValue:0.04717208},
            {name:"Hernan",lastName:"Fartuzek",type:"in", date:"03/19/18",montoUsd:1000,comisionBroker:0.05,montoNetUs:950.00,montoBtc:0.11270433,btcUsd:8429.14,qCpart:2.932601,actualCpartValue:0.03843152},
            {name:"Luis Alejandro",lastName:"Paravati",type:"in", date:"03/22/18",montoUsd:4651,comisionBroker:0.0759,montoNetUs:4297.96,montoBtc:0.51515021,btcUsd:8343.12,qCpart:12.623614,actualCpartValue:0.04080846},
            {name:"Sebastian",lastName:"Bordon",type:"in", date:"05/12/18",montoUsd:2000,comisionBroker:0.03,montoNetUs:1940.00,montoBtc:0.22974404,btcUsd:8444.18,qCpart:4.198225,actualCpartValue:0.05472408},
            {name:"Roberto",lastName:"Ritter",type:"in", date:"06/06/18",montoUsd:3000,comisionBroker:0.05,montoNetUs:2857.14,montoBtc:0.37638557,btcUsd:7591.00,qCpart:7.371560,actualCpartValue:0.05105915},
            {name:"Damian",lastName:"Bertuetti",type:"in", date:"06/20/18",montoUsd:5000,comisionBroker:0.05,montoNetUs:4750.00,montoBtc:0.69681606,btcUsd:6816.72,qCpart:14.711046,actualCpartValue:0.04736686},
            {name:"Jonny",lastName:"Keller",type:"in", date:"10/24/18",montoUsd:6495,comisionBroker:0,montoNetUs:6495.00,montoBtc:1.00000000,btcUsd:6495.00,qCpart:26.299806,actualCpartValue:0.03802309}];




        arrInves.forEach(function (inv) {

            
            db.collection('clients').where('name', '==', inv.name).where('lastName','==',inv.lastName).get().then(function (snap) {
                if (snap.size > 0){
                    snap.forEach(function (doc) {
                        var date = new Date(inv.date);
                        date.setHours(date.getHours()+4);
                        var newInves = {
                            clientID: doc.id,
                            portfolioID: context.props.user.portfolioID,
                            date: date.getTime()/1000,
                            montoUss: inv.montoUsd,
                            comisionBroker: inv.comisionBroker * 100,
                            montoNetUs : inv.montoNetUs,
                            montoBtc: inv.montoBtc,
                            btcUsd: inv.btcUsd,
                            qCPart: inv.qCpart,
                            actualCpartValue: inv.actualCpartValue,
                            type: inv.type,
                        };

                        db.collection('investments').add(newInves).then(function () {
                            console.log(newInves)
                        })
                        
                    })
                } else {
                    console.log('No existe: ' + inv.name + ' ' + inv.lastName )
                }

            })
        });





    };

    searchIfExists = (arr, name, lastName) => {

        for(var i = 0; i < arr.length; i++) {
            if (arr[i].name === name && arr[i].lastName === lastName) {

                return true;
            }
        }
        return false;
    };
    getPosition = (arr, name, lastName) => {
        for(var i = 0; i < arr.length; i++) {
            if (arr[i].name === name && arr[i].lastName === lastName) {

                return i;
            }
        }
    };

    onNewInvestment = () => {

        if (this.state.type === 'in'){
            if (this.state.client && this.state.clientID && this.state.montoNetUs && this.state.montoBtc
                && this.state.btcUsd && this.state.qCPart){

                var db = firebase.firestore();
                const context = this;
                var timestamp = new Date(this.state.date).getTime()/1000;
                var updateBtc = false;
                var updateUser = false;
                var updatePortfolio = false;
                var updateInvestment = false;

                db.collection('coins').where('symbol', '==', 'BTC')
                    .where('portfolioID', '==', this.props.user.portfolioID)
                    .get().then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        var coinRef = db.collection('coins').doc(doc.id);
                        db.runTransaction(function(transaction) {
                            return transaction.get(coinRef).then(function(coinDoc) {
                                if (!coinDoc.exists) {
                                    throw "Document does not exist!";
                                }

                                var quan = new Decimal(coinDoc.data().quantity).plus(context.state.montoBtc);
                                transaction.update(coinRef, { quantity: quan.toNumber() });
                                return quan;


                            });
                        }).then(function(quan) {
                            console.log("Nueva cantidad de BTC: " , quan);
                            updateBtc = true;
                            if (updateBtc && updateInvestment && updatePortfolio && updateUser){
                                context.handleClose();
                                context.props.actionFeedback(0, 'Nueva inversion cargada!')
                            }
                        }).catch(function(err) {
                            // This will be an "population is too big" error.
                            console.error(err);
                        });
                    })
                });

                var clientRef = db.collection('clients').doc(context.state.clientID);
                db.runTransaction(function(transaction) {
                    return transaction.get(clientRef).then(function(coinDoc) {
                        if (!coinDoc.exists) {
                            throw "Document does not exist!";
                        }

                        var quanCpart = new Decimal(coinDoc.data().qCPart).plus(context.state.qCPart);
                        var btc = new Decimal(coinDoc.data().investmentBtc).plus(context.state.montoBtc);
                        var usd = new Decimal(coinDoc.data().investmentUsd).plus(context.state.montoNetUs);
                        var numerador = new Decimal(new Decimal(coinDoc.data().valorCpart).times(coinDoc.data().qCPart))
                            .plus(new Decimal(context.state.actualCpartValue).times(context.state.qCPart));
                        var pondValCPart = numerador.dividedBy(quanCpart);
                        var data = { investmentBtc: btc.toNumber(),  investmentUsd: usd.toNumber(),
                            qCPart: quanCpart.toNumber(), valorCpart: pondValCPart.toNumber() };
                        transaction.update(clientRef, { investmentBtc: btc.toNumber(),  investmentUsd: usd.toNumber(),
                            qCPart: quanCpart.toNumber(), valorCpart: pondValCPart.toNumber() });
                        return data;
                    });
                }).then(function(data) {
                    console.log("usuario actualizado: " , data);
                    updateUser = true;
                    if (updateBtc && updateInvestment && updatePortfolio && updateUser){
                        context.handleClose();
                        context.props.actionFeedback(0, 'Nueva inversion cargada!')
                    }
                }).catch(function(err) {
                    // This will be an "population is too big" error.
                    console.error(err);
                });

                var portRef = db.collection('portfolios').doc(context.props.user.portfolioID);
                db.runTransaction(function(transaction) {
                    return transaction.get(portRef).then(function(portDoc) {
                        if (!portDoc.exists) {
                            throw "Document does not exist!";
                        }

                        var quanCpart = new Decimal(portDoc.data().qCPart).plus(context.state.qCPart);

                        transaction.update(portRef, { qCPart: quanCpart.toNumber() });
                        return quanCpart;



                    });
                }).then(function(data) {
                    console.log("Cantidad de cuota partes totales: " , data);
                    updatePortfolio = true;
                    if (updateBtc && updateInvestment && updatePortfolio && updateUser){
                        context.handleClose();
                        context.props.actionFeedback(0, 'Nueva inversion cargada!')
                    }
                }).catch(function(err) {
                    // This will be an "population is too big" error.
                    console.error(err);
                });

                db.collection('investments').add({
                    clientID: context.state.clientID,
                    portfolioID: context.props.user.portfolioID,
                    date: timestamp,
                    montoUss: parseFloat(context.state.montoUss),
                    comisionBroker: parseFloat(context.state.comisionBroker),
                    montoNetUs : parseFloat(context.state.montoNetUs),
                    montoBtc: parseFloat(context.state.montoBtc),
                    btcUsd: parseFloat(context.state.btcUsd),
                    qCPart: parseFloat(context.state.qCPart),
                    actualCpartValue: parseFloat(context.state.actualCpartValue),
                    type: 'in',
                })
                    .then(function(docRef) {
                        console.log("Document written with ID: ", docRef.id);
                        updateInvestment = true;
                        if (updateBtc && updateInvestment && updatePortfolio && updateUser){
                            context.handleClose();
                            context.props.actionFeedback(0, 'Nueva inversion cargada!')
                        }

                    })
                    .catch(function(error) {
                        console.error("Error adding document: ", error);
                    });

            }else {
                this.setState({error: true});
                this.props.actionFeedback(1,'Campos erroneos')
            }

        } else {

            if (this.state.client && this.state.clientID && this.state.montoUss && this.state.montoBtc
                && this.state.btcUsd && this.state.qCPart){
                var db = firebase.firestore();
                const context = this;
                var timestamp = new Date(this.state.date).getTime()/1000;
                var updateBtc = false;
                var updateUser = false;
                var updatePortfolio = false;
                var updateInvestment = false;

                db.collection('coins').where('symbol', '==', 'BTC')
                    .where('portfolioID', '==', this.props.user.portfolioID)
                    .get().then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        var coinRef = db.collection('coins').doc(doc.id);
                        db.runTransaction(function(transaction) {
                            return transaction.get(coinRef).then(function(coinDoc) {
                                if (!coinDoc.exists) {
                                    throw "Document does not exist!";
                                }

                                var quan = new Decimal(coinDoc.data().quantity).minus(context.state.montoBtc);
                                transaction.update(coinRef, { quantity: quan.toNumber() });
                                return quan;


                            });
                        }).then(function(quan) {
                            console.log("Nueva cantidad de BTC: " , quan);
                            updateBtc = true;
                            if (updateBtc && updateInvestment && updatePortfolio && updateUser){
                                context.handleClose();
                                context.props.actionFeedback(0, 'Nueva inversion cargada!')
                            }
                        }).catch(function(err) {
                            // This will be an "population is too big" error.
                            console.error(err);
                        });
                    })
                });

                var clientRef = db.collection('clients').doc(context.state.clientID);
                db.runTransaction(function(transaction) {
                    return transaction.get(clientRef).then(function(coinDoc) {
                        if (!coinDoc.exists) {
                            throw "Document does not exist!";
                        }

                        var quanCpart = new Decimal(coinDoc.data().qCPart).minus(context.state.qCPart);
                        var btc = new Decimal(coinDoc.data().investmentBtc).minus(context.state.montoBtc);
                        var usd = new Decimal(coinDoc.data().investmentUsd).minus(context.state.montoUss);

                        var data = { investmentBtc: btc.toNumber(),  investmentUsd: usd.toNumber(),
                            qCPart: quanCpart.toNumber() };
                        transaction.update(clientRef, { investmentBtc: btc.toNumber(),  investmentUsd: usd.toNumber(),
                            qCPart: quanCpart.toNumber() });
                        return data;
                    });
                }).then(function(data) {
                    console.log("usuario actualizado: " , data);
                    updateUser = true;
                    if (updateBtc && updateInvestment && updatePortfolio && updateUser){
                        context.handleClose();
                        context.props.actionFeedback(0, 'Nueva inversion cargada!')
                    }
                }).catch(function(err) {
                    // This will be an "population is too big" error.
                    console.error(err);
                });

                var portRef = db.collection('portfolios').doc(context.props.user.portfolioID);
                db.runTransaction(function(transaction) {
                    return transaction.get(portRef).then(function(portDoc) {
                        if (!portDoc.exists) {
                            throw "Document does not exist!";
                        }

                        var quanCpart = new Decimal(portDoc.data().qCPart).minus(context.state.qCPart);

                        transaction.update(portRef, { qCPart: quanCpart.toNumber() });
                        return quanCpart;



                    });
                }).then(function(data) {
                    console.log("Cantidad de cuota partes totales: " , data);
                    updatePortfolio = true;
                    if (updateBtc && updateInvestment && updatePortfolio && updateUser){
                        context.handleClose();
                        context.props.actionFeedback(0, 'Nueva inversion cargada!')
                    }
                }).catch(function(err) {
                    // This will be an "population is too big" error.
                    console.error(err);
                });

                db.collection('investments').add({
                    clientID: context.state.clientID,
                    portfolioID: context.props.user.portfolioID,
                    date: timestamp,
                    montoUss: context.state.montoUss,
                    montoBtc: context.state.montoBtc,
                    btcUsd: context.state.btcUsd,
                    qCPart: context.state.qCPart,
                    actualCpartValue: context.state.actualCpartValue,
                    type: 'out',
                })
                    .then(function(docRef) {
                        console.log("Document written with ID: ", docRef.id);
                        updateInvestment = true;
                        if (updateBtc && updateInvestment && updatePortfolio && updateUser){
                            context.handleClose();
                            context.props.actionFeedback(0, 'Nueva inversion cargada!')
                        }

                    })
                    .catch(function(error) {
                        console.error("Error adding document: ", error);
                    });

            }

        }


    };

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
            error: false,
        });

        if (name === 'montoUss'){
            var net = event.target.value - ((this.state.comisionBroker ? this.state.comisionBroker : 0)  / 100) * event.target.value;
            var btc = net / parseFloat(this.state.btcUsd?this.state.btcUsd:0);
            var qC = btc / (this.state.actualCpartValue?this.state.actualCpartValue:0);
            this.setState({montoNetUs: net, montoBtc: btc, qCPart: qC});
        }
        if (name === 'comisionBroker'){
            var net = (this.state.montoUss?this.state.montoUss:0) - (event.target.value / 100) * (this.state.montoUss?this.state.montoUss:0);
            var btc = net / parseFloat(this.state.btcUsd?this.state.btcUsd:0);
            var qC = btc / (this.state.actualCpartValue?this.state.actualCpartValue:0);
            this.setState({montoNetUs: net, montoBtc: btc, qCPart: qC});
        }

        if (name === 'montoBtc'){
            var net = parseFloat(event.target.value) * (this.state.btcUsd?this.state.btcUsd:0);
            var qC = parseFloat(event.target.value) / (this.state.actualCpartValue?this.state.actualCpartValue:0);
            this.setState({montoNetUs: net, qCPart: qC});
        }


    };

    handleChangeOut = name => (event) => {
        this.setState({
            [name]: event.target.value,
            error: false,
        });

        if (name === 'montoUss'){

            var btc = event.target.value / parseFloat(this.state.btcUsd?this.state.btcUsd:0);
            var qC = btc / (this.state.actualCpartValue?this.state.actualCpartValue:0);
            this.setState({montoBtc: btc, qCPart: qC});
        }


        if (name === 'montoBtc'){
            var net = parseFloat(event.target.value) * (this.state.btcUsd?this.state.btcUsd:0);
            var qC = parseFloat(event.target.value) / (this.state.actualCpartValue?this.state.actualCpartValue:0);
            this.setState({montoUss: net, qCPart: qC});
        }


    };

    handleChangeClient = (newValue) => {
        this.setState({client: newValue})
    };
    handleChangeClientId = (newValue) => {
        this.setState({clientID: newValue})
    };

    handleChangeType = event => {
        this.setState({ type: event.target.value, error: false });
    };

    bringClientData = () =>{
        if (this.state.clientID){
            if (this.state.btcUsd){
                var db = firebase.firestore();
                var context = this;
                db.collection('clients').doc(this.state.clientID).get().then(function (doc) {
                    var client = doc.data();
                    var btc = new Decimal(client.qCPart).times(context.state.actualCpartValue);
                    var usd = btc.times(context.state.btcUsd);
                    context.setState({
                        qCPart: client.qCPart,
                        montoBtc: btc.toNumber(),
                        montoUss: usd.toNumber(),
                    });

                });
            }
            else {
                this.props.actionFeedback(1, 'Precio del BTC no indicado')
            }
        } else {
            this.props.actionFeedback(1, 'Cliente no seleccionado')
        }
    };

    handleClose = () => {
        this.setState({
            client: '',
            clientID: '',
            date: getCurrentDate(),
            montoUss: '',
            comisionBroker: '',
            montoNetUs : '',
            montoBtc: '',
            btcUsd: '',
            qCPart: '',
            error: false,
        });
        this.props.handleClose(0);
    };


    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Modal open={this.props.openInvestment} className={"modalInv"} onClose={this.handleClose}>
                        <Paper className={"modalPaper"} elevation={1}>
                            <p className={'title modalTi'}>Nueva Inversion</p>
                            <FormControl component="fieldset" className={'formControl'}>
                                <RadioGroup
                                    aria-label="Tipo"
                                    name="type"
                                    className={'radioForm'}
                                    value={this.state.type}
                                    onChange={this.handleChangeType}
                                >
                                    <FormControlLabel value={'in'} control={<Radio color={'primary'}/>} label="Ingreso" className={'controlFormLabel'} />
                                    <FormControlLabel value={'out'} control={<Radio color={'primary'}/>} label="Egreso" className={'controlFormLabel'} />
                                </RadioGroup>
                            </FormControl>

                            {this.state.type === 'in' &&
                            <div>
                                <ClientsField error={this.state.error} handleChangeClientId={this.handleChangeClientId} handleChangeClient={this.handleChangeClient} value={this.state.client} user={this.props.user}/>
                                <TextField
                                    value={this.state.date}
                                    onChange={this.handleChange('date')}
                                    error={this.state.error}
                                    label="Fecha"
                                    className="fieldModal"
                                    type="date"
                                    name="date"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.btcUsd}
                                    onChange={this.handleChange('btcUsd')}
                                    error={this.state.error}
                                    label="Precio USD/BTC"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.montoUss}
                                    onChange={this.handleChange('montoUss')}
                                    error={this.state.error}
                                    label="Monto U$S"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.comisionBroker}
                                    onChange={this.handleChange('comisionBroker')}
                                    error={this.state.error}
                                    label="Comision Broker"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.montoNetUs}
                                    onChange={this.handleChange('montoNetUs')}
                                    label="Monto neto U$S"
                                    error={this.state.error}
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.montoBtc}
                                    onChange={this.handleChange('montoBtc')}
                                    error={this.state.error}
                                    label="Monto BTC"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />

                                <TextField
                                    value={this.state.qCPart}
                                    onChange={this.handleChange('qCPart')}
                                    error={this.state.error}
                                    label="Cantidad C.Partes"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                            </div>}
                            {this.state.type === 'out' &&
                            <div>
                                <ClientsField error={this.state.error} handleChangeClientId={this.handleChangeClientId} handleChangeClient={this.handleChangeClient} value={this.state.client} user={this.props.user}/>
                                <Button onClick={this.bringClientData} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton outButton"}>
                                    Traer 100%
                                </Button>
                                <TextField
                                    value={this.state.date}
                                    onChange={this.handleChangeOut('date')}
                                    error={this.state.error}
                                    label="Fecha"
                                    className="fieldModal"
                                    type="date"
                                    name="date"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.btcUsd}
                                    onChange={this.handleChangeOut('btcUsd')}
                                    error={this.state.error}
                                    label="Precio USD/BTC"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.actualCpartValue}
                                    onChange={this.handleChangeOut('actualCpartValue')}
                                    error={this.state.error}
                                    label="Valor Cuota Parte"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                                <TextField
                                    value={this.state.montoUss}
                                    onChange={this.handleChangeOut('montoUss')}
                                    error={this.state.error}
                                    label="Monto U$S"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />

                                <TextField
                                    value={this.state.montoBtc}
                                    onChange={this.handleChangeOut('montoBtc')}
                                    error={this.state.error}
                                    label="Monto BTC"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />

                                <TextField
                                    value={this.state.qCPart}
                                    onChange={this.handleChangeOut('qCPart')}
                                    error={this.state.error}
                                    label="Cantidad C.Partes"
                                    className="fieldModal"
                                    type="number"
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: '',
                                            focused: 'inputCustomLogin',
                                            notchedOutline: 'inputCustomLogin',
                                        },
                                    }}
                                />
                            </div>}

                            <Button onClick={this.onNewInvestment} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"}>
                                Cargar
                            </Button>
                        </Paper>
                    </Modal>
                </MuiThemeProvider>
            </div>
        )
    }
}