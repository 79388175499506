import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import bclogo from "../../images/BC_Logo_.png";
import firebase from '../../firebase';
import {Decimal} from 'decimal.js';
import favicon from '../.././images/favicon.png';


function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function toFixed2(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10,e-1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10,e);
            x += (new Array(e+1)).join('0');
        }
    }
    return x;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {  order, orderBy, } = this.props;
        const rows = [
            { id: 'img', numeric: false, disablePadding: false, label: '' },
            { id: 'name', numeric: false, disablePadding: false, label: 'Nombre' },
            { id: this.props.btc ? 'precioPonderado': 'precioPonderadoUSD', numeric: true, disablePadding: false, label: 'Precio compra' },
            { id: 'quantity', numeric: true, disablePadding: false, label: 'Cantidad' },
            { id: this.props.btc ? 'totalBuy':'totalBuyUSD', numeric: true, disablePadding: false, label: 'Total Compra' },
            { id: this.props.btc ? 'prAct': 'prActUSD', numeric: true, disablePadding: false, label: 'Precio actual' },
            { id: this.props.btc ? 'total':'totalUSD', numeric: true, disablePadding: false, label: 'Total' },
            { id: 'part', numeric: true, disablePadding: false, label: 'Parte' },

            { id: this.props.btc ? 'rend' : 'rendUSD', numeric: true, disablePadding: false, label: 'Rendimiento' },
            { id: this.props.btc ? 'rendNet' : 'rendNetUSD', numeric: true, disablePadding: false, label: 'Rendimiento neto' },
            { id: 'exchange', numeric: false, disablePadding: false, label: 'Exchange' },
        ];

        return (
            <TableHead className={'tableHead'}>
                <TableRow>
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '94%',
        marginTop: theme.spacing.unit * 3,
        marginLeft: '3%',
        borderRadius: 15,
        marginBottom: theme.spacing.unit * 3,
        [theme.breakpoints.up('md')]: {
            width: '96%',
            marginLeft: '2%',
        },
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});

class PortTable extends React.Component {
    state = {
        order: 'desc',
        orderBy: 'total',
        selected: [],
        data: [],
        portListener: {},
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    componentDidMount() {



    }


    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;
        const { data, order, orderBy, selected, } = this.state;


        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            btc={this.props.btc}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={data.length}
                        />
                        <TableBody className={'tableBody'}>
                            {stableSort(this.props.data, getSorting(order, orderBy))
                                .map(n => {
                                    const isSelected = this.isSelected(n.id);
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                            className={classes.row}
                                        >
                                            <TableCell component="th" scope="row">
                                                <img className={'portfolioTableImg'} src={n.imageUrl ? 'https://www.cryptocompare.com' + n.imageUrl : favicon }/>
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {n.name}
                                            </TableCell>

                                            {n.name === 'Bitcoin' &&  <TableCell numeric>{this.props.btc ? '-' : '-' }</TableCell>
                                            }
                                            {n.name !== 'Bitcoin' &&   <TableCell numeric>{this.props.btc ? '₿ ' + new Decimal(n.precioPonderado).toDecimalPlaces(8).toNumber() : '$ ' + new Decimal(n.precioPonderadoUSD).toDecimalPlaces(2).toNumber() }</TableCell>
                                            }

                                            <TableCell numeric>{new Decimal(n.quantity).toDecimalPlaces(8).toNumber()}</TableCell>

                                            <TableCell numeric>{this.props.btc ? '₿ ' +  new Decimal(n.totalBuy).toDecimalPlaces(8).toNumber(): '$ ' +new Decimal(n.totalBuyUsd).toDecimalPlaces(2).toNumber() }</TableCell>
                                            <TableCell numeric>{this.props.btc ? '₿ ' + new Decimal(n.prAct).toNumber().toFixed(8) : '$ ' + new Decimal(n.prActUSD).toNumber().toFixed(2) }</TableCell>

                                            <TableCell numeric>{this.props.btc ? '₿ ' +  new Decimal(n.total).toDecimalPlaces(8).toNumber(): '$ ' +new Decimal(n.totalUSD).toDecimalPlaces(2).toNumber() }</TableCell>
                                            <TableCell numeric>{n.part.toFixed(2) + '%'}</TableCell>
                                            {n.name === 'Bitcoin' &&  <TableCell numeric className={(this.props.btc ? n.rend : n.rendUSD) >= 0 ? 'rendPos' : 'rendNeg'}>{this.props.btc ? '-': '-'}</TableCell>
                                            }
                                            {n.name !== 'Bitcoin' && <TableCell numeric className={(this.props.btc ? n.rend : n.rendUSD) >= 0 ? 'rendPos' : 'rendNeg'}>{this.props.btc ? n.rend.toFixed(2) + '%': n.rendUSD.toFixed(2) + '%'}</TableCell>
                                            }
                                            {n.name === 'Bitcoin' && <TableCell numeric>{this.props.btc ? '-': '-'}</TableCell>
                                            }
                                            {n.name !== 'Bitcoin' && <TableCell className={(this.props.btc ? n.rend : n.rendUSD) >= 0 ? 'rendPos' : 'rendNeg'} numeric>{this.props.btc ? '₿ ' +  new Decimal(n.rendNet).toDecimalPlaces(8).toNumber(): '$ ' + new Decimal(n.rendNetUSD).toDecimalPlaces(2).toNumber()}</TableCell>
                                            }
                                            <TableCell component="th" scope="row">{n.exchange ? n.exchange : 'Bitmex'}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>

            </Paper>
        );
    }
}

PortTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PortTable);