import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ActualLiquiTable from "./liqui/ActualLiquiTable";
import LiquisTable from "./liqui/LiquisTable";


export default class Liquidation extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.clearTyped();
    }

    render() {
        return (
            <div className={'container'}>
                <p className={"title"}>Liquidaciones</p>
                <ActualLiquiTable user={this.props.user} totalPortfolio={this.props.totalPortfolio} actionFeedback={this.props.actionFeedback}/>
                <LiquisTable user={this.props.user} totalPortfolio={this.props.totalPortfolio}/>

            </div>
        )
    }
}