import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import bclogo from "../../images/BC_Logo_.png";


const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');



function createData(id, image, name, marketCap, price,  volume24, circSupply, change24, symbol) {

    if (!change24){
        change24 = 0;
    }

    return {id, image, name, marketCap, price, volume24, circSupply, change24, symbol };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    { id: 'img', numeric: false, disablePadding: false, label: '' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Nombre' },
    { id: 'marketCap', numeric: true, disablePadding: false, label: 'Market cap' },
    { id: 'price', numeric: true, disablePadding: false, label: 'Precio' },
    { id: 'volume24', numeric: true, disablePadding: false, label: 'Volumen (24hs)' },
    { id: 'circSupply', numeric: true, disablePadding: false, label: 'Circulante' },
    { id: 'change24', numeric: true, disablePadding: false, label: 'Cambio (24hs)' },

];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {  order, orderBy, } = this.props;

        return (
            <TableHead className={'tableHead'}>
                <TableRow>
                    {rows.map(row => {
                        return (
                            <TableCell
                                key={row.id}
                                numeric={row.numeric}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '94%',
        marginTop: theme.spacing.unit * 3,
        marginLeft: '3%',
        borderRadius: 15,
        marginBottom: theme.spacing.unit * 3,
        [theme.breakpoints.up('md')]: {
            width: '96%',
            marginLeft: '2%',
        },
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});

class CoinsTable extends React.Component {
    state = {
        order: 'desc',
        orderBy: 'marketCap',
        selected: [],
        data: [],
        dataFiltered: [],
        tBodyC: 'tableBody'
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    componentWillReceiveProps(nextProps) {
        /*this.setState({tBodyC: 'tableBodyC'});*/
       if (nextProps.btc && nextProps.btc !== this.props.btc) {
           this.props.clearTyped();
           fetch('https://min-api.cryptocompare.com/data/top/totalvolfull?limit=100&tsym=BTC&api_key=03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d')
               .then(response => response.json())
               .then(data => {
                   var datas = [];
                   data.Data.map(coin => {
                       datas.push(createData(coin.CoinInfo.Id, coin.CoinInfo.ImageUrl,coin.CoinInfo.FullName, coin.RAW.BTC.MKTCAP, coin.RAW.BTC.PRICE, coin.RAW.BTC.TOTALVOLUME24H, coin.RAW.BTC.SUPPLY, coin.RAW.BTC.CHANGEPCT24HOUR, coin.CoinInfo.Name))
                   });
                   this.setState({data: datas, dataFiltered: datas})
               });
       }
       else if(!nextProps.btc && nextProps.btc !== this.props.btc ){
           this.props.clearTyped();
           fetch('https://min-api.cryptocompare.com/data/top/totalvolfull?limit=100&tsym=USD&api_key=03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d')
               .then(response => response.json())
               .then(data => {
                   var datas = [];
                   data.Data.map(coin => {
                       datas.push(createData(coin.CoinInfo.Id, coin.CoinInfo.ImageUrl,coin.CoinInfo.FullName, coin.RAW.USD.MKTCAP, coin.RAW.USD.PRICE, coin.RAW.USD.TOTALVOLUME24HTO, coin.RAW.USD.SUPPLY, coin.RAW.USD.CHANGEPCT24HOUR, coin.CoinInfo.Name))
                   });
                   this.setState({data: datas, dataFiltered: datas})
               });
       }

       if (nextProps.search){
           var filtCoins = [];
           this.state.data.forEach(function (coin) {
               if (coin.name.toLowerCase().includes(nextProps.search.toLowerCase()) ||
                   coin.symbol.toLowerCase().includes(nextProps.search.toLowerCase())){
                   filtCoins.push(coin);
               }
           });
           this.setState({dataFiltered: filtCoins})
       }
       else {
           this.setState({dataFiltered: this.state.data})
       }


      /* setTimeout(() => {this.setState({tBodyC: 'tableBody'})}, 1000)*/

    }

    componentDidMount() {



        if (this.props.btc){
            fetch('https://min-api.cryptocompare.com/data/top/totalvolfull?limit=100&tsym=BTC&api_key=03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d')
                .then(response => response.json())
                .then(data => {
                    var datas = [];
                    data.Data.map(coin => {
                        var coin2 = createData(coin.CoinInfo.Id, coin.CoinInfo.ImageUrl,coin.CoinInfo.FullName, coin.RAW.BTC.MKTCAP, coin.RAW.BTC.PRICE, coin.RAW.BTC.TOTALVOLUME24H, coin.RAW.BTC.SUPPLY, coin.RAW.BTC.CHANGEPCT24HOUR, coin.CoinInfo.Name);

                        datas.push(createData(coin.CoinInfo.Id, coin.CoinInfo.ImageUrl,coin.CoinInfo.FullName, coin.RAW.BTC.MKTCAP, coin.RAW.BTC.PRICE, coin.RAW.BTC.TOTALVOLUME24H, coin.RAW.BTC.SUPPLY, coin.RAW.BTC.CHANGEPCT24HOUR, coin.CoinInfo.Name))
                    });
                    this.setState({data: datas, dataFiltered: datas})
                });
        }
        else{
            fetch('https://min-api.cryptocompare.com/data/top/totalvolfull?limit=100&tsym=USD&api_key=03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d')
                .then(response => response.json())
                .then(data => {
                    var datas = [];
                    data.Data.map(coin => {
                        datas.push(createData(coin.CoinInfo.Id, coin.CoinInfo.ImageUrl,coin.CoinInfo.FullName, coin.RAW.USD.MKTCAP, coin.RAW.USD.PRICE, coin.RAW.USD.TOTALVOLUME24HTO, coin.RAW.USD.SUPPLY, coin.RAW.USD.CHANGEPCT24HOUR, coin.CoinInfo.Name))
                    });
                    this.setState({data: datas, dataFiltered: datas})
                });
        }


    }


    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;
        const { dataFiltered, order, orderBy, selected, } = this.state;


        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={dataFiltered.length}
                        />
                        <TableBody className={this.state.tBodyC}>
                            {stableSort(dataFiltered, getSorting(order, orderBy))
                                .map(n => {
                                    const isSelected = this.isSelected(n.id);
                                    return (
                                        <TableRow
                                            className={classes.row}
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >
                                            <TableCell component="th" scope="row">
                                                <img className={'portfolioTableImg'} src={'https://www.cryptocompare.com' + n.image}/>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {n.name}
                                            </TableCell>

                                            <TableCell numeric>{this.props.btc ? '₿ ' +  n.marketCap.toFixed(8):'$ ' + n.marketCap.toFixed(2)}</TableCell>
                                            <TableCell numeric>{this.props.btc ? '₿ ' + n.price.toFixed(8):'$ ' + n.price.toFixed(2)}</TableCell>
                                            <TableCell numeric>{this.props.btc ? '₿ ' + n.volume24.toFixed(8):'$ ' + n.volume24.toFixed(2)}</TableCell>
                                            <TableCell numeric>{n.circSupply}</TableCell>
                                            <TableCell numeric className={n.change24 > 0 ? 'rendPos' : 'rendNeg'}>{n.change24.toFixed(2)+ '%'}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>

            </Paper>
        );
    }
}

CoinsTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoinsTable);