import firebase from './firebase'

export function copyAndCreateTest() {
    var db = firebase.firestore();
    return new Promise(function (resolve,reject) {
        db.collection('portfolios').doc('6UJivctnM9d2QN66S2B9').get()
            .then(function (doc) {
                var ymp = doc.data();
                ymp.name = 'YMP Test';
                db.collection('portfolios').add(ymp).then(function (ympTest) {
                    resolve(ympTest);
                   //Copiamos las coins
                    db.collection('coins').where('portfolioID','==', '6UJivctnM9d2QN66S2B9').get().then(function (snapCoins) {
                        snapCoins.forEach(function (coin) {
                            var coinValue = coin.data();
                            coinValue.portfolioID = ympTest.id;
                            db.collection('coins').add(coinValue);
                        })
                    });
                    //Copiamos Trades
                    db.collection('trades').where('portfolioID','==', '6UJivctnM9d2QN66S2B9').get().then(function (snapTrades) {
                        snapTrades.forEach(function (trade) {
                            var tradeValue = trade.data();
                            tradeValue.portfolioID = ympTest.id;
                            db.collection('trades').add(tradeValue)
                        })
                    });
                    //Copiamos clientes
                    db.collection('clients').where('portfolioID','==', '6UJivctnM9d2QN66S2B9').get().then(function (snapClients) {
                        snapClients.forEach(function (client) {
                            var clientValue = client.data();
                            clientValue.portfolioID = ympTest.id;
                            clientValue.userID = '';
                            db.collection('clients').add(clientValue).then(function (newClient) {
                                //Copiamos Investments
                                db.collection('investments').where('clientID','==', client.id).get().then(function (snapInvest) {
                                    snapInvest.forEach(function (invest) {
                                        var investValue = invest.data();
                                        investValue.portfolioID = ympTest.id;
                                        investValue.clientID = newClient.id;
                                        db.collection('investments').add(investValue);
                                    })
                                })
                            })
                        })
                    });
                    //Copiamos Liquis
                    db.collection('liquis').where('portfolioID','==', '6UJivctnM9d2QN66S2B9').get().then(function (snapLiquis) {
                        snapLiquis.forEach(function (liqui) {
                            var liquiValue = liqui.data();
                            liquiValue.portfolioID = ympTest.id;
                            db.collection('liquis').add(liquiValue)
                        })
                    })


                });
            })
    });
}