import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import {defaults, Line, Pie} from "react-chartjs-2";
import cryptocurrencyImg from '../images/cryptocurrency.svg';
import firebase from '../firebase';
import InvesTable from "../client/InvesTable";
const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

export default class ClientsInvestment extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            dataRend: {},
            client: '',
        }
    }

    componentDidMount() {

        if (this.props.userID){
            var db = firebase.firestore();
            var context = this;
            db.collection('clients').doc(this.props.userID).get().then(function (doc) {
                context.setState({client: doc.data()});
                if (context.props.portfolio.historicCpart){

                    var arrayHistoric = context.props.portfolio.historicCpart;
                    var values = [];
                    var dates = [];

                    arrayHistoric.sort(function(a, b){
                        var keyA = new Date(a.date * 1000),
                            keyB = new Date(b.date * 1000);
                        // Compare the 2 dates
                        if(keyA < keyB) return -1;
                        if(keyA > keyB) return 1;
                        return 0;
                    });
                    var lastDate = new Date(context.state.client.date * 1000);
                    lastDate.setMonth(lastDate.getMonth() - 1);

                    var yearFilteredArray = [];
                    /*var lastDate = new Date(arrayHistoric[arrayHistoric.length-1].date * 1000);*/
                    /*lastDate.setFullYear(lastDate.getFullYear() - 1);*/
                    arrayHistoric.forEach(function (hist) {
                        if (new Date(hist.date * 1000) > lastDate){
                            yearFilteredArray.push(hist)
                        }
                    });


                    yearFilteredArray.forEach(function (hist) {
                        values.push(hist.value);
                        dates.push(monthNames[new Date(hist.date * 1000).getMonth()]);
                    });
                    const data = (canvas) => {

                        const ctx = canvas.getContext("2d");
                        var gradientFill = ctx.createLinearGradient(0, 0, 0, 350);
                        gradientFill.addColorStop(0, "rgba(68, 87, 243, 0.5)");
                        gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.1)");
                        return{
                            labels: dates,
                            datasets: [
                                {
                                    label: '',
                                    fill: true,
                                    lineTension: 0.3,
                                    backgroundColor: gradientFill,
                                    borderColor: '#4457F3',
                                    borderCapStyle: 'butt',
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: 'miter',
                                    pointBorderColor: 'rgba(68, 87, 243, 1)',
                                    pointBackgroundColor: '#fff',
                                    pointBorderWidth: 1,
                                    pointHoverRadius: 5,
                                    pointHoverBackgroundColor: '#4457F3',
                                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 1,
                                    pointHitRadius: 10,
                                    data: values,
                                }
                            ]
                        }

                    };
                    context.setState({dataRend: data})

                }
            })

        }
    }

    render() {

        defaults.global.defaultFontFamily = 'Poppins';


        return (
            <div className={'container'}>
                <p className={'title'}>{this.state.client ? this.state.client.name + " " + this.state.client.lastName : ''}</p>
                <div className={"graphsContainer"}>
                    <Paper elevation={1} className={"paperChartPortfolio"}>
                        <div className={"rendimientoCointainer rendimientoContainerInvestment"}>
                            <p className={'title'}>Inversion hoy</p>
                            <img className={'imgCryptos'} src={cryptocurrencyImg} alt={'imgCryptos'}/>
                            <div className={'investmentDataContainer'}>
                                <p className={'title'}>Actual BTC <span className={'cpartValue'}>{'₿' + this.props.client.actualBtcInvest.toFixed(8)}</span></p>
                                <p className={'title'}>Actual USD <span className={'cpartValue'}>{'$' + this.props.client.actualUsdInvest.toFixed(2)}</span></p>
                                <p className={'title'}>Inicial BTC <span className={'cpartValue'}>{'₿' +this.props.client.btcInvest.toFixed(8)}</span></p>
                                <p className={'title'}>Inicial USD <span className={'cpartValue'}>{'$' +this.props.client.usdInvest.toFixed(2)}</span></p>
                                <p className={'title'}>Cantidad C.Partes <span className={'cpartValue'}>{this.props.client.quanCp?this.props.client.quanCp.toFixed(4):this.props.client.quanCp}</span></p>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={1} className={"paperRendimiento paperRendimientoClient"}>
                        <div className={'rendimientoCointainer'}>
                            <p className={"title"}>Rendimiento<span className={(this.props.btc ? this.props.client.rend : this.props.client.rendUsd) >= 0 ? 'cpartValue rendPos' : 'cpartValue rendNeg'}>{this.props.btc ? this.props.client.rend.toFixed(2) + '%':this.props.client.rendUsd.toFixed(2) + '%'}</span></p>

                            <Line className={'chart'} data={this.state.dataRend}  options={{
                                maintainAspectRatio: false,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    xAxes: [{
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        },

                                        ticks: {
                                            autoSkip: true,
                                            /* maxRotation: window.innerWidth < 960 ? 90 : 0,
                                             minRotation: window.innerWidth < 960 ? 90 : 0,*/
                                            maxRotation: 0,
                                            minRotation: 0,
                                        }
                                    }],
                                    yAxes: [{
                                        gridLines: {
                                            color: "rgba(0, 0, 0, 0)",
                                        },
                                        ticks: {
                                            display: false //this will remove only the label
                                        }
                                    }]
                                }
                            }}/>
                        </div>
                    </Paper>
                    <InvesTable user={this.props.user} userID={this.props.userID}/>
                </div>
            </div>
        )
    }
}