import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import firebase from '../firebase';


function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}
function getCurrentDateJustMonthYear() {
    var today = new Date();
    var dd = '1';
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

export default class NewPortfolio extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            managementFee: '',
            valueCpart: '',
            error: false,
        };
    }

    componentWillUnmount() {

    }

    componentDidMount() {

    }

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
            error: false
        });
    };

    onNewPort = () => {
        var context = this;
        if (this.state.name && this.state.managementFee){
            var lastLiqui = getCurrentDateJustMonthYear() + 'T03:00:30.000Z';
            var firstHistoricDate = new Date(lastLiqui).getTime()/1000;

            var db = firebase.firestore();
            var context = this;
            db.collection('portfolios').add({
                name: context.state.name,
                managementFee: parseFloat(context.state.managementFee),
                qCPart: 0,
                lastLiqui: lastLiqui,
                historicCpart: [{value: 0, date: firstHistoricDate}]
            }).then(function (doc) {

                db.collection("coins").add({
                    precioPonderado: 0,
                    precioPonderadoUSD: 0,
                    exchanges: [],
                    quantity: 0,
                    portfolioID: doc.id,
                    name: 'Bitcoin',
                    symbol: 'BTC',
                    imageUrl: '/media/19633/btc.png'
                }).then(function (btc) {
                    context.props.actionFeedback(0, 'Portfolio ' + context.state.name + ' creado');
                    context.handleClose();
                });
                context.handleClose();
            })

        }
        else {
            this.setState({error: true});
            this.props.actionFeedback(1, 'Campos incorrectos')
        }

    };

    handleClose = () => {
        this.setState({
            name: '',
            managementFee: '',
            valueCpart: '',
            error: false,
        });
        this.props.handleClose(9);
        setTimeout(function () {
            document.body.style.overflow = 'auto';
        }, 10);

    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Modal open={this.props.open} className={"modalInv"} onClose={this.handleClose}>
                        <Paper className={"modalPaper"} elevation={1}>
                            <p className={'title modalTi'}>Nuevo Portfolio</p>

                            <TextField
                                value={this.state.name}
                                onChange={this.handleChange('name')}
                                error={this.state.error}
                                label="Nombre"
                                className="fieldModal"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.managementFee}
                                onChange={this.handleChange('managementFee')}
                                error={this.state.error}
                                label="Management Fee"
                                className="fieldModal"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />


                            <Button onClick={this.onNewPort} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"}>
                                Cargar
                            </Button>
                        </Paper>
                    </Modal>
                </MuiThemeProvider>
            </div>
        )
    }
}