import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popper from '@material-ui/core/Popper';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const cc = require('cryptocompare');
cc.setApiKey('03dee27778590267b49b63bb4ba6f911bee4bb4821c80c67463111c2ad5feb7d');




function renderInputComponent(inputProps) {
    const {error, classes, inputRef = () => {}, ref, ...other } = inputProps;


    return (
        <div>
            <TextField
                className={'fieldModal clientsField'}
                variant={"outlined"}
                margin="normal"
                error={error}
                InputLabelProps={{
                    classes: {
                        root: '',
                        focused: 'inputCustomLogin',
                    },
                }}
                InputProps={{
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: {
                        root: '',
                        focused: 'inputCustomLogin',
                        notchedOutline: 'inputCustomLogin',
                    },
                }}
                {...other}
            />

        </div>


    );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </strong>
                    );
                })}
            </div>
        </MenuItem>
    );
}




const styles = theme => ({
    root: {
        flexGrow: 1,
        display: 'inline-block',
        marginLeft: '3%',
        width: '94%',
    },
    container: {
        position: 'relative',
        display: 'inline-block'
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 2,
        marginTop: theme.spacing.unit,
        left: '3%',
        width: '94%',
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});

class CoinsApiField extends React.Component {
    state = {
        single: '',
        popper: '',
        suggestions: [],
        suggestionsCoins: [],
    };

    getSuggestions2 = (value) => {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0
            ? []
            : this.state.suggestionsCoins.filter(suggestion => {
                const keep =
                    count < 40 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    };

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions2(value),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = name => (event, { newValue }) => {
        if (name === 'single'){
            this.props.handleChange(newValue);
        }
        else{
            this.setState({
                [name]: event.target.value,
            });
        }
    };

    componentDidMount() {
        cc.coinList()
            .then(coinList => {
                var suggestionCoinsList = [];
                console.log(coinList.Data);

                for (var key in coinList.Data) {
                    if (coinList.Data.hasOwnProperty(key)) {
                        suggestionCoinsList.push( { label:  coinList.Data[key].CoinName });
                    }
                }
                // ->
                // {
                //   BTC: {
                //    Id: "1182",
                //    Url: "/coins/btc/overview",
                //    ImageUrl: "/media/19633/btc.png",
                //    Name: "BTC",
                //    Symbol: "BTC",
                //    CoinName: "Bitcoin",
                //    FullName: "Bitcoin (BTC)",
                //    Algorithm: "SHA256",
                //    ProofType: "PoW",
                //    FullyPremined: "0",
                //    TotalCoinSupply: "21000000",
                //    PreMinedValue: "N/A",
                //    TotalCoinsFreeFloat: "N/A",
                //    SortOrder: "1",
                //    Sponsored: false
                // },
                //   ETH: {...},
                // }
                this.setState({
                    suggestionsCoins: suggestionCoinsList,
                })
            })
            .catch(console.error);


    }

    /*handleChange = name => (event, { newValue }) => {
        this.props.handleChange(newValue);
    };
*/

    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const autosuggestProps = {
            renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue: this.props.getSuggestApiField,
            renderSuggestion,
        };

        return (
            <div className={classes.root}>
                <Autosuggest
                    {...autosuggestProps}
                    inputProps={{
                        classes,
                        label:'Nombre',
                        value: this.props.value,
                        error: this.props.error,
                        onChange: this.handleChange('single'),
                    }}
                    theme={{
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                />



            </div>
        );
    }
}

CoinsApiField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoinsApiField);