import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ClientsField from "./autocom/ClientsField";
import firebase from '../firebase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';

function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

export default class NewAdmin extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lastName: '',
            date: getCurrentDate(),
            email:'',
            password: '',
            error: false,
            isSuper: false,
        };
    }

    componentDidMount() {

    }

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
            error: false,
        });
    };
    handleChangeSuper = name => event => {
        this.setState({ [name]: event.target.checked });
    };


    onNewClient = () => {

        if (this.state.name && this.state.lastName && this.state.email && this.state.password){
            const db = firebase.firestore();
            const context = this;
            var timestamp = new Date(this.state.date).getTime()/1000;

            var userDatabase = false;
            var userAuth = false;


            db.collection('admins').where('email','==',context.state.email).get().then(function(querySnapshot) {
                if(querySnapshot.size > 0){
                    context.setState({error: true, });
                    context.props.actionFeedback(1, 'Ya existe ese cliente en el portfolio');
                }
                else {

                    let config = {
                        apiKey: "AIzaSyDC1R33pTAJGCni6UV5cERvSLtJ5gTdjxw",
                        authDomain: "portfolio-eaaf7.firebaseapp.com",
                        databaseURL: "https://portfolio-eaaf7.firebaseio.com",
                        projectId: "portfolio-eaaf7",
                        storageBucket: "portfolio-eaaf7.appspot.com",
                        messagingSenderId: "232310493554"
                    };
                    var secondaryApp = firebase.initializeApp(config, "Secondary");
                    secondaryApp.auth().createUserWithEmailAndPassword(context.state.email, context.state.password).then(function(firebaseUser) {
                        console.log("User " + firebaseUser.user.uid + " created successfully!");
                        secondaryApp.auth().signOut();
                        userAuth = true;


                        db.collection('admins').add({
                            name: context.state.name,
                            portfolioID: context.props.user.portfolioID,
                            lastName: context.state.lastName,
                            email:context.state.email,
                            super: context.state.isSuper,
                            userID: firebaseUser.user.uid,
                        })
                            .then(function(docRef) {
                                console.log("Document written with ID: ", docRef.id);
                                context.props.actionFeedback(0, 'Admin creado');
                                context.handleClose();

                            })
                            .catch(function(error) {
                                console.error("Error adding document: ", error);
                            });

                    });
                }
            }).catch(function(error) {
                console.log("Error getting documents: ", error);
            });


        } else {
            this.setState({error: true});
            this.props.actionFeedback(1, 'Campos incompletos')
        }





    };

    handleClose = () =>{
        this.setState({
            name: '',
            lastName: '',
            date: getCurrentDate(),
            email:'',
            password: '',
            fee: '',
            valorCpart: '',
            error: false,
        });
        this.props.handleClose(7);


    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Modal open={this.props.open} className={"modalInv"} onClose={this.handleClose}>
                        <Paper className={"modalPaper"} elevation={1}>
                            <p className={'title modalTi'}>Nuevo Admin</p>
                            <TextField
                                value={this.state.name}
                                onChange={this.handleChange('name')}
                                error={this.state.error}
                                label="Nombre"
                                className="fieldModal"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.lastName}
                                onChange={this.handleChange('lastName')}
                                error={this.state.error}
                                label="Apellido"
                                className="fieldModal"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.password}
                                onChange={this.handleChange('password')}
                                error={this.state.error}
                                label="Contraseña"
                                className="fieldModal"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.date}
                                onChange={this.handleChange('date')}
                                error={this.state.error}
                                label="Fecha"
                                className="fieldModal"
                                type="date"
                                name="date"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.email}
                                onChange={this.handleChange('email')}
                                error={this.state.error}
                                label="E-mail"
                                className="fieldModal"
                                type="email"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <FormControlLabel className={'fieldModal switchMarginNormal'}
                                control={
                                    <Switch
                                        checked={this.state.isSuper}
                                        onChange={this.handleChangeSuper('isSuper')}
                                        value= 'isSuper'
                                        color="primary"
                                    />
                                }
                                label="Super"
                            />


                            <Button onClick={this.onNewClient} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"}>
                                Cargar
                            </Button>
                        </Paper>
                    </Modal>
                </MuiThemeProvider>
            </div>
        )
    }
}