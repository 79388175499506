import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CoinsTable from './Coins/CoinsTable'
import BuysTable from './trades/BuysTable'
import SellTable from "./trades/SellTable";
import LongsTable from "./trades/LongsTable";
import ShortsTable from "./trades/ShortsTable";

export default class Trades extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };



    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };
    }



    componentDidMount(){
        this.props.clearTyped();
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render(){

        const { classes } = this.props;
        const { value } = this.state;

        return(
            <div className={'container'}>
                <AppBar position="static">
                    <Tabs value={value} onChange={this.handleChange}
                          classes={{
                              indicator: 'tabsTradesIndicator'
                          }}>
                        <Tab label="Compras" />
                        <Tab label="Ventas" />
                        <Tab label="Long" />
                        <Tab label="Short" />
                    </Tabs>
                </AppBar>
                {value === 0 && <BuysTable actionFeedback={this.props.actionFeedback} btc={this.props.btc} user={this.props.user} search={this.props.search} clearTyped={this.props.clearTyped}/>}
                {value === 1 && <SellTable actionFeedback={this.props.actionFeedback} btc={this.props.btc} user={this.props.user} search={this.props.search} clearTyped={this.props.clearTyped}/>}
                {value === 2 && <LongsTable actionFeedback={this.props.actionFeedback} btc={this.props.btc} user={this.props.user} search={this.props.search} clearTyped={this.props.clearTyped} handleEndTrade={this.props.handleEndTrade} handleEditLongShort={this.props.handleEditLongShort}/>}
                {value === 3 && <ShortsTable actionFeedback={this.props.actionFeedback} btc={this.props.btc} user={this.props.user} search={this.props.search} clearTyped={this.props.clearTyped} handleEndTrade={this.props.handleEndTrade} handleEditLongShort={this.props.handleEditLongShort}/>}
            </div>
        )
    }
}