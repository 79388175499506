import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import {defaults, Line, Pie} from "react-chartjs-2";
import cryptocurrencyImg from '../images/cryptocurrency.svg';
import InvesTable from "./InvesTable";
import firebase from '../firebase';
import {Decimal} from "decimal.js";
const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

export default class Investment extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            dataRend: {},
            client: {},
            rend: 0,
            rendUsd: 0,
            actualBtcInvest: 0,
            actualUsdInvest: 0,
            cPartPriceBuy: 0,
        };
    }

    componentDidMount() {
        if (this.props.portfolio.historicCpart){

            var cPartPriceBuy  = parseFloat(this.props.user.valorCpart);
            let rend = new Decimal(this.props.actualCPart).dividedBy(cPartPriceBuy).minus(1).times(100);


            let share = new Decimal(this.props.user.qCPart).dividedBy(this.props.portfolio.qCPart).times(100).toNumber();

            let rendUsd = new Decimal(share).times(this.props.totalPortfolioUsd).dividedBy(this.props.user.investmentUsd).minus(100);

            let actualBtcInvest = rend.dividedBy(100).times(this.props.user.investmentBtc).plus(this.props.user.investmentBtc);

            let actualUsdInvest = rendUsd.dividedBy(100).times(this.props.user.investmentUsd).plus(this.props.user.investmentUsd);

            rend = rend.toNumber();
            rendUsd = rendUsd.toNumber();

            this.setState({rend: rend, rendUsd: rendUsd, actualUsdInvest: actualUsdInvest.toNumber(), actualBtcInvest: actualBtcInvest.toNumber(), cPartPriceBuy: cPartPriceBuy});

            var arrayHistoric = this.props.portfolio.historicCpart;
            var values = [];
            var dates = [];

            arrayHistoric.sort(function(a, b){
                var keyA = new Date(a.date * 1000),
                    keyB = new Date(b.date * 1000);
                // Compare the 2 dates
                if(keyA < keyB) return -1;
                if(keyA > keyB) return 1;
                return 0;
            });
            var lastDate = new Date(this.props.user.date * 1000);
            lastDate.setMonth(lastDate.getMonth() - 1);
            console.log(lastDate);
            var yearFilteredArray = [];
            /*var lastDate = new Date(arrayHistoric[arrayHistoric.length-1].date * 1000);*/
            /*lastDate.setFullYear(lastDate.getFullYear() - 1);*/
            arrayHistoric.forEach(function (hist) {
                if (new Date(hist.date * 1000) > lastDate){
                    yearFilteredArray.push(hist)
                }
            });


            yearFilteredArray.forEach(function (hist) {
                values.push(hist.value);
                dates.push(monthNames[new Date(hist.date * 1000).getMonth()]);
            });
            const data = (canvas) => {

                const ctx = canvas.getContext("2d");
                var gradientFill = ctx.createLinearGradient(0, 0, 0, 350);
                gradientFill.addColorStop(0, "rgba(68, 87, 243, 0.5)");
                gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.1)");
                return{
                    labels: dates,
                    datasets: [
                        {
                            label: '',
                            fill: true,
                            lineTension: 0.3,
                            backgroundColor: gradientFill,
                            borderColor: '#4457F3',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(68, 87, 243, 1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: '#4457F3',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: values,
                        }
                    ]
                }

            };
            this.setState({dataRend: data})

        }
        /*if (this.props.userID){
            var db = firebase.firestore;
            var context = this;
            db.collection('clients').doc(this.props.userID).get().then(function (doc) {
                context.setState({client: doc.data()})
            })

        }*/
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.totalPortfolioUsd){
            var cPartPriceBuy  = parseFloat(nextProps.user.valorCpart);
            let rend = new Decimal(nextProps.actualCPart).dividedBy(cPartPriceBuy).minus(1).times(100);


            let share = new Decimal(nextProps.user.qCPart).dividedBy(nextProps.portfolio.qCPart).times(100).toNumber();

            let rendUsd = new Decimal(share).times(nextProps.totalPortfolioUsd).dividedBy(nextProps.user.investmentUsd).minus(100);

            let actualBtcInvest = rend.dividedBy(100).times(nextProps.user.investmentBtc).plus(nextProps.user.investmentBtc);

            let actualUsdInvest = rendUsd.dividedBy(100).times(nextProps.user.investmentUsd).plus(nextProps.user.investmentUsd);

            rend = rend.toNumber();
            rendUsd = rendUsd.toNumber();

            this.setState({rend: rend, rendUsd: rendUsd, actualUsdInvest: actualUsdInvest.toNumber(), actualBtcInvest: actualBtcInvest.toNumber(), cPartPriceBuy: cPartPriceBuy});
        }
    }

    render() {

        defaults.global.defaultFontFamily = 'Poppins';
        const data = (canvas) => {

            const ctx = canvas.getContext("2d");
            var gradientFill = ctx.createLinearGradient(0, 0, 0, 350);
            gradientFill.addColorStop(0, "rgba(68, 87, 243, 0.5)");
            gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.1)");
            return{
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre','Diciembre'],
                datasets: [
                    {
                        label: '',
                        fill: true,
                        lineTension: 0.3,
                        backgroundColor: gradientFill,
                        borderColor: '#4457F3',
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: 'rgba(68, 87, 243, 1)',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: '#4457F3',
                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: [65, 59, 80, 81, 56, 55, 40, 91, 38, 59, 80, 81]
                    }
                ]
            }

        };

        return (
            <div className={'container'}>
                <p className={'title'}>Tus inversiones</p>
                <div className={"graphsContainer"}>
                    <Paper elevation={1} className={"paperChartPortfolio"}>
                        <div className={"rendimientoCointainer rendimientoContainerInvestment"}>
                            <p className={'title'}>Tu inversion</p>
                            <img className={'imgCryptos'} src={cryptocurrencyImg} alt={'imgCryptos'}/>
                            <div className={'investmentDataContainer'}>
                                <p className={'title'}>Actual BTC <span className={'cpartValue'}>{'₿' + this.state.actualBtcInvest.toFixed(8)}</span></p>
                                <p className={'title'}>Actual USD <span className={'cpartValue'}>{'$' + this.state.actualUsdInvest.toFixed(2)}</span></p>
                                <p className={'title'}>Inicial BTC <span className={'cpartValue'}>{'₿' + this.props.user.investmentBtc.toFixed(8)}</span></p>
                                <p className={'title'}>Inicial USD <span className={'cpartValue'}>{'$' + this.props.user.investmentUsd.toFixed(2)}</span></p>
                                <p className={'title'}>Cantidad C.Partes <span className={'cpartValue'}>{this.props.user.qCPart?this.props.user.qCPart.toFixed(4):this.props.user.qCPart}</span></p>
                            </div>
                        </div>
                    </Paper>
                <Paper elevation={1} className={"paperRendimiento paperRendimientoClient"}>
                    <div className={'rendimientoCointainer'}>
                        <p className={"title"}>Rendimiento<span className={(this.props.btc ? this.state.rend : this.state.rendUsd) >= 0 ? 'cpartValue rendPos' : 'cpartValue rendNeg'}>{this.props.btc ? this.state.rend.toFixed(2) + '%':this.state.rendUsd.toFixed(2) + '%'}</span></p>

                        <Line className={'chart'} data={this.state.dataRend}  options={{
                            maintainAspectRatio: false,
                            legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: "rgba(0, 0, 0, 0)",
                                    },

                                    ticks: {
                                        autoSkip: true,
                                        /* maxRotation: window.innerWidth < 960 ? 90 : 0,
                                         minRotation: window.innerWidth < 960 ? 90 : 0,*/
                                        maxRotation: 0,
                                        minRotation: 0,
                                    }
                                }],
                                yAxes: [{
                                    gridLines: {
                                        color: "rgba(0, 0, 0, 0)",
                                    },
                                    ticks: {
                                        display: false //this will remove only the label
                                    }
                                }]
                            }
                        }}/>
                    </div>
                    </Paper>
                    <InvesTable user={this.props.user} userID={this.props.userID}/>
                </div>
            </div>
        )
    }
}