import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/es/Paper/Paper";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ClientsField from "./autocom/ClientsField";
import CoinsField from "./autocom/CoinsField";
import firebase from '../firebase';


function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}

export default class NewObjective extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            coin: '',
            coinSymbol: '',
            tp1: '',
            tp2: '',
            tp3: '',
            stopLoss : '',
            lastSelected: '',
        };
    }

    componentWillUnmount() {

    }

    componentDidMount() {

    }

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
            error: false
        });
    };
    handleChangeCoin = (newValueCoin) => {
        this.setState({coin: newValueCoin})
    };
    handleChangeSymbol = (symbol) => {
        this.setState({coinSymbol: symbol});
    };
    setLastSelected = (name) => {
        this.setState({lastSelected: name});
    };

    onNewObjective = () => {
        if (this.state.coin && this.state.coinSymbol && this.state.tp1 && this.state.tp2 &&
            this.state.tp3 && this.state.stopLoss && this.state.lastSelected){
            if (this.state.coin === this.state.lastSelected) {
                var db = firebase.firestore();
                var context = this;
                db.collection('objectives').add({
                    coin: context.state.coin,
                    coinSymbol: context.state.coinSymbol,
                    tp1: parseFloat(context.state.tp1),
                    tp2: parseFloat(context.state.tp2),
                    tp3: parseFloat(context.state.tp3),
                    stopLoss : parseFloat(context.state.stopLoss),
                    portfolioID: context.props.user.portfolioID,
                })
                    .then(function(docRef) {
                        context.props.actionFeedback(0, 'Nuevo objetivo creado!');
                        context.handleClose();
                    })
                    .catch(function(error) {
                        console.error("Error adding document: ", error);
                    });
            }
            else{
                this.setState({error: true});
                this.props.actionFeedback(1, 'Moneda mal introdicida')
            }
        }
        else {
            this.setState({error: true});
            this.props.actionFeedback(1, 'Campos incorrectos')
        }

    };

    handleClose = () => {
        this.setState({
            coin: '',
            coinSymbol: '',
            tp1: '',
            tp2: '',
            tp3: '',
            stopLoss : '',
            lastSelected: '',
            error: false,
        });
        this.props.handleClose(3);
    };

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#4457F3',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Modal open={this.props.open} className={"modalInv"} onClose={this.handleClose}>
                        <Paper className={"modalPaper"} elevation={1}>
                            <p className={'title modalTi'}>Nuevo Objetivo</p>
                            <CoinsField error={this.state.error} setLastSelected={this.setLastSelected} handleChangeSymbol={this.handleChangeSymbol} handleChange={this.handleChangeCoin} value={this.state.coin} actionFeedback={this.props.actionFeedback} user={this.props.user}/>

                            <TextField
                                value={this.state.tp1}
                                onChange={this.handleChange('tp1')}
                                error={this.state.error}
                                label="TP1"
                                className="fieldModal"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.tp2}
                                onChange={this.handleChange('tp2')}
                                error={this.state.error}
                                label="TP2"
                                className="fieldModal"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.tp3}
                                onChange={this.handleChange('tp3')}
                                error={this.state.error}
                                label="TP3"
                                className="fieldModal"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <TextField
                                value={this.state.stopLoss}
                                onChange={this.handleChange('stopLoss')}
                                error={this.state.error}
                                label="Stop Loss"
                                className="fieldModal"
                                type="number"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: 'inputCustomLogin',
                                    },
                                }}
                            />
                            <Button onClick={this.onNewObjective} type={'submit'} variant="contained" color="primary" className={"loginButton modalButton"}>
                                Cargar
                            </Button>
                        </Paper>
                    </Modal>
                </MuiThemeProvider>
            </div>
        )
    }
}