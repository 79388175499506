import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoalsTable from "./goals/GoalsTable";
import {copyAndCreateTest} from "../copyAndCreateTest";


export default class Goals extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    copyPort = () => {
        copyAndCreateTest().then(function (ympTest) {
            console.log(ympTest)
        })
    };

    componentDidMount(){
        this.props.clearTyped();
    }

    render(){
        return(
            <div className={'container'}>
                <p className={'title'}>Objetivos</p>
                <GoalsTable user={this.props.user}/>
            </div>
        )
    }
}