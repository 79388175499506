import firebase from "firebase";

let config = {
    apiKey: "AIzaSyDC1R33pTAJGCni6UV5cERvSLtJ5gTdjxw",
    authDomain: "portfolio-eaaf7.firebaseapp.com",
    databaseURL: "https://portfolio-eaaf7.firebaseio.com",
    projectId: "portfolio-eaaf7",
    storageBucket: "portfolio-eaaf7.appspot.com",
    messagingSenderId: "232310493554"
};
firebase.initializeApp(config);

export default firebase;
